import {Box, Button, Center, Flex, HStack, Spacer, Stack, Text, VStack} from "@chakra-ui/react";
import CareInstructionForm from "./Forms/CareInstructionForm";
import React from "react";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const order = ["planting", "watering", "fertilizing", "pruning", "propagating", "winterizing"];

const CareInstructionList = ({plant, careCardVersion = false}) => {

    return (
        <>
            {
                plant.care_instructions.length > 0 ? plant.care_instructions.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map((ci) => (
                            careCardVersion ?
                                <Stack key={ci.type} maxW={600}>
                                    <Box>
                                        <Text textAlign="center" bgGradient='linear(to-r, gray.100, gray.200)' borderRadius={3}>
                                            <p><b>{capitalizeFirstLetter(ci.type)} - {ci.every_n_days !== undefined &&
                                                (ci.every_n_days === 0 ?
                                                        <span>N/A</span>
                                                        :
                                                        ci.every_n_days === 999 ?
                                                            <span>Once in Spring</span>
                                                            :
                                                            ci.every_n_days === 1999 ?
                                                                <span>Once in Fall</span>
                                                                :
                                                                ci.every_n_days === 2999 ?
                                                                    <span>Once in Spring & Fall</span>
                                                                    :
                                                                    <span>Every {ci.every_n_days} days</span>
                                                )

                                            }
                                            </b></p>
                                        </Text>
                                    </Box>
                                    <Box w={"100%"} p={3} m={2} bg={"white"}>
                                        <Text>
                                            {ci.instruction_text && ci.instruction_text.length > 0 ? <p>{ci.instruction_text}</p> :
                                                <p>Nothing provided yet</p>}
                                        </Text>
                                    </Box>
                                    <Box>

                                    </Box>
                                </Stack>
                                :
                                <Flex key={ci.type}>
                                    <Center w={40} p={3} m={2} textAlign={'center'}
                                            bgGradient='linear(to-r, gray.100, gray.200)' borderRadius={3}>
                                        <p>{capitalizeFirstLetter(ci.type)}</p>
                                    </Center>
                                    <Center w={500} p={3} m={2} bg={"white"}>
                                        {ci.instruction_text && ci.instruction_text.length > 0 ? <p>{ci.instruction_text}</p> :
                                            <p>Nothing provided yet</p>}
                                    </Center>
                                    <Center w={100} p={3} m={2} bg={"white"}>
                                        {ci.every_n_days !== undefined &&
                                            (ci.every_n_days === 0 ?
                                                    <Text textAlign={"center"}>N/A</Text>
                                                    :
                                                    ci.every_n_days === 999 ?
                                                        <Text textAlign={"center"}>Once in Spring</Text>
                                                        :
                                                        ci.every_n_days === 1999 ?
                                                            <Text textAlign={"center"}>Once in Fall</Text>
                                                            :
                                                            ci.every_n_days === 2999 ?
                                                                <Text textAlign={"center"}>Once in Spring & Fall</Text>
                                                                :
                                                                <Text
                                                                    textAlign={"center"}>Every {ci.every_n_days} days</Text>
                                            )
                                        }
                                    </Center>
                                </Flex>
                        )
                    )
                    :
                    null
            }
        </>

    )
}

export default CareInstructionList;