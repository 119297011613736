import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Image,
    Input,
    Text
} from "@chakra-ui/react";
import ErrorMessage from "../Forms/ErrorMessage"
import logo from "../../images/logo.png";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// TODO Fix url selection
async function loginUser(credentials) {
    const formData = new FormData();
    formData.append('username', credentials['username'])
    formData.append('password', credentials['password'])

    return fetch(baseUrl + '/user/login', {
        method: 'POST',
        body: formData
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        // body: JSON.stringify({username: credentials['username'], password: credentials['password']})
        // body: JSON.stringify(credentials)
    })
        .then(
            data => data.json())
}


const Login = ({...props}) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [valid, setValid] = useState();
    const [error, setError] = useState();

    const setToken = props.setToken;
    const setRefreshToken= props.setRefreshToken;
    const handleSubmit = async e => {
        e.preventDefault();
        const api_response = await loginUser({
            username,
            password
        });
        const token = api_response['access_token'];
        const refresh_token = api_response['refresh_token'];

        if (token) {
            setToken(token); // TODO: this might be ruining my life or making it better. but who rly knows bcuz i cant read javascript.
            setRefreshToken(refresh_token)
        } else {
            setValid(false);
            setError("Invalid user password");
        }


    }


    return (
        <Center h={600}>
            <Box w={400} sx={{backgroundImage: "linear-gradient(45deg, #f0f0f0, #e0e0e0)"}} p={6}>
                <Center>
                    <Image src={logo} alt='Plantista Logo' w={200}/>
                </Center>
                <form onSubmit={handleSubmit}>
                    {error && <ErrorMessage message={error}/>}
                    <FormControl mt={4} mb={10}>
                        <FormLabel>Email</FormLabel>
                        <Input bgColor="white" type='email' name='username' onChange={e => setUsername(e.target.value)}/>
                        <FormHelperText>Your email or username</FormHelperText>
                    </FormControl>
                    <FormControl isInvalid={error}>
                        <FormLabel>Password</FormLabel>
                        <Input bgColor="white" type='password' onChange={e => setPassword(e.target.value)}/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <Text fontSize={"sm"}><Link to={"/request-reset"}>Forgot password?</Link></Text>
                    <Button mt={4} w={"100%"} type="submit">Submit</Button>
                </form>

            </Box>
        </Center>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setRefreshToken: PropTypes.func.isRequired
}
export default Login;