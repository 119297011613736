import {useNavigate} from "react-router-dom";
import {AgGridReact} from "ag-grid-react";
import React, {useMemo, useRef, useState} from "react";
import {Input, Box} from "@chakra-ui/react";
import {useMutation} from "@tanstack/react-query";
import axiosInstance from "../Api";
import {toast} from "react-toastify";


// SHOW ALL MATCHES AGAINST THE POSSIBLE GENERIC PRODUCT LIST
const ProductMappingTable = ({productMapping, retailerProducts, user}) => {
    // Row Data: The data to be displayed.
    const [quickFilterText, setQuickFilterText] = useState("");
    const gridRef = useRef();

    const retailerProductNames = retailerProducts.map(item => item.name);

    const defaultColDef = useMemo(() => {
        return {
            // set every column width
            // width: 400,
            flex: 1, // https://stackoverflow.com/questions/65169997/react-ag-grid-auto-size-columns-and-grid-width
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default

        };
    }, []);

    const [colDefs] = useState([
        {field: 'use_when_instruction_type', headerName: 'Use When', width: 150},
        {field: 'generic_product_name', headerName: 'Generic Product Name', width: 300, sort: 'asc', flex: 0},
        {field: 'generic_product_id', headerName: 'Generic Product ID', width: 300, sort: 'asc', flex: 0, hide: true},
        {
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: retailerProductNames,
            },
            field: 'retailer_product_name',
            headerName: 'Retailer Product Name',
            editable: true,
            width: 600,
            flex: 0
        },
        {field: 'retailer_product_id', headerName: 'Retailer Product ID', width: 300, sort: 'asc', flex: 0, hide: true},
    ]);


    function getRetailerProductIdByName(name) {
        const product = retailerProducts.find(product => product.name === name);
        return product ? product.id : null;
    }

    const updateProductMapping = (e) => {
        // if (!e.oldValue || e.oldValue == 0) {
        //     return
        // }
        //
        // if (!e.newValue || e.newValue.length < 4) {
        //     return
        // }

        if (e.newValue !== e.oldValue) {
            // TODO: seems sketchy because it grabs the edited value based on the headername of data of the edited cell
            let data = {
                // "care_instruction_id": e.data.care_instructions[e.column.colDef.headerName].care_instruction_id,
                // console.log()
                "existing_retailer_product_id": getRetailerProductIdByName(e.oldValue),
                "retailer_product_id": getRetailerProductIdByName(e.newValue),
                "generic_product_id": e.data.generic_product_id
            };
            // console.log(data) // TODO: remove
            doUpdateProductMapping(data);

        } else {
            console.log("No changes made on edit")
        }
    }


    const {
        mutate: doUpdateProductMapping,
        isLoading: loadingUpdateProductMapping,
        error: errorUpdateProductMapping
    } = useMutation({
        mutationFn: (requestData) => axiosInstance.put('/me/product_mapping/', requestData),
        onSuccess: (response) => {
            // console.log(plantUrl);
            toast.success("Product Mapping successfully updated.")
            queryClient.invalidateQueries(['product_mapping', user?.retailer_id]).then();
        },
        onError: (e) => {
            console.log(e);
        },
    });


    return (
        <div
            className={"ag-theme-quartz"}
            style={{width: '100%', height: '600px'}}
        >
            <Box mb={4}>
                <Input type="text" id="filter-text-box" placeholder="Filter..."
                       onChange={e => setQuickFilterText(e.target.value)}/>
            </Box>
            <AgGridReact
                ref={gridRef}
                suppressExcelExport={true}
                rowHeight={50}
                quickFilterText={quickFilterText}
                defaultColDef={defaultColDef}
                // onRowClicked={(e) => navigateToProduct(e)}
                // suppressRowClickSelection={true}
                onCellEditingStopped={(e) => updateProductMapping(e)}
                pagination={true}
                rowData={productMapping}
                columnDefs={colDefs}
            />
        </div>
    );
}

export default ProductMappingTable;