// Filename - App.js

import React, {useRef, useState} from "react";
import {Box, Button, FormControl, FormLabel, Image, Input} from "@chakra-ui/react";
import {useFormik} from "formik";
import PhoneValidationSchema from "./Forms/CareValidationSchema";
import axiosInstance from "../Api";
import {useAuth} from "./Providers/Auth/AuthContext";

function ImageUploadFormControl({formik}) {
    const {user, setUser} = useAuth();
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState()
    const fileInputRef = useRef(null);
    // const handleSubmit = async (e) => {
    //     const formData = new FormData();
    //     formData.append('file', file);
    //
    //     axiosInstance.post("/me/retailer/logo_upload", formData, {headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }})
    //         .then((response) => {
    //             // Update retailer's logo via AuthContext
    //             setUser({ ...user, retailer: { ...user.retailer, logo_url: response.data.logo_url } });
    //             formik.resetForm();
    //             setIsSent(true);
    //         }).catch();
    // }

    const handleFileChange = (event) => {
        if (event.target.files.length === 0) return;
        formik.setFieldValue("file", event.currentTarget.files[0]);
        setFileUrl(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <FormControl isInvalid={!!formik.errors.file && formik.touched.file}>
            <FormLabel>Add an image to your coupon, like a barcode.</FormLabel>
            <Input name="file" type="file" onChange={handleFileChange} onBlur={formik.handleBlur} accept="image/*" ref={fileInputRef} paddingTop="1"/>
            <Image src={fileUrl} w={200} borderRadius="lg" marginX="auto"/>
        </FormControl>
    );
}

export default ImageUploadFormControl;