import React, {useState} from 'react';
import {useFormik} from 'formik';

import {
    Box,
    Button,
    Textarea,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack, Select, HStack, InputGroup, InputLeftAddon
} from "@chakra-ui/react";
import PlantSearch from "../PlantSearch";
import CareValidationSchema from "../Forms/CareValidationSchema";
import axiosInstance from "../../Api";
import ErrorPage from '../Pages/ErrorPage';
import { getErrorMsg } from "../../utils/funcs";
import { useMutation } from "@tanstack/react-query";
import { CARE_INSTRUCTION_TYPES } from '../../utils/constants';


const SendCareForm = ({plant}) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isSent, setIsSent] = useState(false);
    const [plantSearchTouched, setPlantSearchTouched] = useState(false);
    const { mutate, isPending, error } = useMutation({
        mutationFn: () => {
            const payload = {
                plant: formik.values.plant.map((plant) => plant.value),
                phone: formik.values.phone,
                instruction: formik.values.instruction,
            }
            return axiosInstance.post("/me/message", payload)
        },
        onSuccess: (response) => {
            formik.resetForm();
            setIsSent(true);
        },
        onError: (e) => {
            console.log(e);
        },
    });

    const initialValues = {
        phone: "",
        plant: [],
        instruction: "planting"
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: CareValidationSchema,
        onSubmit: values => {
            mutate();
        },
    });

    if (error) return (<ErrorPage text={getErrorMsg(error)}/>);

    return (
        <Box bg="white" w={"100vw"} maxW={"500"} p={6} rounded="md">
            <form onSubmit={formik.handleSubmit} id="care-submit-form">
                <PlantSearch searchUrl="/plants/genus"
                             setSelectedProduct={setSelectedProduct}
                             multiSelectMode={false}
                             responseType="into"
                             values={formik.values.plant}
                             setPlantSearchTouched={setPlantSearchTouched}
                             setFieldValue={formik.setFieldValue}
                             formErrors={formik.errors.plant}/>

                <VStack spacing={4} align="flex-start">
                    <FormControl isInvalid={!!formik.errors.phone && formik.touched.phone}>
                        <FormLabel htmlFor="phone">Phone</FormLabel>
                        <InputGroup mt={4}>
                            <InputLeftAddon children='+1'/>
                            <Input
                                id="phone"
                                name="phone"
                                type="phone"
                                variant="filled"
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.instruction && formik.touched.instruction}>
                        <FormLabel mt={2} htmlFor="instruction">Instruction to Send</FormLabel>
                        <Select
                            placeholder="Select option"
                            id="instruction"
                            name="instruction"
                            variant="filled"
                            onChange={formik.handleChange}
                            value={formik.values.instruction}
                            // onChange={(e) => setFieldValue("instruction", e.target.value)}
                        >
                            {CARE_INSTRUCTION_TYPES.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.label}</option>
                                )
                            })}
                        </Select>
                        <FormErrorMessage>{formik.errors.instruction}</FormErrorMessage>
                    </FormControl>
                    {isSent &&
                        <Box color={"green"}>
                            <p>Care instructions sent!</p>
                        </Box>
                    }
                    {/*{selectedProduct &&*/}
                    {/*    <>*/}
                    {/*        <FormLabel mt={2}>Instruction List </FormLabel>*/}
                    {/*        <CareInstructionList plant={selectedProduct} retailer={retailer}/>*/}
                    {/*    </>*/}
                    {/*}*/}
                    {/*<Button type="submit" isDisabled={success} colorScheme="green" width="full">*/}
                    <HStack>
                        <Button type="submit" width="full">
                            Send Care
                        </Button>
                        <Button type="reset">Reset </Button>
                        {/*<Button type="reset" onClick={formik.resetForm()}>*/}
                        {/*    Send Care*/}
                        {/*</Button>*/}
                    </HStack>
                </VStack>
            </form>
        </Box>
    )
}

export default SendCareForm;