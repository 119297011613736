import {FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Select} from "@chakra-ui/react";
import React from "react";

const CouponListFormControl = ({formik, couponOptions, setCurrentCoupon}) => {

    const handleChange = async (event) => {
        const selectedCouponId = event.target.value;
        const selectedCoupon = couponOptions.find(coupon => coupon.id === selectedCouponId);
        setCurrentCoupon(selectedCoupon);

        formik.handleChange(event);
        await formik.setFieldValue('coupon_id', selectedCouponId); // Update formik values
        formik.setFieldTouched('coupon_id', true); // Mark field as touched

    }
    return (


        <FormControl
            isInvalid={!!formik.errors.coupon_id && formik.touched.coupon_id}>
            <FormLabel htmlFor="type">Attach a Coupon</FormLabel>
            <Select
                placeholder="Select option"
                id="coupon_id"
                name="coupon_id"
                variant="filled"
                onChange={handleChange}
                value={formik.values.coupon_id}
            >
                {/*<option value='sale'>Sale Event</option>*/}
                {/*<option value='in_store_event'>In Store Event</option>*/}
                {couponOptions.map((coupon) => (
                    <option key={coupon.id} value={coupon.id}>
                        {coupon.name}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>{formik.errors.coupon_id}</FormErrorMessage>
        </FormControl>
    )
}

export default CouponListFormControl;

