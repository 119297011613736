import React, {useState} from "react";
import {
    Box,
    Center,
    GridItem, Heading,
    Image,
    SimpleGrid, Skeleton, Stack,
    Text,
    VStack
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../Api";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import PlantMiniCard from "../PlantMiniCard";
import EnrollmentForm from "../Forms/EnrollmentForm";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const ManageEnrollments = () => {
    const [success, setSuccess] = useState(false);
    const params = useParams('');
    const token = params['token'];
    // const [retailer, setRetailer] = useState(false);
    const queryClient = useQueryClient();

    const {
        isLoading: loadingSmsToken,
        error: errorSmsToken,
        data: smsTokenData
    } = useQuery({
        queryKey: ['sms_token', token], queryFn: () =>
            axiosInstance.get('/manage_enrollment_token/' + token).then((response) => response.data)
    });

    const {
        isLoading: loadingCustomerEnrollments,
        error: errorCustomerEnrollments,
        data: enrollmentData
    } = useQuery({
        queryKey: ['enrollments', smsTokenData?.phone_number],
        queryFn: () =>
            axiosInstance.get(
                '/manage/sms/' + token)
                .then((response) => response.data),
        enabled: !!smsTokenData
    });

    const {mutate: updateEnrollment, isPending: pendingUpdateEnrollment, error: enrollmentMutationError} = useMutation({
        mutationFn: (patchData) => axiosInstance.delete('/manage/sms/' + token, {data: patchData}),
        onSuccess: (response) => {
            queryClient.invalidateQueries(['enrollments', smsTokenData.phone_number]).then();
            //TODO: add toasts here. but i don't think they are available because of how the routing is set up on the app.
        },
        onError: (e) => {
            // const details = e.response?.data?.detail;
            // const msg = (
            //     <>
            //         <div>Failed to update</div>
            //         <div>{details || 'Please try again.'}</div>
            //     </>
            // );
            console.log(e);
        },
    });

    const removeEnrollment = (e) => {
        const genusId = e.target.getAttribute(['genusid'])
        const requestData = {token: token, genus_id: genusId};
        updateEnrollment(requestData);
    }
    // const handleSubmit = (postData) => {
    //     axiosInstance.post("/manage/sms/" + token, postData)
    //         .then((response) => {
    //             setSuccess(true);
    //         }, (error) => {
    //             console.log(error);
    //             setSuccess(false);
    //         });
    // }
    // if (loadingSmsToken) return <LoadingPage text="Loading"
    if (loadingCustomerEnrollments || loadingSmsToken) return <LoadingPage text="Loading..."/>;
    if (errorCustomerEnrollments && errorCustomerEnrollments?.response?.data?.detail === 'Token has expired.') {
        return <ErrorPage
            text={"Your manage token has expired. Please close this window, and text MANAGE to our phone number again to receive a new link."}
            manageExpired={true}></ErrorPage>
    }
    if (errorCustomerEnrollments || enrollmentMutationError) return <ErrorPage
        text={getErrorMsg(errorCustomerEnrollments || enrollmentMutationError)}/>;

    return (
        <>
            <GridItem colSpan={{base: 6, md: 5}}>
                <Center>
                    <Box maxW={600} p={4}>
                        {/*bg={"#b7e4c7"}>*/}
                        <VStack mb={4}>
                            {smsTokenData.retailer?.logo_url ?
                                <Image src={smsTokenData.retailer.logo_url} maxH={100}/>
                                :
                                <p>{smsTokenData?.retailer?.business_name} </p>
                            }
                            <h2>Manage Your Enrollments</h2>
                        </VStack>
                        {/*<Box mb={2}>*/}
                        {/*    <Center><Text as="b" fontSize={"lg"}>Current Plants</Text></Center>*/}
                        {/*</Box>*/}
                        {enrollmentData && enrollmentData?.length > 0 ?

                            <SimpleGrid spacing={4} columns={{base: 1, md: 2}}>
                                {
                                    enrollmentData
                                        .slice() // Create a copy of the array to avoid mutating the original
                                        .sort((a, b) => a.genus?.name?.localeCompare(b.genus.name)) // Sort items alphabetically
                                        .map((item, index) => (
                                            <Skeleton isLoaded={!pendingUpdateEnrollment}>
                                                <PlantMiniCard genusid={item.genus.id}
                                                               key={item.genus.id}
                                                               plantData={item.genus}
                                                               cardWidth={'100%'}
                                                               startDate={item.start_date}
                                                               removeEnrollment={removeEnrollment}/>
                                            </Skeleton>
                                        ))
                                }
                            </SimpleGrid>

                            :
                            <Center><Text>You are not enrolled in any plants at this time.</Text></Center>
                        }
                        <Stack mt={8} spacing={1} align={'center'} w="100%">
                            {enrollmentData && enrollmentData?.length > 0 ?
                                <>
                                    <Text as="b" fontSize={"lg"}>Enroll in More Plants</Text>
                                    <Text>Search for new plants and add them!</Text>
                                </>
                                :

                                <>
                                    <Text>Search for a plant below, and enroll!</Text>
                                </>
                            }
                            <EnrollmentForm manageFlow={true}
                                            retailer={smsTokenData.retailer}
                                            showPhoneAndEmail={false}
                                // we pass in the number so it passes validation unlike normal enroll flow.
                                            phoneIn={smsTokenData.phone_number}
                                            alreadyEnrolled={enrollmentData.map((enroll) => enroll.genus.id)}
                                            disableOnSubmit={false}
                            />
                        </Stack>
                    </Box>
                </Center>
            </GridItem>
        </>
    )
}

export default ManageEnrollments;

