import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {Box, Button, HStack, Input, Skeleton, VStack} from "@chakra-ui/react";
import axiosInstance from "../Api";
import {useNavigate, Link} from "react-router-dom"; // Theme
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import ErrorPage from './Pages/ErrorPage';
import {getErrorMsg} from '../utils/funcs';
import {useAuth} from './Providers';
import LoadingPage from "./Pages/LoadingPage";
import {toast} from "react-toastify";

// https://ag-grid.com/react-data-grid/reference-data/
// https://stackoverflow.com/questions/76773396/how-to-store-display-and-allow-editing-of-complex-objects-in-a-react-ag-grid-c
// https://stackoverflow.com/questions/63637259/how-to-send-changed-cells-to-server-in-ag-grid

const PlantTable = ({
                        colDefs,
                        plantUrl,
                        careEditTable = false,
                        clickToPlant = false,
                    }) => {
    const {user} = useAuth();
    const [quickFilterText, setQuickFilterText] = useState("");
    const gridRef = useRef();

    const queryClient = useQueryClient();
    let navigate = useNavigate();

    const defaultColDef = useMemo(() => {
        return {
            // set every column width
            // width: 400,
            flex: 1, // https://stackoverflow.com/questions/65169997/react-ag-grid-auto-size-columns-and-grid-width
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default

        };
    }, []);

    const navigateToPlant = (e) => {
        if (e.colDef.field !== "Action") {
            navigate("/plants/genus/" + e.data.id, {replace: false})
        }
    }

    const updateCareInstruction = (e) => {
        if (!e.oldValue || e.oldValue == 0) {
            return
        }

        if (!e.newValue || e.newValue.length < 4) {
            return
        }

        if (e.newValue !== e.oldValue) {
            // TODO: seems sketchy because it grabs the edited value based on the headername of data of the edited cell
            let data = {
                // "care_instruction_id": e.data.care_instructions[e.column.colDef.headerName].care_instruction_id,
                // console.log()
                "instruction_text": e.newValue,
                "genus_id": e.data.genus_id,
                "type": e.column.colDef.headerName,
                "every_n_days": e.data.care_instructions[e.column.colDef.headerName].every_n_days
            };

            updateCareInstructionOverride(data);

        } else {
            console.log("No changes made on edit")
        }
    }

    const popupParent = useMemo(() => {
        return document.body;
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const getValue = (inputSelector) => {
        var text = document.querySelector(inputSelector).value;
        switch (text) {
            case 'none':
                return;
            case 'tab':
                return '\t';
            default:
                return text;
        }
    };
    const getParams = () => {
        return {
            columnSeparator: getValue('#columnSeparator'),
        };
    };

    const {
        mutate: updateCareInstructionOverride,
        isLoading: loadingCareInstructionOverride,
        error: errorCareInstructionOverride
    } = useMutation({
        mutationFn: (requestData) => axiosInstance.put('/me/plants/genus/care/', requestData),
        onSuccess: (response) => {
            // console.log(plantUrl);
            toast.success("Care Instruction Successfully Saved.")
            queryClient.invalidateQueries(['plant', requestData?.genus_id]).then();
        },
        onError: (e) => {
            console.log(e);
        },
    });
    const {isLoading, error, data: allPlantData} = useQuery({
        queryKey: ['allPlantData', plantUrl], queryFn: () =>
            axiosInstance.get(plantUrl).then((response) => response.data)
    });

    const rd = allPlantData;

    if (isLoading) return <LoadingPage/>;
    if (error) return <ErrorPage text={getErrorMsg(error)}/>;
    // if (errorCareInstructionOverride) return <ErrorPage text={getErrorMsg(errorCareInstructionOverride)}/>;

    return (
        <>
            <HStack mb={4}>
                <Input type="text" id="filter-text-box" placeholder="Filter..."
                       onChange={e => setQuickFilterText(e.target.value)}/>
                {user?.is_admin && plantUrl === '/plants/genus' && (
                    <Button as={Link} to="/plants/add">Add Plant</Button>
                )}
                <Button onClick={onBtnExport}>Download CSV</Button>
            </HStack>
            <div
                className={"ag-theme-quartz"}
                style={{width: '100%', height: '600px'}}
            >
                {!careEditTable ?
                    <AgGridReact
                        ref={gridRef}
                        suppressExcelExport={true}
                        popupParent={popupParent}
                        rowHeight={50}
                        quickFilterText={quickFilterText}
                        defaultColDef={defaultColDef}
                        onCellClicked={(e) => clickToPlant && navigateToPlant(e)}
                        suppressRowClickSelection={true}
                        onCellEditingStopped={(e) => updateCareInstruction(e)}
                        pagination={true}
                        rowData={rd}
                        columnDefs={colDefs}
                        reactiveCustomComponents={true}
                    />
                    :
                    <>
                        <AgGridReact
                            ref={gridRef}
                            // suppressExcelExport={true}
                            // popupParent={popupParent}
                            rowHeight={50}
                            quickFilterText={quickFilterText}
                            defaultColDef={defaultColDef}
                            // onCellEditingStarted={(e) => console.log(e)}
                            onCellEditingStopped={(e) => updateCareInstruction(e)}
                            pagination={true}
                            rowData={rd}
                            columnDefs={colDefs}
                            // reactiveCustomComponents={true}
                        />
                    </>
                }
            </div>
        </>

    );
}
export default PlantTable// Render GridExample