import {
    Box, Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs, Tbody, Td, Th, Thead, Tr, Text
} from "@chakra-ui/react";
import {useState} from "react";
import SendCareForm from "../Forms/SendCareForm";
import CreateCareCardForm from "../Forms/CreateCareCardForm";
import PlantTable from "../PlantTable";
import CreateCareOrBenchCardForm from "../Forms/CreateCareCardForm";
import {useAuth} from "../Providers/Auth/AuthContext";
import {useLocation} from "react-router-dom";
import {CARE_INSTRUCTION_TYPES} from "../../utils/constants";
import SMSCampaigns from "./SMSCampaigns";


const Marketing = () => {
    const location = useLocation();
    const [showCareCard, setShowCareCard] = useState(false)
    const {user} = useAuth();

    // Default tabIndex by looking at state passed via react router, otherwise default to 0
    const [tabIndex, setTabIndex] = useState(location?.state?.defaultIndex || 0)

    // const [defaultIndex, setDefaultIndex] = useState(1);

    const toggleCareCard = () => setShowCareCard(value => !value);


    // const [searchParams] = useSearchParams();
    // const plantId = searchParams.get('plantId');
    // if (!user) return <p>Loading</p>;

    return (
        <>
            <Box mb={4}>
                <h2>Marketing</h2>
                <p><em>Send custom messages, print bench cards, and more.</em></p>
            </Box>
            <Tabs defaultIndex={tabIndex}>
                <TabList>
                    <Tab>SMS Campaigns</Tab>
                    <Tab>Benchcards</Tab>
                    {user.is_admin && <Tab>Message Testing</Tab>}
                    {/*<Tab>Care Cards</Tab>*/}
                    {/*<Tab>Bench Cards</Tab>*/}
                    {/*<Tab>Care Instructions</Tab>*/}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SMSCampaigns/>
                    </TabPanel>
                    <TabPanel>
                        Talk to us!
                    </TabPanel>
                    {user.is_admin && <TabPanel>
                        <h3>Send Test messages for specific plants.</h3>
                        {user?.retailer.sms_phone_number ?
                            <SendCareForm/>
                            :
                            <Text>No sms phone number is set for this account. Please contact us to set one up.</Text>
                        }
                    </TabPanel>}
                    {/*<TabPanel>*/}
                    {/*    <CreateCareOrBenchCardForm type={"care"}/>*/}
                    {/*</TabPanel>*/}
                    {/*<TabPanel>*/}
                    {/*    <CreateCareOrBenchCardForm type={"bench"}/>*/}
                    {/*</TabPanel>*/}
                    {/*<TabPanel>*/}
                    {/*    <Box mb={4} mt={2}>*/}
                    {/*        <em><p>Viewing Care Instructions for plants in Zone: 6a and Zip Code: 80027</p></em>*/}
                    {/*    </Box>*/}
                    {/*    /!*TODO: potentially change the component name because here it is rendering care intructions?*!/*/}
                    {/*    <PlantTable plantUrl="me/plants/genus/care" colDefs={careColDefs}*/}
                    {/*                renderCareData={true}></PlantTable>*/}
                    {/*</TabPanel>*/}

                </TabPanels>
            </Tabs>
        </>
    );
};

export default Marketing;