import {
    Box,
    Button,
    Card, CardBody,
    Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    SimpleGrid,
    Skeleton,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels, Tabs, Text, useDisclosure
} from '@chakra-ui/react'
import ProductTable from "../ProductTable";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import axiosInstance from "../../Api";
import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../Providers/Auth/AuthContext";
import ErrorPage from './ErrorPage';
import {getErrorMsg} from '../../utils/funcs';
import CreateProductForm from "../Forms/CreateProductForm";
import RecommendationTable from "../../RecommendationTable";
import ProductMappingTable from "../ProductMappingTable";

const Products = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {user} = useAuth();

    const {isLoading: isLoadingProducts, error: errorProducts, data: productsData} = useQuery({
        queryKey: ['products', user?.email], queryFn: () =>
            axiosInstance.get('/me/product').then(response => {
                return response.data;
            })
    });

    const {isLoading: isLoadingRecommendations, error: errorRecommendations, data: recommendationData} = useQuery({
        queryKey: ['recommendations', user?.email], queryFn: () =>
            axiosInstance.get('/me/product_recommendation/table').then(response => {
                return response.data;
            })
    });

    const {isLoading: isLoadingMappings, error: errorMappings, data: mappingData} = useQuery({
        queryKey: ['product_map', user?.email], queryFn: () =>
            axiosInstance.get('/me/product_mapping').then(response => {
                return response.data;
            })
    });

    if (isLoadingProducts || isLoadingRecommendations || isLoadingMappings) return <div><Skeleton/></div>;
    if (errorProducts || errorRecommendations || errorMappings) return <ErrorPage text={getErrorMsg(errorProducts)}/>;

    return (
        <>
            <Box mb={4}>
                <h2>Products</h2>
                <p><em>View your products, add new ones, and set recommendations.</em></p>
            </Box>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
                <TabList>
                    <Tab>Your Products</Tab>
                    {user.is_admin &&
                        <Tab>Recommendation Config</Tab>
                    }
                    <Tab>Recommendations</Tab>
                    <Tab>Create Product</Tab>
                    {/*<Tab>Inventory Connect</Tab>*/}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ProductTable productList={productsData}/>
                    </TabPanel>
                    <TabPanel>
                        {/*TODO: only show if there were no other recommendations to make */}
                        {user.is_admin &&
                            <ProductMappingTable productMapping={mappingData} retailerProducts={productsData}
                                                 user={user}/>
                        }
                        {/*{user.is_admin &&*/}
                        {/*    <div>*/}
                        {/*        <Text>Pressing this button will perform a crazy operation.</Text>*/}
                        {/*        <Button mt={20}>Apply All Recommendations</Button>*/}
                        {/*    </div>*/}
                        {/*}*/}

                    </TabPanel>
                    <TabPanel>
                        <Text mb={2}>A list of your current recommendations.</Text>
                        <RecommendationTable recommendationList={recommendationData}/>
                    </TabPanel>
                    <TabPanel>
                        <CreateProductForm/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {/*</Skeleton>*/
            }
        </>
    )
        ;
};

export default Products;