import React, {useRef, useState} from 'react';
import {Formik, Form, Field, ErrorMessage, useFormik} from 'formik';

import {
    Box,
    Button,
    Textarea,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Select,
    HStack,
    Spinner
} from "@chakra-ui/react";
import axiosInstance from "../../Api";
import * as Yup from "yup";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../Providers/Auth/AuthContext";
import ErrorPage from '../Pages/ErrorPage';
import {getErrorMsg} from '../../utils/funcs';

const CreateProductForm = ({hideTypeField = false}) => {
    // const [genCode, setGenCode] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const queryClient = useQueryClient();
    const {user} = useAuth();
    // const retailerId = user.retailer_id;
    const handleSubmit = async () => {
        makeProduct(formik.values);
    }

    const resetFullForm = () => {
        setIsSent(false)
        formik.resetForm();
    }

    const ProductValidationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        description: Yup.string().required("A description is required."),
        type: Yup.string().required("For use when type is required"),
        image_url: Yup.string()
    });
    const initialValues = {
        name: "",
        description: "",
        type: "NA",
        image_url: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ProductValidationSchema,
        onSubmit: values => {
            handleSubmit();
        },
    })

    const {mutate: makeProduct, isPending: pendingMakeProduct, error: makeProductError} = useMutation({
        mutationFn: (postData) => axiosInstance.post('/me/product/', postData),
        onSuccess: (resp) => {
            resetFullForm();
            setIsSent(true);
            queryClient.setQueryData(['products', user?.email], (prev) => {
                return prev.concat([resp.data]);
            });
        },
        onError: (e) => {
            // toast.error('Could not submit comment, please try again.');
            console.log(e);
        },
    });


    if (pendingMakeProduct) return <Spinner/>
    if (makeProductError) return <ErrorPage text={getErrorMsg(makeProductError)}/>;

    return (
        <Box bg="white" w={800} p={6} rounded="md">
            <form onSubmit={formik.handleSubmit} onBlur={() => setIsSent(false)} id="create-product-form">
                <VStack spacing={4} align="flex-start">
                    <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input
                            id="product-name"
                            name="name"
                            // type="name"
                            variant="filled"
                            placeholder="the name of this product"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.description && formik.touched.description}>
                        <FormLabel htmlFor="value">Description</FormLabel>
                        <Textarea
                            id="description"
                            name="description"
                            type="text"
                            variant="filled"
                            placeholder=""
                            onChange={formik.handleChange}
                            value={formik.values.value}
                        />
                        <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.image_url && formik.touched.image_url}>
                        <FormLabel htmlFor="imageurl">Image Url</FormLabel>
                        <Input
                            id="product-image-url"
                            name="image_url"
                            variant="filled"
                            placeholder="a URL to the image, if you have one. not required."
                            onChange={formik.handleChange}
                            value={formik.values.value}
                        />
                        <FormErrorMessage>{formik.errors.image_url}</FormErrorMessage>
                    </FormControl>
                    {!hideTypeField &&
                        <FormControl isInvalid={!!formik.errors.type && formik.touched.type}>
                            <FormLabel htmlFor="type">For Use When</FormLabel>
                            <Select
                                placeholder="Select when this product should be used."
                                id="type"
                                name="type"
                                variant="filled"
                                onChange={formik.handleChange}
                                value={formik.values.type}
                                // onChange={(e) => setFieldValue("instruction", e.target.value)}
                            >
                                <option value='planting'>planting</option>
                                <option value='fertilizing'>fertilizing</option>
                            </Select>
                            <FormErrorMessage>{formik.errors.type}</FormErrorMessage>
                        </FormControl>
                    }
                    <HStack>
                        <Button type="submit" width="full">
                            Create Product
                        </Button>
                        <Button type="reset" onClick={resetFullForm}>Reset</Button>
                    </HStack>
                </VStack>
                {isSent &&
                    <Box color={"green"}>
                        <p>Product Created!</p>
                    </Box>
                }
            </form>
        </Box>
    )
}

export default CreateProductForm;