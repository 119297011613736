import {FormControl, FormErrorMessage, FormLabel, Input, InputGroup} from "@chakra-ui/react";
import React from "react";

const EmailFormControl = ({formik}) => {
    return (
        <FormControl mt={2} isInvalid={!!formik.errors.email && formik.touched.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <InputGroup mt={2}>
                <Input
                    id="email"
                    name="email"
                    type="email"
                    variant="filled"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
            </InputGroup>
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>
    )
}

export default EmailFormControl;