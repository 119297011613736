import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Image,
    Input,
    Radio,
    RadioGroup,
    Select,
    Stack,
    SimpleGrid,
    Textarea,
    Text,
    VStack,
    Center,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter, Flex, Spacer,
    useClipboard
} from "@chakra-ui/react";
import React, {forwardRef, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../styles.css'
import PhoneFormControl from "../Forms/FormControls/PhoneFormControl";
import PlantSearch from "../PlantSearch";
import {useAuth} from "../Providers";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";
import LoadingPage from "./LoadingPage";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axiosInstance from "../../Api";
import {toast} from "react-toastify";
import CouponListFormControl from "../Forms/FormControls/CouponListFormControl";
import {LuClipboardCopy} from "react-icons/lu";


const SMSCampaigns = () => {
    // const [scheduleType, setScheduleType] = useState();
    // const [startDate, setStartDate] = useState(new Date());
    const [success, setSuccess] = useState(false);
    const [currentCoupon, setCurrentCoupon] = useState(null);
    const [couponStr, setCouponStr] = useState("");

    const {isOpen, onOpen, onClose} = useDisclosure()

    const cancelRef = React.useRef()
    // const [recipientType, setRecipientType] = useState();
    const {user} = useAuth();
    const queryClient = useQueryClient();

    const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
        <Button m={4} className="example-custom-input" onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));
    const buildCouponStr = (coupon, retailerPhone) => {
        if (!coupon) {
            setCouponStr('');
            return '';
        }

        let ctype = coupon.type;
        let ctstr = null;
        let dollar_str = null;
        let coupon_str = "";

        if (ctype === 'percentage') {
            ctstr = '%';
        } else if (ctype === 'flat') {
            dollar_str = '$';
        }

        let lead_coupon_part = coupon.image_url ? "Show this barcode in store for " : "Bring this code in store for ";

        let purchase_requirement_part = coupon.min_purchase_requirement ?
            ` off on your next purchase of $${coupon.min_purchase_requirement} or more.` :
            " off on your next purchase.";

        if (ctype === 'flat') {
            coupon_str = `${lead_coupon_part}${dollar_str}${coupon.value}${purchase_requirement_part}`;
        } else if (ctype === 'percentage') {
            coupon_str = `${lead_coupon_part}${coupon.value}${ctstr}${purchase_requirement_part}`;
        }

        if (!coupon.image_url && coupon.code) {
            coupon_str += ` Code: ${coupon.code}`;
        }

        // Additional condition for retailerPhone and recommendedProductName
        // if (user.retailer.sms_phone_number === '18333597744' && ctype === 'flat' && coupon.image_url) {
        if (user.retailer.sms_phone_number === '18333597744' && ctype === 'flat' && coupon.image_url) {
            coupon_str = `Show this barcode in store for $${coupon.value} off on your next purchase.`;
        }

        return coupon_str;
    };

    const {isLoading: loadingCouponData, error: errorCouponData, data: couponData} = useQuery({
        queryKey: ['coupons', user.retailer_id], queryFn: () =>
            axiosInstance.get('/me/coupons').then((response) => response.data)
    });


    const {mutate: createCampaign, isPending: pendingCampaignCreate, error: errorCampaign} = useMutation({
        mutationFn: (postData) => axiosInstance.post('/me/message/campaign/', postData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(['campaign', user?.retailer?.id]).then();
            setSuccess(true);
            toast.success('Message(s) sent to customer. ');
        },
        onError: (e) => {
            console.log(e);
        },
    });


    const CampaignValidationSchema = Yup.object().shape({
        campaign_type: Yup.string().required("A campaign type is required."),
        content: Yup.string().required("Content is required in the message."),
        delivery_schedule: Yup.string().required("A delivery schedule type is required."),
        recipient_type: Yup.string().required("A recipient type is required."),
        scheduled_date: Yup.string().required("A scheduled date is required."),
        phone: Yup.string().when('recipient_type', {
            is: 'individual',
            then: () => Yup.string().required("Phone required"),
            otherwise: () => Yup.string().optional(),
        }),
        coupon_id: Yup.string().required("A coupon is required to send a message"),
        // image_url: Yup.string()
    });

    const initialValues = {
        campaign_type: "",
        content: "",
        delivery_schedule: "immediately",
        recipient_type: "individual",
        scheduled_date: new Date(),
        phone: "",
        coupon_id: "",
        // image_url: "",
    }
    const submitAndClose = () => {
        onClose();
        formik.submitForm();
    }
    const handleSubmit = () => {
        console.log(formik.values);
        createCampaign(formik.values);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: CampaignValidationSchema,
        onSubmit: values => {
            handleSubmit();
        },
    })
    const {hasCopied, onCopy} = useClipboard(formik.values.content);
    // const handleChange = (e) => {
    //     setScheduleType(e)
    // }

    // const handleRecipientChange = (e) => {
    //     setRecipientType(e)
    // }
    // if (isLoading || isLoadingCoupons || !user) return <div><Skeleton/></div>;

    useEffect(() => {
        // console.log(currentCoupon)
        setCouponStr(buildCouponStr(currentCoupon));
    }, [currentCoupon]);

    if (!formik || loadingCouponData || !user) return <LoadingPage withHeading={true}/>;
    // if () return <ErrorPage text={getErrorMsg(isRetailerError || plantsError)}/>;


    return (
        <Box>

            <Box bg="white" mt={2} rounded="md">
                <SimpleGrid columns={2} p={4}>
                    <Box>
                        <Center><Text as={"h3"}>Build Message</Text></Center>
                        <form onSubmit={formik.handleSubmit} id="create-campaign-form">
                            <VStack spacing={4} align="flex-start">
                                <FormControl
                                    isInvalid={!!formik.errors.campaign_type && formik.touched.campaign_type}>
                                    <FormLabel htmlFor="type">Type of Message</FormLabel>
                                    <Select
                                        placeholder="Select option"
                                        id="campaign_type"
                                        name="campaign_type"
                                        variant="filled"
                                        onChange={formik.handleChange}
                                        value={formik.values.type}
                                    >
                                        {/*<option value='sale'>Sale Event</option>*/}
                                        {/*<option value='in_store_event'>In Store Event</option>*/}
                                        <option value='other'>Other</option>
                                    </Select>
                                    <FormErrorMessage>{formik.errors.campaign_type}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!formik.errors.content && formik.touched.content}>
                                    <FormLabel htmlFor="value">Content</FormLabel>
                                    <Textarea
                                        id="content"
                                        name="content"
                                        type="text"
                                        variant="filled"
                                        placeholder=""
                                        onChange={formik.handleChange}
                                        value={formik.values.value}
                                    />
                                    <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                                    <Button
                                        bg="primary.400"  // Set the background color to primary.400
                                        color="black"     // Set the text color to white
                                        _hover={{bg: "primary.400"}}  // Set the hover background color
                                        onClick={onCopy}
                                        mt={2}
                                    >
                                        {hasCopied ? "Copied!" : "Copy Content"}
                                    </Button>
                                </FormControl>

                                <CouponListFormControl formik={formik} couponOptions={couponData}
                                                       setCurrentCoupon={setCurrentCoupon}/>

                                <FormControl
                                    isInvalid={formik.errors.delivery_schedule && formik.touched.delivery_schedule}>
                                    <FormLabel>Delivery Schedule</FormLabel>
                                    <RadioGroup defaultValue='immediately'
                                                name="delivery_schedule"
                                                onChange={(value) => formik.setFieldValue('delivery_schedule', value)}
                                                value={formik.values.delivery_schedule}
                                    >
                                        <Stack spacing={2}>
                                            <Radio size="lg" colorScheme='green' value='immediately'>
                                                Immediately
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>

                                {formik.values.delivery_schedule === 'scheduled' && (
                                    <DatePicker
                                        customInput={<DatePickerCustomInput/>}
                                        selected={formik.values.scheduled_date}
                                        showTimeSelect
                                        onChange={(value) => formik.setFieldValue('scheduled_date', value)}
                                    />
                                )}

                                <FormControl
                                    isInvalid={!!formik.errors.recipient_type && formik.touched.recipient_type}>
                                    <FormLabel>Recipients</FormLabel>
                                    <Text mb={2} as={'em'}>Select who you would like to send this message
                                        to.</Text>
                                    <RadioGroup
                                        name="recipient_type"
                                        onChange={(value) => formik.setFieldValue('recipient_type', value)}
                                        value={formik.values.recipient_type}
                                    >
                                        <Stack spacing={4} direction='row'>
                                            <Radio size="lg" colorScheme='green' value='individual'>
                                                Individual
                                            </Radio>
                                            <Radio size="lg" colorScheme='green' value='all'>
                                                All
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                    <FormErrorMessage>{formik.errors.recipient_type}</FormErrorMessage>
                                </FormControl>

                                {formik.values.recipient_type === 'individual' && (
                                    <PhoneFormControl formik={formik} fontSize='md' center={false}
                                                      title={"Enter a Recipient Phone Number"}/>
                                )}

                                {/*{formik.values.recipient_type === 'all' && (*/}
                                {/*    <Text color={"black"}>Caution: This will send a message to all of your*/}
                                {/*        subscribed*/}
                                {/*        SMS customers.</Text>*/}
                                {/*)}*/}
                                {success && (
                                    <Box>
                                        <Text color={"green"}>Message(s) successful sent.</Text>
                                        <Text color={"red"}>If you want to send again, don't forget to copy your
                                            Content
                                            before refreshing the page!</Text>
                                    </Box>
                                )}

                                {formik.values.recipient_type === 'plant-owners' && (
                                    <FormControl>
                                        {/* <PlantSearch
                                    searchUrl={"/retailer/" + user.retailer.id + "/plants/genus"}
                                    responseType="public"
                                    multiSelectMode={true}
                                    values={formik.values.plant}
                                    setFieldValue={formik.setFieldValue}
                                    formErrors={formik.errors.plant}
                                    openUpwards={true}
                                /> */}
                                    </FormControl>
                                )}
                                {pendingCampaignCreate &&
                                    <Text color={"red"}>Now sending. This can take a while depending on the number of
                                        subscribers you have. Please be patient!</Text>}
                                <HStack>
                                    <Button isLoading={pendingCampaignCreate} isDisabled={success} onClick={onOpen}>
                                        Send Message
                                    </Button>
                                    <Modal isOpen={isOpen} onClose={onClose}>
                                        <ModalOverlay/>
                                        <ModalContent>
                                            <ModalHeader>Confirm Send</ModalHeader>
                                            <ModalCloseButton/>
                                            <ModalBody>
                                                <Text color="red.500">
                                                    {formik.values.recipient_type === 'all'
                                                        ? 'This will send to all of your subscribed customers. Are you sure?'
                                                        : 'Are you sure you want to send?'}
                                                </Text>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button variant="ghost" onClick={onClose}>Cancel</Button>
                                                <Box w={10}></Box>
                                                <Button loadingText={'Sending'}
                                                        isLoading={pendingCampaignCreate}
                                                        isDisabled={success} type="submit"
                                                        width="full" onClick={submitAndClose}>
                                                    Confirm and Send
                                                </Button>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>
                                </HStack>
                            </VStack>
                        </form>
                    </Box>
                    <Box>
                        <Center><Text as={"h3"}>Preview Content</Text></Center>
                        <Center>
                            <Box w={"80%"} minH={200} className="message-bubble" whiteSpace="pre-wrap">
                                {formik.values.content}
                                {couponStr && couponStr}
                                {currentCoupon?.image_url && (
                                    <Image src={currentCoupon.image_url} alt="Coupon"/>
                                )}
                            </Box>
                        </Center>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    )
}

export default SMSCampaigns;