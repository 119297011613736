import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import 'react-toastify/dist/ReactToastify.css'
import {PostHogProvider} from 'posthog-js/react'

// import 'bootstrap/dist/css/bootstrap.min.css';

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}
export const theme = extendTheme({
    colors: {
        brand: {
            900: '#1a365d',
            800: '#153e75',
            700: '#2a69ac',
        },
        primary: {
            // 50: '#f2f9f5',
            // 100: '#d6eee1',
            // 200: '#b9e3cc',
            // 300: '#9bdaaf',
            300: '#8FC73E',
            400: '#D3E0E8',
            // 500: '#5eb76b',
            // 600: '#4ca85b',
            // 700: '#3a9850',
            // 800: '#287944',
            500: '#0f5730',
        },
        secondary: {
            50: '#e5eef2',
            100: '#c1d8e0',
            200: '#9abfcf',
            300: '#71a8bf',
            400: '#4a91af',
            500: '#8FC73E',
            600: '#335aa1',
            700: '#28468d',
            800: '#1e347a',
            900: '#16255d',
        }
    },
    components: {
        Button: {
            defaultProps: {
                colorScheme: 'primary',
            },
            baseStyle: {
                _hover: {
                    bg: "primary.500",  // Set the hover background color
                    color: "white",     // Set the hover text color
                },
            },
        },
    },
    styles: {
        global: {
            body: {
                bg: 'gray.50',
                color: 'gray.900',
            },
            h1: {
                fontsize: '2xl',
                fontWeight: 'bold'
            },
            h2: {
                fontSize: '2xl',
                fontWeight: 'bold',
            },
            h3: {
                fontSize: 'lg',
                fontWeight: 'bold'
            },
            h4: {
                fontSize: 'md',
                // fontWeight: 'bold'
            },
            button: {
                colorScheme: 'red'
            },
            ".chakra-card.active": {
                borderColor: 'blue',
                borderWidth: "1px",
                borderStyle: "solid"

            }
        }
    }
});

// const theme = extendTheme({colors})
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <ChakraProvider theme={theme}>
            <PostHogProvider
                apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
                options={options}
            >
                <App/>
            </PostHogProvider>
        </ChakraProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
