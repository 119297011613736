import {useNavigate} from "react-router-dom";
import {AgGridReact} from "ag-grid-react";
import React, {useMemo, useRef, useState} from "react";
import {Input, Box} from "@chakra-ui/react";


const ProductTable = ({productList}) => {
    // Row Data: The data to be displayed.
    const [plants, setPlants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState("");
    const gridRef = useRef();

    let navigate = useNavigate();

    const [colDefs] = useState([
        {field: 'name', headerName: 'Product Name', width: 300, sort: 'asc', flex: 0},
        {field: 'description', width: 600, flex: 0},
        {field: 'type', headerName: 'Type', width: 150},
        {field: 'image_url', headerName: 'Image', width: 150, hide:true}
    ]);

    const navigateToProduct = (e) => {
        navigate("/product/" + e.data.id, {replace: false})

    }
    const defaultColDef = useMemo(() => {
        return {
            // set every column width
            // width: 400,
            flex: 1, // https://stackoverflow.com/questions/65169997/react-ag-grid-auto-size-columns-and-grid-width
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default

        };
    }, []);
    return (
        <div
            className={"ag-theme-quartz"}
            style={{width: '100%', height: '600px'}}
        >
            <Box mb={4}>
                <Input type="text" id="filter-text-box" placeholder="Filter..."
                       onChange={e => setQuickFilterText(e.target.value)}/>
            </Box>
            <AgGridReact
                ref={gridRef}
                suppressExcelExport={true}
                rowHeight={50}
                quickFilterText={quickFilterText}
                defaultColDef={defaultColDef}
                onRowClicked={(e) => navigateToProduct(e)}
                // suppressRowClickSelection={true}
                // onCellEditingStopped={(e) => updateCareInstruction(e)}
                pagination={true}
                rowData={productList}
                columnDefs={colDefs}
            />
        </div>
    );
}

export default ProductTable;