import {Box} from "@chakra-ui/react";
import CustomerTable from '../CustomerTable'

const Customers = () => {

    return (
        <>
            <Box mb={4}>
                <h2>Customers</h2>
                <p><em>A list of customers that have signed up, at some point, for notifications/emails.</em></p>
            </Box>
            <CustomerTable/>
        </>
    );
};

export default Customers;