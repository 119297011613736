import React, {useState} from 'react';
import {useFormik} from "formik";
import {Formik} from 'formik';
import {
    Box,
    Button,
} from "@chakra-ui/react";
import CareInstructionInputs from "./FormControls/CareInstructionInputs";
import axiosInstance from "../../Api";
import ErrorPage from '../Pages/ErrorPage';
import { CARE_INSTRUCTION_TYPES } from '../../utils/constants';
import { useMutation } from "@tanstack/react-query";


// This wasn't using the delivery schedule component, so I removed it in favor of standardizing to single source of truth
// const careInstructionFields = [
//     ["planting", "planting_delivery_schedule"],
//     ["watering", "watering_delivery_schedule"],
//     ["fertilizing", "fertilizing_delivery_schedule"],
//     ["pruning", "pruning_delivery_schedule"]
// ]
const careInstructionFields = CARE_INSTRUCTION_TYPES.map((item) => item.value);


// TODO: This component is broken when going to a plant page for a plant without CIs and trying to add them. Needs fixing.
const CareInstructionForm = ({plant, retailer}) => {
    const [success, setSuccess] = useState(false);

    const { mutate: updateCareInstructions, isPending, error } = useMutation({
        mutationFn: () => axiosInstance.post("/care_instructions", formik.values),
        onSuccess: (response) => {
            formik.resetForm();
            // setSuccess(true);
        },
        onError: (e) => {
            console.log(e);
        },
    });

    const initialValues = {
        instruction: "",
        plant: [],
    }
    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: PlantValidationSchema,
        onSubmit: values => {
            updateCareInstructions();
        },
    });


    if (error) return (<ErrorPage text={getErrorMsg(error)}/>);

    return (
        <Box bg="white" w={800} p={6} rounded="md">
            <Formik
                initialValues={{
                    planting: "testing",
                    watering: "",
                    fertilizing: "",
                    pruning: "",
                    zone: retailer.zone,
                    planting_delivery_schedule: "one_time",
                    watering_delivery_schedule: "weekly",
                    fertilizing_delivery_schedule: "quarterly",
                    pruning_delivery_schedule: "weekly"
                }}

                onSubmit={(values) => {
                    // TODO: need to update the api so it accepts values for delivery schedule instead of UUID...
                    //  hardcoding the ids sucks.
                    const postData = []
                    careInstructionFields.map((group) => {

                        const careInstructionPostBody = {
                            "plant_id": plant.id,
                            // TODO: fix retailer id hardcoding.
                            "retailer_id": "00000000-0000-0000-0000-000000000000",
                            // TODO: fix delivery schedule hardcode.
                            // "delivery_schedule_id": "ca8b219a-233d-4ed3-8d22-0efed0286ab5",
                            "instruction_text": values[group],
                            "preview_image_url": null,
                            "video_url": null,
                            "type": group,
                            "zip_code": retailer.post_code,
                            "zone": values.zone
                        }
                        postData.push(careInstructionPostBody);
                    })
                    handleSubmit(postData);
                }}
            >
                {({handleSubmit, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                        <CareInstructionInputs handleSubmit={handleSubmit} errors={errors} touched={touched}/>
                        {success &&
                            <Box color={"green"}>
                                <p>Care instructions created!</p>
                            </Box>
                        }
                        <Button type="submit" isDisabled={success} width="full">
                            Create
                        </Button>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default CareInstructionForm;