const CARE_INSTRUCTION_TYPES = [
    { value: 'planting', label: 'Planting' },
    { value: 'watering', label: 'Watering' },
    { value: 'pruning', label: 'Pruning' },
    { value: 'fertilizing', label: 'Fertilizing' },
];

const MONTHS = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' },
];

export { CARE_INSTRUCTION_TYPES, MONTHS };