// import logo from './logo.svg';
import './App.css';
import ReactGA from "react-ga4";
import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    GridItem,
    Image,
    Input, Stack, Text
} from '@chakra-ui/react'
import {Link, Outlet, Route, Routes, useLocation, useParams} from "react-router-dom";
import Home from "./component/Pages/Home"
import Customers from "./component/Pages/Customers"
import Dashboard from "./component/Pages/Dashboard"
import Products from "./component/Pages/Products"
import Settings from "./component/Pages/Settings";
import Product from "./component/Pages/Product";
import Design from "./component/Pages/Design";
import Sidebar from "./component/Sidebar";
import Marketing from "./component/Pages/Marketing";
import Training from "./component/Pages/Training";
import Plants from "./component/Pages/Plants";
import Plant from "./component/Pages/Plant";
import CompetitionTracker from "./component/Pages/CompetitionTracker";
import RequestResetPassword from "./component/Login/RequestResetPassword";
import React, {useState} from "react";
import Login from "./component/Login/Login";
import useToken from "./component/Login/UseToken"
import axiosInstance from "./Api";
import Logout from "./component/Login/Logout";
import Enroll from "./component/Pages/Enroll";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import Coupons from "./component/Pages/Coupons";
import {AuthProvider, RetailersProvider} from './component/Providers';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import ManageEnrollments from "./component/Pages/ManageEnrollments";
import TermsOfService from "./component/TermsOfService";
import PrivacyPolicy from "./component/PrivacyPolicy";
import logo from "./images/logo.png";
import ErrorMessage from "./component/Forms/ErrorMessage";
import {Label} from "recharts";
import SetNewPassword from "./component/Login/SetNewPassword";
import AddPlant from './component/Pages/AddPlant';
import PublicPlantPage from "./component/Pages/PublicPlantPage";
import {ToastContainer} from 'react-toastify';
import PlantTable from "./component/PlantTable";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            // refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            // failureCount: 3
            staleTime: 5 * 60 * 1000, // 5 minutes
            //staleTime: 10 * 1000, // 3 seconds
        },
    },
});


function App() {

    const {token, setToken, setRefreshToken} = useToken();
    // https://www.geeksforgeeks.org/how-to-log-out-user-from-app-using-reactjs/
    // const [isLoggedin, setIsLoggedIn] = useState(false);
    const location = useLocation();

    // TODO: this seems insane to do this way. but idk what else to do cause i suck at this.
    // basically if the user is accessing a plant page and doesn't have a token, we give them the public plant page.
    if (!token && location.pathname.includes('/plants/')) {
        return (
            <QueryClientProvider client={queryClient}>
                <Container bgGradient='linear(to-r, gray.100, gray.200)' p={4} maxWidth={'1200'}>
                    <Routes>
                        <Route path="/plants/genus/:id/:retailerId/:aliasId"
                               element={<PublicPlantPage/>}/>
                        <Route path="*" element={<NoMatch/>}/>
                    </Routes>
                </Container>
            </QueryClientProvider>
        )
    }

    if (location.pathname.includes('/competition')) {
        return (
            <QueryClientProvider client={queryClient}>
                <Container bgGradient='linear(to-r, gray.100, gray.200)' p={4} maxWidth={'1200'} minHeight={'100vh'}>
                    <Routes>
                        <Route path={"/competition"} element={<CompetitionTracker/>}></Route>
                        <Route path="*" element={<NoMatch/>}/>
                    </Routes>
                </Container>
            </QueryClientProvider>
        )
    }

    if (location.pathname.includes('/enroll/')) {
        return (
            <QueryClientProvider client={queryClient}>
                {/*<GridItem colSpan={{base: 6, md: 5}}>*/}
                {/*    <Box>*/}
                <Routes>
                    <Route path="/enroll" element={<Enroll/>}>
                        <Route path=":retailer_id"/>
                    </Route>
                    <Route path="/enroll/terms-of-service" element={<TermsOfService/>}/>
                    <Route path="/enroll/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
                {/*</Box>*/}
                {/*</GridItem>*/}
                <ReactQueryDevtools/>
            </QueryClientProvider>
        )
    }

    if (location.pathname.includes('/manage/sms')) {
        return (
            <QueryClientProvider client={queryClient}>
                <GridItem colSpan={{base: 6, md: 5}}>
                    <Box>
                        <Routes>
                            <Route path="/manage/sms/:token" element={<ManageEnrollments/>}/>
                            <Route path="*" element={<NoMatch/>}/>
                        </Routes>
                    </Box>
                </GridItem>
                <ReactQueryDevtools/>
            </QueryClientProvider>
        )
    }
    if (location.pathname === '/request-reset') {
        return (
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/request-reset"
                           element={<RequestResetPassword/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
            </QueryClientProvider>
        )
    }
    if (location.pathname.includes('/set-pass')) {
        return (
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/set-pass/:ptoken"
                           element={<SetNewPassword/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
            </QueryClientProvider>
        )
    }
    if (!token) {
        return <Login setToken={setToken} setRefreshToken={setRefreshToken}/>
    }

    const currentEnv = process.env.REACT_APP_ENV;
    if (currentEnv === 'prod') {
        ReactGA.initialize("G-CNYVQ3VFG2");
    }
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <RetailersProvider>
                        {/* https://reactrouter.com/en/main/start/concepts#index-routes */}
                        <Routes>
                            <Route element={<Layout token={token}/>}>
                                <Route index path="/" element={<Dashboard/>}/>
                                {/*TODO: handle redirects for random urls and where they should go after login.*/}
                                {/*<Route path="/login" element={<Dashboard/>}/>*/}
                                <Route path="/customers" element={<Customers/>}/>
                                <Route path="/products" element={<Products/>}/>
                                <Route path="/coupons" element={<Coupons/>}/>
                                <Route path="/product/:id" element={<Product/>}/>
                                <Route path="/marketing" element={<Marketing/>}>
                                    {/*<Route path=":plantId" element={<Marketing/>}></Route>*/}
                                </Route>
                                <Route path="/training" element={<Training/>}/>
                                <Route path="/plants" element={<Plants/>}/>
                                {/*<Route*/}
                                {/*    path="/plants/care_edits"*/}
                                {/*    element={*/}
                                {/*        <PlantTable*/}
                                {/*            plantUrl="me/plants/genus/care"*/}
                                {/*            colDefs={careColDefs}*/}
                                {/*            careEditTable={true}*/}
                                {/*            renderCareData={true}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*/>*/}
                                <Route path="/plants/add" element={<AddPlant/>}/>
                                <Route path="/plants/genus/:id" element={<Plant/>}/>
                                <Route path="/plants/genus/:id/:retailerId/:aliasId"
                                       element={<PublicPlantPage/>}/>
                                <Route path="*" element={<NoMatch/>}/>
                                <Route path="/design" element={<Design/>}/>
                                <Route path="/settings" element={<Settings/>}/>
                                <Route path="/logout" element={<Logout/>}></Route>
                                <Route path="*" element={<NoMatch/>}/>
                                {/*<Route path="/logout" element={<Logout setIsLoggedIn={setIsLoggedIn}/>}></Route>*/}
                            </Route>
                            {/*<Route element={<enroll/>}>*/}
                            {/*<Route path="/enroll" element={<Enroll/>}/>*/}
                            {/*<Route path="/enroll/:retailer_id" element={<Enroll/>}/>*/}
                        </Routes>
                    </RetailersProvider>
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false}/>
            </QueryClientProvider>
        </>
    );
}

// TODO: brett is a liar.
// customize the layout based on the pathname.
// Fix the routes. so that i don't do the stuff brett tells me to do.

function Layout({token}) {
    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {!token || token === undefined ?
                <Center>
                    <Box w={400} h={400}>
                        <Center>
                            <Image src={logo} alt='Plantista Logo' w={200}/>
                        </Center>
                        <Outlet/>
                    </Box>
                </Center>
                :
                <Grid
                    // h='200px'
                    // templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(6, 1fr)'
                    gap={4}
                    columns={{sm: 1, md: 2}}
                    autoColumns={true}
                >
                    <GridItem colSpan={1}>
                        {/*<Sidebar setIsLoggedIn={setIsLoggedIn}/>*/}
                        <Sidebar/>
                    </GridItem>
                    {/*https://github.com/chakra-ui/chakra-ui/issues/5179*/}
                    <GridItem colSpan={{base: 6, md: 5}}>
                        <Box maxWidth={'1200'} pt={4} p={4}>
                            {/* An <Outlet> renders whatever child route is currently active,
                         so you can think about this <Outlet> as a placeholder for
                         the child routes we defined above. */
                            }
                            <Outlet/>
                        </Box>
                    </GridItem>
                </Grid>
            }
        </div>
    )
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}


export default App;
