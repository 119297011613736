import {
    Box,
    Center,
    Container,
    Divider, Flex,
    Progress, Stack,
    Table, TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import {useQuery} from "@tanstack/react-query";
import axiosInstance from "../../Api";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";
import {useEffect, useState} from "react";

const plantJokes = {
    "jokes": {
        1: "Why do plants hate math? Because it gives them square roots.",
        2: "What did the big flower say to the little flower? 'Hey, bud!'",
        3: "Why was the gardener so good at math? He knew how to mulch-tiply!",
        4: "Why do trees seem suspicious on sunny days? They just seem a little shady.",
        5: "What do you call a plant that works at a bakery? A flour-ist.",
        6: "How do plants greet each other? 'Hey there, green-thumb!'",
        7: "Why did the tree go to the dentist? To get a root canal.",
        8: "What did the cactus say to the sunflower? 'You're looking sharp!'",
        9: "Why did the scarecrow become a successful gardener? Because he was outstanding in his field!",
        10: "What do you call a grumpy gardener? A sore-thistle!",
        11: "How does a tree get on the internet? It logs in.",
        12: "Why don't plants ever play hide and seek? Because they always get 'spotted.'",
        13: "What do you get if you cross a four-leaf clover with poison ivy? A rash of good luck!",
        14: "Why are plants so good at keeping secrets? Because their roots are deep.",
        15: "Why did the tomato turn red? Because it saw the salad dressing.",
        16: "What’s a plant’s favorite drink? Root beer.",
        17: "What do you call a mushroom that’s always invited to parties? A fungi.",
        18: "How do you know when a plant is sad? When it's a weeping willow.",
        19: "What did the fern say to the cactus? 'With fronds like you, who needs spines?'",
        20: "Why was the gardener always calm? Because he was in his 'element.'",
        21: "How do you fix a broken plant? With a 'plant surgeon.'",
        22: "What’s a plant’s favorite type of music? Heavy mulch-metal.",
        23: "What’s a flower’s favorite movie? 'Back to the Fuchsia.'",
        24: "Why did the gardener plant a seed in the computer? To grow a power plant.",
        25: "Why are plants bad secret agents? They can’t keep their leaves shut.",
        26: "What do you call a vegetable that's also a sailor? A swede sailor.",
        27: "Why did the flower go to school? To get to the 'stem' of things.",
        28: "What do you get when you cross a plant and a lightbulb? A power plant.",
        29: "How do plants send secret messages? With 'fern-to-fern' communication.",
        30: "Why are gardeners great at parties? Because they know how to 'turnip' the fun!",
        31: "How do you fix a broken tomato? With tomato paste!"
    }
};

const enrollments = {
    1: 65,
    2: 65,
    3: 65,
    4: 65,
    5: 65,
    6: 65,
    7: 65,
    8: 65,
    9: 65,
    10: 65,
    11: 65,
    12: 65,
    13: 65,
    14: 65,
    15: 65,
    16: 65,
    17: 65,
    18: 65,
    19: 65,
    20: 65,
    21: 65,
    22: 65,
    23: 65,
    24: 65,
    25: 65,
    26: 65,
    27: 65,
    28: 65,
    29: 65,
    30: 65,
    31: 65
};

const getCurrentDayEnrollment = () => {
    const currentDay = new Date().getDate();
    console.log(currentDay);
    console.log(enrollments[currentDay]);
    return enrollments[currentDay];
};

const CompetitionTracker = () => {
    const [fact, setFact] = useState('');
    const [joke, setJoke] = useState('');
    const {
        isLoading: loadingCompetition,
        error: errorCompetition,
        data: competitionData
    } = useQuery({

        queryKey: ['competition'], queryFn: () =>
            axiosInstance.get('/competition').then(response => {
                return response.data;
            })
    });
    useEffect(() => {
        const dayOfMonth = new Date().getDate(); // Get the day of the month
        console.log(dayOfMonth)
        setJoke(plantJokes.jokes[dayOfMonth]);
        console.log(joke);
    }, []);

    if (loadingCompetition) return <LoadingPage/>
    if (errorCompetition) return <ErrorPage text={getErrorMsg(errorDashboard)}/>

    return (
        <>
            <Container bgGradient='linear(to-r, gray.100, gray.200)' p={4} maxWidth={'1200'} minHeight={'100vh'}>
                <Center w="100%" flexDirection="column">
                    <Box maxW={{base: "100%", md: "90%"}} mt={4} textAlign="center" paddingX={{base: "2", md: "0"}}>
                        <Text fontSize="2xl" mb={2}>🚀 Enrollment Competition Tracker 🚀</Text>
                        <Text fontSize="md" as="em">The competition is taking place during the month of June, and prizes
                            will be awarded to the team with the most enrollments.</Text>
                        <Divider mb={4}/>
                        <Text mt={6} mb={4} fontSize="xl">Scoreboard</Text>
                        {/*<Text>Current Leader: B</Text>*/}
                        <VStack spacing={4} alignItems="stretch">
                            <Flex>
                                <Box flexBasis="50%" textAlign="center"><Text as={"b"} fontSize={"l"}>Team Name </Text></Box>

                                <Box flexBasis="50%" textAlign="center"><Text as={"b"} fontSize={"l"}>Enrollments</Text></Box>
                            </Flex>
                            {competitionData.map((team, index) => (
                                <Box>
                                    <Flex key={index} justifyContent="space-between" alignItems="center" padding="2"
                                          borderBottom="1px solid" borderColor="gray.200">
                                        <Box flexBasis="50%" textAlign="center">{team.competitor_name}</Box>

                                        <Box flexBasis="50%" textAlign="center">

                                            {team.competitor_name === 'Alice Eastwood' ?
                                                <Progress colorScheme='green' height='32px'
                                                          value={getCurrentDayEnrollment()} max={200}
                                                          marginBottom="2"/>
                                                :
                                                <Progress colorScheme='green' height='32px' value={team.score} max={200}
                                                          marginBottom="2"/>
                                            }
                                            {/*<Text as={"b"}>Score</Text>*/}
                                            <Box>{team.competitor_name === 'Alice Eastwood' ? getCurrentDayEnrollment() : team.score}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            ))}
                        </VStack>
                        <Center mt={6}>
                            <Box w="100%" maxWidth="400px">
                                <Text fontSize="l">Plant Joke of the Day</Text>
                                <p>{joke}</p>
                            </Box>
                        </Center>
                    </Box>
                </Center>
            </Container>
        </>
    )
        ;
}
export default CompetitionTracker;
