import {Center, Text} from "@chakra-ui/react";
import React from "react";

const EnrollC = () => {
    return (
        <>
            <Center>
                <h2>Care Reminders Signup</h2>
            </Center>

            <Center>
                <Text mt={2} textAlign={"center"}>
                    Receive just-in-time plant care reminders, hear about our latest sales, and more!
                </Text>
            </Center>

        </>
    );
}

export default EnrollC;