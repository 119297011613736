import {Center, Text} from "@chakra-ui/react";
import React from "react";

const EnrollA = () => {
    return (
        <>
            {/*<Center>*/}
            {/*    <Text textAlign={"center"} as={"h2"}>SMS Care Reminders</Text>*/}
            {/*</Center>*/}
            <Center>
                <Text mt={2} textAlign={"center"} fontSize={"1.3em"} as={"em"}>
                    <b><span className={"add-green"}>Sign up</span></b> to receive just-in-time SMS
                    plant <b><span
                    className={"add-green"}>care reminders</span></b>, exclusive <b><span
                    className={"add-green"}>discounts</span></b>, and more!</Text>
            </Center>
        </>
    )
}
export default EnrollA;