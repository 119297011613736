import {Box, Center, Heading, Spinner, VStack} from "@chakra-ui/react";

const LoadingPage = ({withHeading=true}) => {
    return (
        <>
            <Box p={4}>
                <VStack>
                    {withHeading && <Heading as={"h5"}>Loading...</Heading>}
                    <Spinner size='lg'/>
                </VStack>
            </Box>

        </>
    )
}
export default LoadingPage;
