import {
    Box,
    Card,
    CardBody,
    Container,
    Flex,
    Heading,
    HStack,
    SimpleGrid, Spacer,
    Stack, Table, TableCaption,
    TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr
} from "@chakra-ui/react";
import {
    ResponsiveContainer,
    CartesianGrid,
    Line,
    LineChart,
    XAxis,
    YAxis,
} from "recharts";
import {useQuery} from "@tanstack/react-query";
import axiosInstance from "../../Api";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import {useAuth} from "../Providers/Auth/AuthContext";
import {forecastData} from "../DemoForecastData";
import {getErrorMsg} from "../../utils/funcs";

const data = [
    {
        name: 'March',
        // uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'April',
        // uv: 3000,
        pv: 3200,
        amt: 2210,
    },
    {
        name: 'May',
        // uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'June',
        // uv: 2780,
        pv: 9700,
        amt: 2000,
    },
    {
        name: 'July',
        // uv: 1890,
        pv: 8000,
        amt: 2181,
    },
    {
        name: 'August',
        // uv: 2390,
        pv: 7000,
        amt: 2500,
    },
    {
        name: 'September',
        // uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const Dashboard = () => {

    const {user} = useAuth();
    const {
        isLoading: loadingDashboard,
        error: errorDashboard,
        data: dashboardData
    } = useQuery({

        queryKey: ['dashboard', user?.retailer.id], queryFn: () =>
            axiosInstance.get('/me/dashboard/').then(response => {
                return response.data;
            })
    });
    const {
        isLoading: loadingWeather,
        error: errorWeather,
        data: weatherData
    } = useQuery({
        enabled: false, // TODO: add back when ready
        queryKey: ['weather', user?.retailer.business_name], queryFn: () =>
            axiosInstance.get('/me/weather', {params: {lat: 37.83493, lng: -122.12969}}).then(response => {
                return response.data;
            })
    });

    if (loadingDashboard) return <LoadingPage/>
    if (errorDashboard) return <ErrorPage text={getErrorMsg(errorDashboard)}/>

    return (
        <>
            <Box mb={4}>
                <h2>Dashboard</h2>
                <p><em>Insights into topline and customer enagement performance.</em></p>
            </Box>
            <SimpleGrid
                columns={3}
                rows={3}
                rowGap={4}
                textAlign={"center"}>
                <Box>
                    <Card maxW='sm' h={150}>
                        <CardBody>
                            <Stack spacing='3'>
                                <Heading size='md'>Customer Enrollments</Heading>
                                <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.distinct_customer_ct.day}
                                    </Text>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.distinct_customer_ct.week}
                                    </Text>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.distinct_customer_ct.year}
                                    </Text>
                                    <Text>day</Text>
                                    <Text>week</Text>
                                    <Text>year</Text>
                                </SimpleGrid>
                            </Stack>
                        </CardBody>
                    </Card>
                </Box>
                <Box>
                    <Card maxW='sm' h={150}>
                        <CardBody>
                            <Stack spacing='3'>
                                <Heading size='md'>Plant Enrollments</Heading>
                                <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.plant_enrollment_cts.day}
                                    </Text>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.plant_enrollment_cts.week}
                                    </Text>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.plant_enrollment_cts.year}
                                    </Text>
                                    <Text>day</Text>
                                    <Text>week</Text>
                                    <Text>year</Text>
                                </SimpleGrid>
                            </Stack>
                        </CardBody>
                    </Card>
                </Box>
                <Box>
                    <Card maxW='sm' h={150}>
                        <CardBody>
                            <Stack spacing='3'>
                                <Heading size='md'>Messages Sent</Heading>
                                <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.distinct_messages_ct.day}
                                    </Text>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.distinct_messages_ct.week}
                                    </Text>
                                    <Text color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.distinct_messages_ct.year}
                                    </Text>
                                    <Text>day</Text>
                                    <Text>week</Text>
                                    <Text>year</Text>
                                </SimpleGrid>
                            </Stack>
                            {/*<Stack spacing='3'>*/}
                            {/*    <Heading size='md'>Messages Sent (24H)</Heading>*/}
                            {/*    <Text color='black.600' fontSize='2xl'>*/}
                            {/*        {dashboardData && dashboardData.distinct_messages_ct.day}*/}
                            {/*    </Text>*/}
                            {/*</Stack>*/}
                        </CardBody>
                    </Card>
                </Box>
                {/**/}
                {/*<Box>*/}
                {/*    <Card maxW='sm' h={150}>*/}
                {/*        <CardBody>*/}
                {/*            <Stack spacing='3'>*/}
                {/*                <Heading size='md'>New Enrollments (week)</Heading>*/}
                {/*                <Text color='black.600' fontSize='2xl'>*/}
                {/*                    {dashboardData && dashboardData.distinct_customer_ct.week}*/}

                {/*                </Text>*/}
                {/*            </Stack>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Box>*/}
                {/*<Box>*/}
                {/*    <Card maxW='sm' h={150}>*/}
                {/*        <CardBody>*/}
                {/*            <Stack spacing='3'>*/}
                {/*                <Heading size='md'>Messages Sent (week)</Heading>*/}
                {/*                <Text color='black.600' fontSize='2xl'>*/}
                {/*                    {dashboardData && dashboardData.distinct_messages_ct.week}*/}
                {/*                </Text>*/}
                {/*            </Stack>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Box>*/}
                {/*<Box>*/}
                {/*    <Card maxW='sm' h={150}>*/}
                {/*        <CardBody>*/}
                {/*            <Stack spacing='3'>*/}
                {/*                <Heading size='md'>Recommendations Made (week)</Heading>*/}
                {/*                <Text color='black.600' fontSize='2xl'>*/}
                {/*                    {dashboardData && dashboardData.recommendations_made_ct.week}*/}
                {/*                </Text>*/}
                {/*            </Stack>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Box>*/}
                {/*<Box>*/}
                {/*    <Card maxW='sm' h={150}>*/}
                {/*        <CardBody>*/}
                {/*            <Stack spacing='3'>*/}
                {/*                <Heading size='md'>New Enrollments (Year)</Heading>*/}
                {/*                <Text color='black.600' fontSize='2xl'>*/}
                {/*                    {dashboardData && dashboardData.distinct_customer_ct.year}*/}
                {/*                </Text>*/}
                {/*            </Stack>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Box>*/}
            </SimpleGrid>
            <Spacer h={5}/>

            <SimpleGrid
                columns={3}
                rows={2}
                rowGap={4}
                textAlign={"center"}>
                <Box>
                    <Card maxW='sm' h={150}>
                        <CardBody>
                            <Stack spacing='3'>
                                <Heading size='md'>Top Plant</Heading>
                                <SimpleGrid columns={2} rows={2} textAlign={"center"}>
                                    <Text noOfLines={1} color='black.600' fontSize='1xl'>
                                        {dashboardData && dashboardData.top_plant.day !== undefined && dashboardData.top_plant.day !== null
                                            ? dashboardData.top_plant.day
                                            : "N/A"}
                                    </Text>
                                    <Text noOfLines={1} color='black.600' fontSize='1xl'>
                                        {dashboardData && dashboardData.top_plant.week}
                                    </Text>
                                    <Text>day</Text>
                                    <Text>week</Text>

                                </SimpleGrid>
                            </Stack>
                            {/*<Stack spacing='3'>*/}
                            {/*    <Heading size='md'>Top Plant (week)</Heading>*/}
                            {/*    <Text color='black.600' fontSize='2xl'>*/}
                            {/*        {dashboardData && dashboardData.top_plant.week}*/}
                            {/*    </Text>*/}
                            {/*</Stack>*/}
                        </CardBody>
                    </Card>
                </Box>
                <Box>
                    <Card maxW='sm' h={150}>
                        <CardBody>
                            <Stack spacing='3'>
                                <Heading size='md'>Genuses Added</Heading>
                                <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                    <Text noOfLines={1} color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.new_plants_ct.day}
                                    </Text>
                                    <Text noOfLines={1} color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.new_plants_ct.week}
                                    </Text>
                                    <Text noOfLines={1} color='black.600' fontSize='2xl'>
                                        {dashboardData && dashboardData.new_plants_ct.year}
                                    </Text>
                                    <Text>day</Text>
                                    <Text>week</Text>
                                    <Text>year</Text>
                                </SimpleGrid>
                            </Stack>
                            {/*<Stack spacing='3'>*/}
                            {/*    <Heading size='md'>Plants Added (week)</Heading>*/}
                            {/*    <Text color='black.600' fontSize='2xl'>*/}
                            {/*        {dashboardData && dashboardData.new_plants_ct.week}*/}
                            {/*    </Text>*/}
                            {/*</Stack>*/}
                        </CardBody>
                    </Card>
                </Box>
                <Box>
                    <Card maxW='sm' h={150}>
                        <CardBody>
                            <Stack spacing='3'>
                                <Heading size='md'>Opt Outs</Heading>
                                <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                    <Text noOfLines={1} color='black.600' fontSize='2xl'>
                                        {dashboardData?.opt_out_events_ct?.day}
                                    </Text>
                                    <Text noOfLines={1} color='black.600' fontSize='2xl'>
                                        {dashboardData?.opt_out_events_ct?.week}
                                    </Text>
                                    <Text noOfLines={1} color='black.600' fontSize='2xl'>
                                        {dashboardData?.opt_out_events_ct?.year}
                                    </Text>
                                    {/*<Text noOfLines={1} color='black.600' fontSize='2xl'>*/}
                                    {/*    {dashboardData?.convo_event_cts?.day?.custom_data?.stop ?? 0}*/}
                                    {/*</Text>*/}
                                    {/*<Text noOfLines={1} color='black.600' fontSize='2xl'>*/}
                                    {/*    {dashboardData?.convo_event_cts?.week?.custom_data?.stop ?? 0}*/}
                                    {/*</Text>*/}
                                    {/*<Text noOfLines={1} color='black.600' fontSize='2xl'>*/}
                                    {/*    {dashboardData?.convo_event_cts?.year?.custom_data?.stop ?? 0}*/}
                                    {/*</Text>*/}
                                    <Text>day</Text>
                                    <Text>week</Text>
                                    <Text>year</Text>
                                </SimpleGrid>
                            </Stack>
                            {/*<Stack spacing='3'>*/}
                            {/*    <Heading size='md'>Opt Outs (week)</Heading>*/}
                            {/*    <Text color='black.600' fontSize='2xl'>*/}
                            {/*        {dashboardData?.convo_event_cts?.week?.custom_data?.stop ?? 0}*/}
                            {/*    </Text>*/}
                            {/*</Stack>*/}
                        </CardBody>
                    </Card>
                </Box>
            </SimpleGrid>
            <Box>
                <Card mt={4} h={150}>
                    <CardBody>
                        <Stack spacing='3'>
                            <Heading textAlign="center" size='md'>Recommendations Made</Heading>
                            <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                <Text color='black.600' fontSize='2xl'>
                                    {dashboardData && dashboardData.recommendations_made_ct.day}
                                </Text>
                                <Text color='black.600' fontSize='2xl'>
                                    {dashboardData && dashboardData.recommendations_made_ct.week}
                                </Text>
                                <Text color='black.600' fontSize='2xl'>
                                    {dashboardData && dashboardData.recommendations_made_ct.year}
                                </Text>
                                <Text>day</Text>
                                <Text>week</Text>
                                <Text>year</Text>
                            </SimpleGrid>
                        </Stack>
                        {/*<Stack spacing='3'>*/}
                        {/*    <Heading size='md'>Recommendations Made (24H)</Heading>*/}
                        {/*    <Text color='black.600' fontSize='2xl'>*/}
                        {/*        {dashboardData && dashboardData.recommendations_made_ct.day}*/}
                        {/*    </Text>*/}
                        {/*</Stack>*/}
                    </CardBody>
                </Card>
            </Box>
            <Box>
                <Card mt={4} h={150}>
                    <CardBody>
                        <Stack spacing='3'>
                            <Heading textAlign="center" size='md'>Incremental Revenue Est.</Heading>
                            <SimpleGrid columns={3} rows={2} textAlign={"center"}>
                                <Text color='black.600' fontSize='2xl'>
                                    ${dashboardData && dashboardData.est_incremental.day}
                                </Text>
                                <Text color='black.600' fontSize='2xl'>
                                    ${dashboardData && dashboardData.est_incremental.week}
                                </Text>
                                <Text color='black.600' fontSize='2xl'>
                                    ${dashboardData && dashboardData.est_incremental.year}
                                </Text>
                                <Text>day</Text>
                                <Text>week</Text>
                                <Text>year</Text>
                            </SimpleGrid>
                        </Stack>
                        {/*<Stack spacing='3'>*/}
                        {/*    <Heading size='md'>Opt Outs (week)</Heading>*/}
                        {/*    <Text color='black.600' fontSize='2xl'>*/}
                        {/*        {dashboardData?.convo_event_cts?.week?.custom_data?.stop ?? 0}*/}
                        {/*    </Text>*/}
                        {/*</Stack>*/}
                    </CardBody>
                </Card>
            </Box>
            {/*<Box mt={4} mb={4}>*/}
            {/*    <h3>Historic Revenue</h3>*/}
            {/*    <Box width={1200} height={400}>*/}
            {/*        <ResponsiveContainer width={"100%"} height={"100%"}>*/}
            {/*            <LineChart width={800} height={300} data={data}>*/}
            {/*                <XAxis dataKey="name"/>*/}
            {/*                <YAxis/>*/}
            {/*                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>*/}
            {/*                <Line type="monotone" dataKey="uv" stroke="#8884d8"/>*/}
            {/*                <Line type="monotone" dataKey="pv" stroke="#82ca9d"/>*/}
            {/*            </LineChart>*/}
            {/*        </ResponsiveContainer>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*<Box mt={4} mb={4}>*/}
            {/*    <h2>Weather</h2>*/}
            {/*    <p><em>Showing weather data for 94556</em></p>*/}
            {/*    <SimpleGrid*/}
            {/*        columns={3}*/}
            {/*        rows={2}*/}
            {/*        rowGap={4}*/}
            {/*        mt={2}>*/}
            {/*        <Box>*/}
            {/*            <Card maxW='sm'>*/}
            {/*                <CardBody>*/}
            {/*                    <Stack spacing='3'>*/}
            {/*                        <Heading size='md'>Current Temp</Heading>*/}
            {/*                        <Text color='black.600' fontSize='2xl'>*/}
            {/*                            {forecastData ? forecastData['current']['temperature_2m'] + " °F" : "N/A"}*/}
            {/*                        </Text>*/}
            {/*                    </Stack>*/}
            {/*                </CardBody>*/}
            {/*            </Card>*/}
            {/*        </Box>*/}
            {/*        <Box>*/}
            {/*            <Card maxW='sm'>*/}
            {/*                <CardBody>*/}
            {/*                    <Stack spacing='3'>*/}
            {/*                        <Heading size='md'>Precipitation Last Hour</Heading>*/}
            {/*                        <Text color='black.600' fontSize='2xl'>*/}
            {/*                            {forecastData ? forecastData['current']['precipitation'] + " inches" : "N/A"}*/}
            {/*                        </Text>*/}
            {/*                    </Stack>*/}
            {/*                </CardBody>*/}
            {/*            </Card>*/}
            {/*        </Box>*/}
            {/*        <Box>*/}
            {/*            <Card maxW='sm'>*/}
            {/*                <CardBody>*/}
            {/*                    <Stack spacing='3'>*/}
            {/*                        <Heading size='md'>Windspeed</Heading>*/}
            {/*                        <Text color='black.600' fontSize='2xl'>*/}
            {/*                            {forecastData ? forecastData['current']['wind_speed_10m'] + " mph" : "N/A"}*/}
            {/*                        </Text>*/}
            {/*                    </Stack>*/}
            {/*                </CardBody>*/}
            {/*            </Card>*/}
            {/*        </Box>*/}
            {/*    </SimpleGrid>*/}
            {/*    <Box mt={4}><h3>7-day Forecast</h3></Box>*/}

            {/*    <TableContainer mt={2}>*/}
            {/*        <Table variant='simple'>*/}
            {/*            /!*<TableCaption>7-day forecast</TableCaption>*!/*/}
            {/*            <Thead>*/}
            {/*                <Tr>*/}
            {/*                    <Th>Day</Th>*/}
            {/*                    <Th>High</Th>*/}
            {/*                    <Th>Low </Th>*/}
            {/*                </Tr>*/}
            {/*            </Thead>*/}
            {/*            <Tbody>*/}
            {/*                {forecastData &&*/}
            {/*                    forecastData['formatted_daily_data'].map((data) =>*/}
            {/*                        <Tr key={data['date']}>*/}
            {/*                            <Td>{data['date']}</Td>*/}
            {/*                            <Td>{data['temperature_2m_min']}</Td>*/}
            {/*                            <Td>{data['temperature_2m_max']}</Td>*/}
            {/*                        </Tr>*/}
            {/*                    )}*/}
            {/*            </Tbody>*/}
            {/*        </Table>*/}
            {/*    </TableContainer>*/}
            {/*</Box>*/}
        </>
    );
};

export default Dashboard;