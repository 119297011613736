import {Center, Text} from "@chakra-ui/react";
import React from "react";

const EnrollB = () => {
    return (
        <>
            <Center>
                <h2>Keep Your Plant Alive</h2>
            </Center>
            <Center>
                <Text mt={2} textAlign={"center"}>
                    <em>Wondering when to fertilize, water, or winterize your plant? We'll tell you, just in
                        time.</em>
                </Text>
            </Center>
        </>
    );
}

export default EnrollB;