import {Box, Center, Stack, Text} from "@chakra-ui/react";
import {Link} from "react-router-dom";

const TermsOfService = () => {
    return (
        <>
            <Center>
                <Box minW={400} maxW={600} p={4}>
                    <Text textAlign={"center"} fontSize={"lg"}>Terms of Service</Text>
                    <Text textAlign={"center"} fontSize={"md"}>Text Message Notifications via <Link to={"https://getplantista.com"}>Plantista</Link></Text>
                    <Box mt={4}>
                        <Stack spacing={4}>
                            <p>1. You can cancel the SMS service at any time. Just text "STOP" back to the sending
                                number.
                                After
                                you send the SMS
                                message "STOP" to us, we will send you an SMS message to confirm that you have been
                                unsubscribed. After this,
                                you will no longer receive SMS messages from us. If you want to join again, text "START"
                                and
                                and
                                we will start sending SMS messages to you again.</p>

                            <p>2. If you are experiencing issues with the messaging program you can reply with the
                                keyword
                                HELP
                                for more
                                assistance, or you can get help directly at <span>contact@getplantista.com </span>.</p>

                            <p>3. Carriers are not liable for delayed or undelivered messages.</p>

                            <p>4. As always, message and data rates may apply for any messages sent to you from us and
                                to us
                                from you.
                                You will receive 2-8 messages per month, per plant that you are enrolled to receive
                                notifications from.
                                If you have any questions about your text plan or data plan, it is best to contact your
                                wireless
                                provider.</p>

                            <p>5. If you have any questions regarding privacy, please read our <Link
                                to={"/enroll/privacy-policy"}>privacy
                                policy.</Link></p>
                        </Stack>
                    </Box>
                </Box>
            </Center>
        </>
    )

}
export default TermsOfService;