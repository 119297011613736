import {Button, Card, CardBody, CardFooter, CardHeader, Center, Heading, Stack, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import moment from 'moment';


const ProductPlantMiniCard = ({plantData, removeEnrollment, removeRecommendation, startDate = null, cardWidth = '200px'}) => {
    let localDateStr = '';
    if (startDate) {
        localDateStr = moment(startDate).local().format('YYYY-MM-DD');
    }


    return (
        <>
            <Card align="center" w={cardWidth}>
                {/*<CardHeader>*/}
                {/*    <Heading color="green" size='sm'>{plantData.name}</Heading>*/}
                {/*</CardHeader>*/}
                <CardBody>
                    <Stack spacing={3} align='center'>
                        <Heading color="green" size='md'>{plantData.name}</Heading>
                        {plantData.category ? <Text><em>{plantData.category}</em></Text> :
                            <Text><em>category not set.</em></Text>}
                        {localDateStr && <Text><b>Enrolled on:</b> {localDateStr}</Text>}
                        <Text><b>Sent for:</b></Text>
                        {plantData.trigger_instruction_type && <Text>{plantData.trigger_instruction_type}</Text>}
                    </Stack>
                    {/*<GenusImage genus={plantData}/>*/}
                </CardBody>
                <CardFooter>
                    {
                        removeRecommendation &&
                        <Button colorScheme="red" genusid={plantData.id}
                                onClick={(e) => removeRecommendation(e)}>Remove</Button>
                    }
                    {
                        removeEnrollment &&
                        <Button colorScheme="red" genusid={plantData.id}
                                onClick={(e) => removeEnrollment(e)}>Remove</Button>
                    }
                </CardFooter>
            </Card>
        </>
    )
}
export default ProductPlantMiniCard;