'use client'

import React, {ReactNode} from 'react'
// https://react-icons.github.io/react-icons/search/#q=cart
import {
    IconButton,
    Image,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps, Button,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
} from '@chakra-ui/react'
import {
    FiHome,
    FiGitBranch,
    FiSettings,
    FiMenu,
    FiUsers,
    FiChevronDown,
    FiCheck,
} from 'react-icons/fi'
import {
    PiPlant,
    PiChalkboardTeacherFill, PiPencilCircle
} from 'react-icons/pi'
import {GiFertilizerBag} from "react-icons/gi";
import {BiSolidOffer} from "react-icons/bi";

import logo from '../images/logo.png';
import {Link} from "react-router-dom";

import { useAuth, useRetailers } from './Providers';
import axiosInstance from '../Api'

// https://chakra-templates.vercel.app/navigation/sidebar


const LinkItems = [
    {name: 'Dashboard', icon: FiHome, href: '/'},
    {name: 'Plants', icon: PiPlant, href: 'plants'},
    {name: 'Marketing', icon: FiGitBranch, href: 'marketing'},
    {name: 'Products', icon: GiFertilizerBag, href: 'products'},
    {name: 'Coupons', icon: BiSolidOffer, href: 'coupons'},
    {name: 'Customers', icon: FiUsers, href: 'customers'},
    // {name: 'Training', icon: PiChalkboardTeacherFill, href: 'training'},
    // {name: 'Design', icon: PiPencilCircle},
    {name: 'Settings', icon: FiSettings, href: 'settings'},
    {name: 'Logout', href: 'logout'}

]

export default function SimpleSidebar() {
    const {isOpen, onOpen, onClose} = useDisclosure()
    return (
        <Box>
            {/*<Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>*/}
            <SidebarContent onClose={() => onClose} display={{base: 'none', md: 'block'}}/>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose}/>
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{base: 'flex', md: 'none'}} onOpen={onOpen}/>
            <Box ml={{base: 0, md: 60}} p="4">
                {/* Content */}
            </Box>
        </Box>
    )
}

// interface SidebarProps extends BoxProps {
//   onClose: () => void
// }

const updateUserRetailer = (user, retailer_id) => {
    if (!user || !user?.is_admin) return;

    axiosInstance.patch(`/admin/user/${user.id}`, { retailer_id: retailer_id }).then(response => {
        window.location.href = "/";
        return;
    });
};

const SidebarContent = ({onClose, ...rest}) => {
    const { user } = useAuth();
    const { retailers } = useRetailers();

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{base: 'full', md: 60}}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Box>
                    <Image maxW={150} src={logo} alt='Plantista Logo'/>

                </Box>
                <CloseButton display={{base: 'flex', md: 'none'}} onClick={onClose}/>
            </Flex>
            {/*<Box mb={2} textAlign={"center"}>*/}
            {/*    <Text as='i' fontSize='sm'>Welcome, Carl</Text>*/}
            {/*</Box>*/}
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} href={link.href}>
                    {link.name}
                </NavItem>
            ))}
            {user?.is_admin && <Menu>
                <MenuButton as={Button} rightIcon={<FiChevronDown/>} variant="outline" mx="4" colorScheme="cyan" size="md" w="80%" mt={4}>
                    Switch Retailers
                </MenuButton>
                <MenuList>
                    {retailers.map((retailer) => {
                        return (
                            <MenuItem key={retailer.id} onClick={() => {
                                updateUserRetailer(user, retailer.id);
                            }} icon={user.retailer_id === retailer.id && <FiCheck />}>
                                {retailer.business_name}
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>}
        </Box>
    )
}

// interface NavItemProps extends FlexProps {
//   icon: IconType
//   children: ReactText
// }
const NavItem = ({icon, href, children, ...rest}) => {
    return (
        <Link to={href}>
            <Box
                // as="a"
                // href={`/${children.toLowerCase()}`}
                // href={href}
                style={{textDecoration: 'none'}}
                _focus={{boxShadow: 'none'}}>
                <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    _hover={{
                        bg: 'cyan.400',
                        color: 'white',
                    }}
                    {...rest}>
                    {icon && (
                        <Icon
                            mr="4"
                            fontSize="16"
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    )}
                    {children}
                </Flex>
            </Box>
        </Link>


    )

}

// interface MobileProps extends FlexProps {
//   onOpen: () => void
// }
const MobileNav = ({onOpen, ...rest}) => {
    return (
        <Flex
            ml={{base: 0, md: 60}}
            px={{base: 4, md: 24}}
            width = "100vw"
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu/>}
            />
            {/*<Box boxSize='sm'>*/}
            {/*<Box boxSize='md'>*/}
            {/*    <Image boxSize={"50px"} src={logo} alt='Plantista Logo'/>*/}
            {/*</Box>*/}
            {/*<Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">*/}
            {/*    Logo*/}
            {/*</Text>*/}
        </Flex>
    )
}