import {Box} from "@chakra-ui/react";

const Design = () => {
    return(
        <>
          <Box mb={4}>
                <h2>Design</h2>
                <p><em>View recent pre-orders and picklists from customers who created a design.</em></p>
            </Box>
    </>
    );
}
export default Design;