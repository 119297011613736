import axiosInstance from "../../Api";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken
};

async function logoutUser() {
    const token = getToken()
    axiosInstance.post(baseUrl + '/me/user/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: token
    })
        .then(
            data => data)
}

const Logout = () => {
    // TODO: this is probably hacky. don't @ me.
    const api_response = logoutUser();
    console.log(api_response);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/";

}


export default Logout;