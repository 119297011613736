import {Box, Center, Stack, Text} from "@chakra-ui/react";
import {Link} from "react-router-dom";

const PrivacyPolicy = () => {
    return (
        <>
            <Center>
                <Box minW={400} maxW={600} p={4}>
                    <Text textAlign={"center"} fontSize={"lg"}>Privacy Policy</Text>
                    {/*<Text textAlign={"center"} fontSize={"md"}>Text Message Notifications via <Link*/}
                    {/*    to={"https://getplantista.com"}>Plantista</Link></Text>*/}
                    <Box mt={4}>
                        <Stack spacing={4}>
                            <p>This Privacy Policy describes how Plantista Inc. ("we," "our," or "us") collects, uses,
                                and
                                discloses your personal information when you use our website and any related services
                                (collectively, the
                                "Services").</p>
                            <h2>1. Information We Collect</h2>
                            <p>We collect personal information you provide to us, such as your phone number, when you
                                sign up for an
                                account or use our Services. We also collect information
                                automatically, such as your IP address, device information, and usage data.</p>
                            <h2>2. How We Use Your Information</h2>
                            <p>We use your personal information to provide and improve our Services, communicate with
                                you, and
                                personalize your experience. We also use your information to send you marketing
                                communications and
                                analyze how users interact with our Services.</p>
                            <h2>3. Information Sharing</h2>
                            <p>We do not sell your personal information to third parties. We may share your information
                                with third-party
                                service providers who help us provide and improve our Services. We may also share your
                                information in
                                response to legal requests or to protect our rights.</p>
                            <h2>4. Data Retention</h2>
                            <p>We retain your personal information for as long as necessary to provide our Services and
                                fulfill the
                                purposes outlined in this Privacy Policy. We may also retain and use your information as
                                necessary to
                                comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                            <h2>5. Security</h2>
                            <p>We take reasonable measures to protect your personal information from unauthorized
                                access, use, and
                                disclosure. However, no method of transmission over the internet or electronic storage
                                is 100% secure,
                                so we cannot guarantee absolute security.</p>
                            <h2>6. Your Choices</h2>
                            <p>You can update your account information and preferences at any time by texting "MANAGE"
                                to one of our
                                numbers. You can also opt out of receiving marketing communications from us by following
                                the
                                instructions in those communications.</p>
                            <h2>7. Changes to This Policy</h2>
                            <p>We may update this Privacy Policy from time to time. If we make material changes, we will
                                notify you by
                                email or by posting a notice on our website prior to the change becoming effective. We
                                encourage you to
                                review this Privacy Policy periodically.</p>
                            <h2>8. Contact Us</h2>
                            <p>If you have any questions about this Privacy Policy, please contact us at contact@getplantista.com.</p>
                            <p>By using our Services, you agree to the terms of this Privacy Policy.</p>
                        </Stack>
                    </Box>
                </Box>
            </Center>
        </>
    )
}
export default PrivacyPolicy;