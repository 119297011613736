import React, {useState} from "react";
import axiosInstance, {getMe} from "../../Api";
import ImageUpload from "../ImageUpload";
import {
    Box,
    Button,
    Divider,
    Image,
    SimpleGrid,
    Skeleton,
    Spacer,
    Spinner,
    StackDivider,
    Text,
    VStack,
    Link,
} from "@chakra-ui/react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../Providers/Auth/AuthContext";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";

const Settings = () => {
        const {user, setUser} = useAuth();
        const [showSkeleton, setShowSkeleton] = useState(false);
        const [loading, setLoading] = useState(false);
        const queryClient = useQueryClient();
        const [hasLogo, setHasLogo] = useState(false)

        // const {
        //     error: userError,
        //     isLoading: userLoading,
        // } = useQuery({
        //     queryKey: ['user', user?.email],
        //     queryFn: async () => {
        //         return await getMe();
        //     },
        // });
        const handleCreate = async () => {
            setShowSkeleton(true);
            createQRCode()
        }
        const handleDelete = async () => {
            updateMe({"retailer": {"logo_url": null}});
        }

        const {mutate: createQRCode, isLoading: loadingCreateQRCode, error: createQRError} = useMutation({
            mutationFn: (addData) => axiosInstance.post('/me/qr_code/enroll', {"type": "generic_enroll"}),
            onMutate: () => {
                setLoading(true)
            },
            mutationKey: ['user', user?.retailer?.qr_code_url],
            onSuccess: (response) => {
                setShowSkeleton(false);
                setUser({...user, retailer: {...user.retailer, qr_code_url: response.data.qr_code_url}})
            },
            onError: (e) => {
                console.log(e);
            },
            onSettled: () => {
                setLoading(false);
            }
        });

        const {mutate: updateMe, isLoading: updateMeLoading, error: updateMeError} = useMutation({
            mutationFn: (putData) => axiosInstance.patch('/me', putData),
            onMutate: () => {
                setLoading(true)
            },
            mutationKey: ['user', user?.retailer?.logo_url],
            onSuccess: (resp) => {
                setUser(resp.data);
            },
            onError: (e) => {
                console.log(e);
            },
            onSettled: () => {
                setLoading(false);
            }
        });

        if (updateMeLoading) return <Skeleton height={"100%"}/>
        if (createQRError || updateMeError) return <ErrorPage text={getErrorMsg(createQRError || updateMeError)}/>;

        return (
            <>
                <Box mb={8}>
                    <h2>Settings</h2>
                    <p><em>View settings about your user and your business.</em></p>
                </Box>
                <Skeleton isLoaded={user}>
                    {user &&
                        <>
                            <VStack
                                divider={<StackDivider borderColor='gray.200'/>}
                                spacing={4}
                                align='stretch'
                                w={600}
                            >
                                <Box>
                                    <h3>User</h3>
                                    <SimpleGrid mt={2} columns={2} spacing={4}>
                                        <Box>Name</Box>
                                        <Box>{user.first_name ? user.first_name : <p>None given</p>}</Box>
                                        <Box>Email</Box>
                                        <Box>{user.email}</Box>
                                    </SimpleGrid>
                                </Box>
                                <Box>
                                    <h3>Retailer</h3>
                                    <SimpleGrid mt={2} columns={2} spacing={6}>
                                        <Box><p>Name</p></Box>
                                        <Box><p>{user.retailer.business_name}</p></Box>
                                        <Box><p>SMS Phone Number</p></Box>
                                        <Box>
                                            {user?.retailer.sms_phone_number ? (
                                                user.retailer.sms_phone_number
                                            ) : (
                                                <p>Not setup</p>
                                            )}
                                        </Box>
                                        <Box><p>Enrollment Page URL</p></Box>
                                        <Box>
                                            <Link
                                                color="#0000EE"
                                                href={`https://app.getplantista.com/enroll/${user?.retailer.id}`}
                                                isExternal
                                            >
                                                {`https://app.getplantista.com/enroll/${user?.retailer.id}`}
                                            </Link>
                                        </Box>
                                        {/*<Box><p>Subdomain</p></Box>*/}
                                        {/*<Box>{(user.retailer.sub_domain !== "") && (user.retailer.sub_domain !== null) ?*/}
                                        {/*    <p>{user.retailer.sub_domain}</p>*/}
                                        {/*    :*/}
                                        {/*    <p>No subdomain set up yet.</p>*/}
                                        {/*}*/}
                                        {/*</Box>*/}
                                        <Box><p>Address</p></Box>
                                        <Box>{user.retailer.address_line1}</Box>
                                        <Box><p>Logo</p></Box>
                                        <Box>{user.retailer.logo_url !== "" && user.retailer.logo_url !== null ?
                                            <div>
                                                <Image
                                                    w={200}
                                                    className={"retailer-logo"}
                                                    src={user.retailer.logo_url}
                                                    borderRadius='lg'
                                                />
                                                <Spacer h={2}/>
                                                <Button isLoading={loading} colorScheme="red" onClick={handleDelete}>Delete
                                                    Logo</Button>
                                            </div>
                                            :
                                            <ImageUpload/>}
                                        </Box>
                                        <Box>QR Code</Box>
                                        <Box>{user.retailer.qr_code_url !== "" && user.retailer.qr_code_url !== null ?
                                            <div>
                                                <Image
                                                    w={200}
                                                    className={"qr-code"}
                                                    src={user.retailer.qr_code_url}
                                                    borderRadius='lg'
                                                />
                                                {/*<Button onClick={handleDelete}>Delete</Button>*/}
                                            </div>
                                            :
                                            <div>

                                                <Text>QR Code Doesn't Exist.</Text>
                                                <Button mt={4} isLoading={loading} onClick={handleCreate}>Generate QR
                                                    Code</Button>

                                            </div>
                                        }
                                        </Box>
                                    </SimpleGrid>
                                </Box>
                            </VStack>

                            <Divider mt={4} w={600}/>

                        </>}
                </Skeleton>
            </>
        );
    }
;

export default Settings;