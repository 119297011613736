// Filename - App.js

import React, {useState} from "react";
import {Box, Button, FormControl, Image, Input} from "@chakra-ui/react";
import {useFormik} from "formik";
import PhoneValidationSchema from "./Forms/CareValidationSchema";
import axiosInstance from "../Api";
import {useAuth} from "./Providers/Auth/AuthContext";

function ImageUpload() {
    const { user, setUser } = useAuth();
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState()
    const [isSent, setIsSent] = useState(false);
    const handleSubmit = async (e) => {
        const formData = new FormData();
        formData.append('file', file);

        axiosInstance.post("/me/retailer/logo_upload", formData, {headers: {
                    'Content-Type': 'multipart/form-data'
                }})
            .then((response) => {
                // Update retailer's logo via AuthContext
                setUser({ ...user, retailer: { ...user.retailer, logo_url: response.data.logo_url } });
                formik.resetForm();
                setIsSent(true);
            }).catch();
    }

    const handleFileChange = (event) => {
        if (event.target.files.length === 0) return;

        // Set file and fileUrl state
        // File state for use in POST request to DB
        // FileURL state for use in Image component to display image before upload
        setFile(event.target.files[0]);
        setFileUrl(URL.createObjectURL(event.target.files[0]));
    };

    const initialValues = {
        file: "",
    }

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: PhoneValidationSchema,
        onSubmit: values => {
            handleSubmit();
        },
    })

    return (
        <Box mt={4} w={400}>
            <form onSubmit={formik.handleSubmit} id="logo-submit-form">
                <FormControl isInvalid={!!formik.errors.file && formik.touched.file}>
                    <Input name="file" type="file" onChange={handleFileChange} onBlur={formik.handleBlur} accept="image/*" paddingTop="1"/>
                    <Image src={fileUrl} w={200} borderRadius="lg" marginX="auto" />
                </FormControl>

                {file && <Button isDisabled={isSent} type="submit" width="full"> Upload </Button>}
            </form>
        </Box>
    );
}

export default ImageUpload;