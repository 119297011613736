import {
    Box,
    Button,
    Center,
    Text
} from "@chakra-ui/react";
import PhoneFormControl from "./FormControls/PhoneFormControl";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {validatePhoneNumber} from "../../utils/funcs";
import {useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import axiosInstance from "../../Api";
import EnrollFinePrint from "./EnrollFinePrint"
import LoadingPage from "../Pages/LoadingPage";

const PhoneEnrollmentForm = ({plantData, retailer}) => {
    const {id, retailerId, aliasId} = useParams();
    const [success, setSuccess] = useState();

    const initialValues = {
        plant: [{"value": id}],
        genus_alias_id: aliasId,
        phone: "",
    }

    const EnrollValidationSchema = Yup.object().shape({
        // plant: Yup.array().required().min(1),
        phone: Yup.string()
            .required('Phone number is required')
            .test('phone', 'Invalid phone number', validatePhoneNumber),
    });

    const {mutate: addEnrollment, isPending: pendingEnrollment, error: enrollmentError} = useMutation({
        // mutationFn: (requestData) => axiosInstance.delete('/me/coupon_product', {data: {payload: requestData}}),
        mutationFn: (postData) => axiosInstance.post('/enroll/' + retailerId, postData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(['enrollments', formik.values.phone]).then();
        },
        onError: (e) => {
            console.log(e);
        },
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: EnrollValidationSchema,
        onSubmit: values => {
            handleSubmit(formik.values);
            formik.resetForm();
        },
    })

    const handleSubmit = (postData) => {
        addEnrollment(postData)
        setSuccess(true);
    }

    // const minPurchaseRequirement = plantData?.recommendedProducts?.[0]?.coupons?.[0]?.min_purchase_requirement;
    const minPurchaseRequirement =
        plantData.recommended_products?.[0]?.coupons?.[0]?.min_purchase_requirement;

    const couponValue =
        plantData.recommended_products?.[0]?.coupons?.[0]?.value;

    if (!retailer) return <LoadingPage text={"Loading..."}/>

    return (
        <>
            <Box p={4} border={"2px"} borderColor={"gray.200"} textAlign={"center"}>
                {/*<Text as="h2" color={"green"}>Keep This Plant Alive!</Text>*/}
                <Text as="h2" color={"green"}>Enroll in Care Reminders</Text>
                <p>Receive just-in-time plant care reminders, hear about our latest sales, and more!</p>
                <br/>
                {/* NOTE - this is a hack for Tagawa. Their coupon does not have min purchase value so we have
                custom rendering. */}
                {minPurchaseRequirement && couponValue && retailerId !== '4cf54c01-e708-4261-8abb-62a403999128' && (
                    <p>
                        Sign up today and receive <b><span className={"add-green"}>${couponValue} coupon</span></b> for your qualifying purchase of
                        ${minPurchaseRequirement} or more.
                    </p>
                )}
                {couponValue && retailerId === '4cf54c01-e708-4261-8abb-62a403999128' && (
                    <p>
                        Sign up today and get a <b><span className={"add-green"}>${couponValue} coupon</span></b> for your
                        next qualifying purchase. Details included in message.
                    </p>
                )}
                <Center>
                    <Box mt={4} maxW="600px" w="100%">
                        <form onSubmit={formik.handleSubmit} id="phone-submit-form" style={{width: '100%'}}>
                            <PhoneFormControl formik={formik} center={true}/>
                            <Button mt={4} h={50} w={200} size="lg"
                                    isDisabled={false} type="submit">Submit! </Button>
                        </form>
                    </Box>
                </Center>
                {success &&
                    <Center>
                        <Box mt={3} textAlign="center" mb={2} color="green"><Text fontSize="xl" as={"b"}> Thank you
                            enrolling.<br/>Expect to receive your first
                            text
                            message within 24 hours.</Text></Box>
                    </Center>
                }
                <EnrollFinePrint maxW={600} center={true}/>
            </Box>
        </>
    )
};

export default PhoneEnrollmentForm;
