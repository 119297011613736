import {Box, Button, Card, CardBody, Heading, SimpleGrid, Skeleton, Stack, Text} from "@chakra-ui/react";
import {useQuery} from "@tanstack/react-query";
import axiosInstance from "../../Api";
import {useAuth} from "../Providers/Auth/AuthContext";
import React, {useState} from "react";
import CouponCreateForm from "../Forms/CouponCreateForm";
import CouponCard from "../CouponCard";
import ErrorPage from "./ErrorPage";
import { getErrorMsg } from "../../utils/funcs";

const Coupons = () => {
    const {user} = useAuth();
    const retailerId = user.retailer_id;
    const [showCouponCreate, setShowCouponCreate] = useState(false);
    const {
        isLoading: isLoadingCouponsData,
        error: errorCouponsData,
        data: CouponsData
    } = useQuery({
        queryKey: ['coupons', retailerId], queryFn: () =>
            axiosInstance.get('/me/coupons').then(response => {
                return response.data;
            })
    });

    const showCreateCoupon = () => {
        setShowCouponCreate(prevCheck => !prevCheck);
    }

    if (isLoadingCouponsData) return <Skeleton/>
    if (errorCouponsData) return <ErrorPage text={getErrorMsg(errorCouponsData)} />;

    return (
        <>
            <Box mb={4}>
                <h2>Coupons</h2>
                <Text as="i">Create and configure coupons that can be attached to specific products.</Text>
                {CouponsData && CouponsData.length > 0 ?
                    <>
                        <SimpleGrid mt={4} spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
                            {CouponsData.map((coupon, index) => (
                                <CouponCard key={coupon.id} couponData={coupon} showRemove={false} showAdd={false}
                                            showDelete={true}></CouponCard>
                            ))}
                        </SimpleGrid>
                        <Box pt={4}>
                            <Button onClick={() => showCreateCoupon()}> Create Coupon</Button>
                            {showCouponCreate && <CouponCreateForm/>}
                        </Box>
                    </>
                    :
                    <>
                        <Text>No coupons to show.</Text>
                        <Box pt={4}>
                            <Button onClick={() => showCreateCoupon()}> Create Coupon</Button>
                            {showCouponCreate && <CouponCreateForm/>}
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}


export default Coupons;
