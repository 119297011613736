import {Box, Center, Text} from "@chakra-ui/react";
import React from "react";

const EnrollFinePrint = ({maxW, center}) => {


    const content = (
        <>
            <Box maxW={maxW}>
                <Box mt={10}>
                    <Text as="em" fontSize={'sm'}>I agree to receive marketing messages, account and plant care
                        notifications at the number provided above. I understand I will receive 2-8 messages per plant
                        per
                        month. Reply "STOP" at any time to opt out. Standard message and data rates may apply. We will
                        not
                        share or sell your phone number.
                    </Text>
                </Box>
                <Center mt={10}>
                    <Text as="em" fontSize={'xs'}>This service is powered by
                        <a target="_blank" href="https://getplantista.com"> Plantista.</a>
                    </Text>
                </Center>
            </Box>
        </>
    )
    return center ? <Center>{content}</Center> : content;
}
export default EnrollFinePrint;