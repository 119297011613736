import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Textarea,
    Heading,
    Text,
    VStack,
    Select,
    IconButton,
    FormErrorMessage,
    FormHelperText
} from "@chakra-ui/react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Form, FormikConsumer, useFormik} from "formik";
import axiosInstance from "../../Api";
import {useNavigate} from "react-router-dom";
import {FiX} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import {CARE_INSTRUCTION_TYPES, MONTHS} from "../../utils/constants";
import * as Yup from "yup";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";
import {useAuth} from "../Providers";

function convertToLabelValueFormat(products, filterByForUseWhen = null) {
    let filteredProducts = products;

    if (filterByForUseWhen) {
        filteredProducts = filteredProducts.filter(product => product.use_when_instruction_type === filterByForUseWhen);
    }

    return filteredProducts.map(product => ({ label: product.name, value: product.id }));
}


const AddPlant = () => {
        const [PlantFormFields, setPlantFormFields] = useState([]);
        const [CareInstructionFormFields, setCareInstructionFormFields] = useState([]);
        const navigate = useNavigate();
        const queryClient = useQueryClient();
        const [file, setFile] = useState(null);
        const {user} = useAuth();

        const formatPayload = (values) => {
            return {
                name: values.name,
                scientific_name: values.scientific_name,
                description: values.description,
                native_to: values.native_to,
                care_difficulty: values?.care_difficulty,
                light_requirements: values.light_requirements,
                usda_plant_zones: values?.usda_plant_zones,
                category: values?.category,
                aliases: (values?.aliases || '').split(",").map(alias => alias.trim()),
                light_amount: values?.light_amount,
                mature_height: values?.mature_height,
                mature_spread: values?.mature_spread,
                plant_in_the: values?.plant_in_the,
                bloom_month: values?.bloom_month,
                drought_tolerant: values?.drought_tolerant,
                notification_stop_date: values?.notification_stop_date === 'year-round' ? null : values?.notification_stop_date,
                common_use: values?.common_use,
                pairs_well_with: values?.pairs_well_with,
                known_for: values?.known_for,
                attracts: values?.attracts,
                repels: values?.repels,
                toxic_to: values?.toxic_to,
                planting_generic_product_rec: values?.planting_generic_product_rec,
                fertilizing_generic_product_rec: values?.planting_generic_product_rec,
                care_instruction: values.care_instruction.map((instruction) => {
                    return {
                        type: instruction.type,
                        instruction_text: instruction.instruction_text,
                        preview_image_url: instruction?.preview_image_url,
                        video_url: instruction?.video_url,
                        zip_code: instruction?.zip_code,
                        zone: instruction?.zone,
                        every_n_days: instruction?.every_n_days,
                    }
                }),
            };
        }

        const {mutate, isPending, error} = useMutation({
            onMutate: () => {
                // setLoading(true)
            },
            mutationFn: (values) => {
                const payload = formatPayload(values);
                const formData = new FormData();
                if (file) formData.append('image', file);
                formData.append('data_in', JSON.stringify(payload));
                return axiosInstance.post('/admin/genus_form', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
            },
            onSuccess: (response) => {
                console.log(response)
                formik.resetForm();
                queryClient.invalidateQueries(['allPlantData', '/plants/genus']).then();
                navigate('/plants');
            },
            onError: (e) => {
                console.log(e);
            },
            onSettled: () => {
                // setLoading(false);
            },
        });

        const {isLoading: loadingGenericProducts, error: errorGenericProducts, data: genericProducts} = useQuery({
            queryKey: ['generic_products', user?.email], queryFn: () =>
                axiosInstance.get('/admin/generic_product').then(response => {
                    console.log(response.data);
                    return response.data;
                })
        });

        const formik = useFormik({
            initialValues: {
                ...PlantFormFields.reduce((acc, field) => {
                    acc[field.value] = field.defaultValue;
                    return acc;
                }, {}),
                care_instruction: [],
            },
            validationSchema: Yup.object().shape({
                ...PlantFormFields.filter((field) => field.validation).reduce((acc, field) => {
                    acc[field.value] = field.validation;
                    return acc;
                }, {}),
                care_instruction: Yup.array().of(Yup.object().shape({
                    ...CareInstructionFormFields.filter((field) => field.validation).reduce((acc, field) => {
                        acc[field.value] = field.validation;
                        return acc;
                    }, {}),
                })).test(
                    "at-least-one-planting-instruction",
                    "At least one care instruction must have type 'planting'",
                    (value) => value.some((item) => item.type === "planting")
                ),
            }),
            // Only validate on form submission, otherwise validaation on change and blur will be too aggressive with error messages
            validateOnChange: false,
            validateOnBlur: false,
            onSubmit: values => {
                mutate(values);
            },
        });

        const handleInstructionDelete = (index) => {
            const newInstructions = formik.values.care_instruction.filter((instruction, i) => i !== index);
            formik.setFieldValue('care_instruction', newInstructions);
        }

        const handleFileChange = (event) => {
            if (event.target.files.length === 0) return;
            // File state for use in POST request to DB
            setFile(event.target.files[0]);
        };

        const selectedInstructionTypes = formik.values.care_instruction.map((instruction) => instruction.type);

        useEffect(() => {
            if (genericProducts) {
                const PlantFormFields = [
                    {
                        value: "name",
                        label: "Plant Name",
                        type: "text",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Name is required."),
                    },
                    {
                        value: "scientific_name",
                        label: "Scientific Name",
                        type: "text",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Scientific name is required."),
                    },
                    {
                        value: "category",
                        label: "Category",
                        type: "dropdown",
                        dropdownOptions: [{label: 'Perennial', value: 'perennial'}, {
                            label: 'Annual',
                            value: 'annual'
                        }, {label: 'Houseplant', value: 'houseplant'}, {label: 'Shrub', value: 'shrub'}, {
                            label: 'Vine',
                            value: 'vine'
                        }],
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().lowercase().oneOf(['perennial', 'annual', 'houseplant', 'shrub', 'vine'], "Category must be one of: perennial, annual, houseplant, shrub, or vine").required("Category is required."),
                    },
                    {
                        value: "aliases",
                        label: "Aliases",
                        type: "text",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().optional().matches(/(\w+)(,\s*\w+)*/), // Confirms aliases are comma separated
                        helperText: "Please provide a comma-separated list of any aliases for this plant",
                    },
                    {
                        value: "description",
                        label: "Description",
                        type: "textarea",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Description is required."),
                    },
                    {
                        value: "usda_plant_zones",
                        label: "USDA Plant Zones",
                        type: "text",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("USDA plant zones is required."),
                    },
                    {
                        value: "care_difficulty",
                        label: "Care Difficulty",
                        type: "dropdown",
                        dropdownOptions: [{label: "Easy", value: "easy"}, {
                            label: "Intermediate",
                            value: "intermediate"
                        }, {label: "Advanced", value: "advanced"}, {label: "Expert", value: "expert"}],
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().lowercase().oneOf(["easy", "intermediate", "advanced", "expert"], "Care difficulty must be easy, intermediate, hard, or expert.").required("Care difficulty is required"),
                    },
                    {
                        value: "light_requirements",
                        label: "Light Requirements",
                        type: "text",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Light requirements is required."),
                    },
                    {
                        value: "light_amount",
                        label: "Light Amount (Hours / Day)",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "mature_height",
                        label: "Mature Height",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: 'mature_spread',
                        label: "Mature Spread",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "native_to",
                        label: "Native To",
                        type: "text",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Native to is required."),
                    },
                    {
                        value: 'plant_in_the',
                        label: "Plant In",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: 'bloom_month',
                        label: "Bloom Month",
                        type: "text",
                        // dropdownOptions: MONTHS,
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: 'notification_stop_date',
                        label: "Send Notifications Through",
                        type: "dropdown",
                        dropdownOptions: [{label: "Year-Round", value: 'year-round'}, ...MONTHS],
                        required: true,
                        defaultValue: undefined,
                    },

                    {
                        value: "common_use",
                        label: "Common Use",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "pairs_well_with",
                        label: "Pairs Well With",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "known_for",
                        label: "Known For",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "attracts",
                        label: "Attracts",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "repels",
                        label: "Repels",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "toxic_to",
                        label: "Toxic To",
                        type: "text",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "drought_tolerant",
                        label: "Drought Tolerant",
                        type: "dropdown",
                        dropdownOptions: [{label: "Yes", value: true}, {label: "No", value: false}],
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "image",
                        label: "Image",
                        type: "file",
                        required: false,
                        defaultValue: undefined,
                    },
                    {
                        value: "planting_generic_product_rec",
                        label: "Planting Generic Product Rec",
                        type: "dropdown",
                        required: false,
                        defaultValue: undefined,
                        dropdownOptions: convertToLabelValueFormat(genericProducts, "planting"),
                    },
                    {
                        value: "fertilizing_generic_product_rec",
                        label: "Fertilizing Generic Product Rec",
                        type: "dropdown",
                        required: false,
                        defaultValue: undefined,
                        dropdownOptions: convertToLabelValueFormat(genericProducts, "fertilizing"),
                    },
                ];

                const CareInstructionFormFields = [
                    {
                        value: "type",
                        label: "Instruction Type",
                        type: "dropdown",
                        dropdownOptions: CARE_INSTRUCTION_TYPES,
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Type is required."),
                    },
                    {
                        value: "instruction_text",
                        label: "Instruction Text",
                        type: "textarea",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.string().required("Instruction text is required."),
                    },
                    {
                        value: "every_n_days",
                        label: "Every N Days",
                        type: "number",
                        required: true,
                        defaultValue: undefined,
                        validation: Yup.number().required("Every n days is required."),
                    },
                ];
                setPlantFormFields(PlantFormFields);
                setCareInstructionFormFields(CareInstructionFormFields);
            }
        }, [genericProducts]);

        return (
            <>
                <Box>
                    <h2>Add Plant</h2>
                    <p><em>Enter the details of the plant you would like to add.</em></p>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing="4" mt="4" align={"start"}>
                        <Box bg={"white"} border="1px" borderColor="black" rounded="md" px='8' py='4' w='75%'>
                            <Heading>Plant Info</Heading>
                            {PlantFormFields.map((field, index) => {
                                return (
                                    <FormControl key={index} isRequired={field?.required}
                                                 isInvalid={formik.errors[field.value]}>
                                        <FormLabel>{field.label}</FormLabel>
                                        {field.type === "text" && (
                                            <Input type="text" placeholder={field.label} name={field.value}
                                                   value={formik.values[field.value]} onChange={formik.handleChange}/>
                                        )}
                                        {field.type === "textarea" && (
                                            <Textarea placeholder={field.label} name={field.value}
                                                      value={formik.values[field.value]} onChange={formik.handleChange}/>
                                        )}
                                        {field.type === "file" && (
                                            <Input type="file" placeholder={field.placeholder} name={field.value}
                                                   onChange={handleFileChange}
                                                   onBlur={formik.handleBlur} accept="image/*" paddingTop="1"/>
                                        )}
                                        {field.type === "dropdown" && (
                                            <Select placeholder={field.label} name={field.value}
                                                    onChange={formik.handleChange}>
                                                {field.dropdownOptions.map((option, index) => {
                                                    return <option value={option.value} key={index}>{option.label}</option>
                                                })}
                                            </Select>
                                        )}
                                        {field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
                                        <FormErrorMessage>{formik.errors[field.value]}</FormErrorMessage>
                                    </FormControl>
                                );
                            })}
                        </Box>

                        <Box bg={"white"} border="1px" borderColor="black" rounded="md" px='8' py='4' w="75%">
                            <Heading>Care Instructions</Heading>
                            <Text color={"red"}> {formik.errors?.care_instruction}</Text>
                            {formik.values.care_instruction.map((instruction, index) => {
                                return (
                                    <Box key={index} border="1px" borderColor="gray" rounded="md" mt="4" px='8' py='6'
                                         w='75%' pos="relative">
                                        <IconButton
                                            aria-label="Delete instruction"
                                            size="sm"
                                            variant="outline"
                                            icon={<FiX/>}
                                            pos="absolute"
                                            right="8"
                                            top="4"
                                            onClick={() => {
                                                handleInstructionDelete(index)
                                            }}
                                            zIndex={1} // Need to set this so the button is clickable while positioned absolute
                                        />
                                        {CareInstructionFormFields.map((field, index2) => {
                                            return (
                                                <FormControl key={index2} isRequired={field?.required}
                                                             isInvalid={formik.errors?.care_instruction?.[index]?.[field.value]}>
                                                    <FormLabel>{field.label}</FormLabel>
                                                    {field.type === "text" && (
                                                        <Input type="text" placeholder={field.label}
                                                               value={formik.values.care_instruction[index]?.[field.value]}
                                                               name={`care_instruction[${index}].${field.value}`}
                                                               onChange={formik.handleChange}/>
                                                    )}
                                                    {field.type === "number" && (
                                                        <>
                                                            <p>Use 0 for Planting Instructions, 999 for Spring, 1999 for
                                                                Fall, 2999 for Spring/Fall</p>
                                                            <Input type="number" placeholder={field.label}
                                                                   value={formik.values.care_instruction[index]?.[field.value]}
                                                                   name={`care_instruction[${index}].${field.value}`}
                                                                   onChange={formik.handleChange}/>
                                                        </>
                                                    )}
                                                    {field.type === "textarea" && (
                                                        <Textarea placeholder={field.label}
                                                                  value={formik.values.care_instruction[index]?.[field.value]}
                                                                  name={`care_instruction[${index}].${field.value}`}
                                                                  onChange={formik.handleChange}/>
                                                    )}
                                                    {field.type === "dropdown" && (
                                                        <Select placeholder={field.label}
                                                                name={`care_instruction[${index}].${field.value}`}
                                                                onChange={formik.handleChange}>
                                                            {field.dropdownOptions.map((option, index) => {
                                                                return <option value={option.value} key={index}
                                                                               disabled={selectedInstructionTypes.includes(option.value)}>{option.label}</option>
                                                            })}
                                                        </Select>
                                                    )}
                                                    {field.helperText &&
                                                        <FormHelperText>{field.helperText}</FormHelperText>}
                                                    <FormErrorMessage>{formik.errors?.care_instruction?.[index]?.[field.value]}</FormErrorMessage>
                                                </FormControl>
                                            );
                                        })}
                                    </Box>
                                )
                            })}
                            <Button
                                // colorScheme="green"
                                type="button"
                                onClick={() => {
                                    formik.setFieldValue('care_instruction', [...formik.values.care_instruction, {}]);
                                }}
                                mt="2"
                                isDisabled={formik.values.care_instruction.length >= CARE_INSTRUCTION_TYPES.length}
                            >Add Care Type</Button>
                        </Box>
                        <Button type="submit" w="75%">Submit</Button>
                    </VStack>
                </form>

            </>
        )
    }
;

export default AddPlant