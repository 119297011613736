import {
    Box,
    Card, CardBody,
    Tab,
    TabList,
    Text,
    TabPanel,
    TabPanels, Tabs, Image, CardHeader, SimpleGrid, VStack
} from '@chakra-ui/react'
import {forwardRef, useImperativeHandle, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import PlantTable from "../PlantTable";
import AddPlantFromTableRenderer from "../AddPlantFromTableRenderer";
import {capitalizeFirstLetter} from '../../utils/funcs';
import {CARE_INSTRUCTION_TYPES} from "../../utils/constants";
import {toast} from "react-toastify";

const Plants = () => {
        const [tabIndex, setTabIndex] = useState(0);
        const [failedLengthCheck, setFailedLengthCheck] = useState(false);

        const [colDefs] = useState([
            {
                field: 'name',
                headerName: 'Plant Name',
                width: 200,
                sort: 'asc',
                flex: 0,
                valueFormatter: capitalizeFirstLetter
            },
            {field: 'category', width: 120, headerName: 'Category', flex: 0, valueFormatter: capitalizeFirstLetter},
            {field: 'aliases', hide: false},
            {field: 'description', width: 600, flex: 1},
            {field: 'id', headerName: 'Genus Id', width: 150, hide: true}
        ]);
        const [careColDefs] = useState([

                {flex: 0, field: 'name', headerName: 'Plant Name', sort: 'asc', width: 150, editable: false, wrapText: true},
                {flex: 0, field: 'category', headerName: 'Category', width: 100, editable: false},

                // NOTE - defines the Columns for each care instruction type
                ...CARE_INSTRUCTION_TYPES.map((item, index) => {
                        return {
                            // field: item.value, // use this for generic tables, otherwise valuegetter/valuesetter are used.
                            headerName: item.value,
                            width: 220,
                            wrapText: true,
                            autoHeight: true,
                            cellStyle: {'wordBreak': 'normal'},
                            cellEditor: 'agLargeTextCellEditor',
                            editable: true,
                            cellEditorPopup: true,
                            // NOTE - there is a default 200 maxLength on this component. if you don't set this very high,
                            // then it causes all sorts of weird UX bugs where user can't add text but can only delete.
                            cellEditorParams: {
                                maxLength: 4000
                            },
                            // cellRenderer: TestProp,
                            valueGetter: (row) => {
                                // console.log(row.data);
                                return row.data.care_instructions[item.value].instruction_text;
                            },
                            valueSetter: (row) => {
                                if (!row.oldValue || row.oldValue == 0) {
                                    toast.error("You cannot update rows without care instructions at this time.")
                                    return false
                                }
                                if (row.newValue.length < 4) {
                                    toast.error("You must set a value > 4 characters.")
                                    return false;
                                } else {
                                    row.data.care_instructions[item.value].instruction_text = row.newValue;
                                    return true;
                                }
                            }
                        }
                    }
                ),
            ])
        ;

        return (
            <>
                <Box mb={4}>
                    <h2>Plants</h2>
                    <p><em>A list of our plants, and their care instructions.</em></p>
                </Box>
                <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab>Our Plants</Tab>
                        <Tab>Your Plants</Tab>
                        <Tab>Care Edits</Tab>
                        {/*<Tab>Inventory Connect</Tab>*/}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <PlantTable plantUrl={"/plants/genus"} colDefs={
                                colDefs.concat(
                                    [{
                                        field: 'Action',
                                        flex: 1,
                                        maxWidth: 100,
                                        cellStyle: {textAlign: 'left'},
                                        editable: false,
                                        valueGetter: (params) =>
                                            params.data.id,
                                        cellRenderer: AddPlantFromTableRenderer,
                                    }])}
                                        renderCareData={false}
                                        clickToPlant={true}>
                            </PlantTable>
                        </TabPanel>
                        <TabPanel>
                            <PlantTable colDefs={colDefs}
                                        renderCareData={false}
                                        clickToPlant={true}
                                        plantUrl={"/me/plants/genus"}></PlantTable>
                        </TabPanel>

                        <TabPanel>
                            <Box mb={4} mt={2}>
                                <em><p>Viewing your plants' care instructions.</p></em>
                            </Box>
                            {/*TODO: potentially change the component name because here it is rendering care instructions?*/}
                            <PlantTable plantUrl="me/plants/genus/care"
                                        colDefs={careColDefs}
                                        careEditTable={true}
                                        renderCareData={true}>
                            </PlantTable>
                        </TabPanel>
                    </TabPanels>
                </Tabs>


                {/*<Box><h2> whats trending </h2></Box>*/
                }
            </>
        )
            ;
    }
;

export default Plants;