import {Box, Input, Skeleton, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {AgGridReact} from "ag-grid-react";
import React, {useMemo, useState} from "react";
import axiosInstance from "../Api"
import {useQuery} from "@tanstack/react-query";
import {useAuth} from "./Providers/Auth/AuthContext";
import { getErrorMsg } from "../utils/funcs";

const CustomerTable = () => {
    const {user} = useAuth();
    const [quickFilterText, setQuickFilterText] = useState("");
    const [colDefs, setColDefs] = useState([
            {field: "phone" },
            // {field: "email"},
            {field: "receive_email"},
            {field: "receive_sms"}
    ])


    const defaultColDef = useMemo(() => {
        return {
            // set every column width
            // width: 400,
            flex: 1, // https://stackoverflow.com/questions/65169997/react-agfc-grid-auto-size-columns-and-grid-width
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default

        };
    }, []);

    const {isLoading: loadingCustomerData, error: errorCustomerData, data: customerData} = useQuery({
        queryKey: ['customers', user?.email], queryFn: () =>
            axiosInstance.get('/me/customers').then((response) => response.data)
    });

    
    if (errorCustomerData) return <ErrorPage text={getErrorMsg(errorCustomerData)} />

    return (
        <>
            <Skeleton isLoaded={!loadingCustomerData}>
                <Box>
                    <Input mb={4} type="text" id="filter-text-box" placeholder="Filter..."
                           onChange={e => setQuickFilterText(e.target.value)}/>
                </Box>
                <div
                    className={"ag-theme-quartz"}
                    style={{width: '100%', height: '600px'}}
                >
                    <AgGridReact
                        rowHeight={50}
                        quickFilterText={quickFilterText}
                        defaultColDef={defaultColDef}
                        // onRowClicked={(e) => clickToPlant && navigateToPlant(e)}
                        // onCellEditingStopped={(e) => updateCareInstruction(e)}
                        pagination={true}
                        rowData={customerData}
                        columnDefs={colDefs}
                    />
                </div>
            </Skeleton>
        </>
    )
}

export default CustomerTable;