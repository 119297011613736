import React from 'react';
import axiosInstance from "../Api";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {FiCheck, FiLoader} from 'react-icons/fi';
import {Flex} from '@chakra-ui/react';
import {toast} from "react-toastify";

export default (props) => {
    const queryClient = useQueryClient();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    const {mutate, isLoading, error} = useMutation({
        mutationFn: () => axiosInstance.post("me/retailer/genus", JSON.stringify({genus_id: cellValue})),
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({queryKey: ['allPlantData', '/me/plants/genus']});
            toast.success('Plant successfully added.');
        },
    });

    const handleIconClick = () => {
        if (plantAlreadyAdded) {
            toast.info('Plant was already added.');
        } else {
            // Add the logic for adding the plant
            mutate();
        }
    };

    const {data: plantsData, isLoading: plantDataIsLoading} = useQuery({
        queryKey: ['allPlantData', '/me/plants/genus'],
        queryFn: () => axiosInstance.get('/me/plants/genus').then((response) => response.data),
    });

    const plantAlreadyAdded = plantsData && plantsData.some(plant => plant.id === cellValue);


    return (
        <Flex w="100%" h="100%" align="center" justifyContent="left">
            {(isLoading || plantDataIsLoading) && <FiLoader/>}
            {!isLoading && (
                plantAlreadyAdded
                    ? <FiCheck color="green" size={24} title="Plant already added" onClick={handleIconClick}/>
                    : <button onClick={() => mutate()}>Add Plant</button>
            )}
        </Flex>

    );
};
