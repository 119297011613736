import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import axiosInstance from "../../Api";
import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Stack,
    Text
} from "@chakra-ui/react";
import logo from "../../images/logo.png";
import * as Yup from "yup";
import {useFormik} from "formik";
import {ErrorBar} from "recharts";
import ErrorPage from "../Pages/ErrorPage";
import Logout from "./Logout";

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSent, setIsSent] = useState(false);
    const {ptoken} = useParams(); // Get the token from url
    const [error, setError] = useState(false);

    const resetPassword = async () => {
        try {
            await axiosInstance.post('/user/set_new_password', formik.values);
        } catch (error) {
            setError(true);
        }
        setIsSent(true);
        formik.resetForm();
    };

    const NewPasswordValidationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Email is required"),
        password: Yup.string().required("Password is required.")
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
            .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character.")
            .matches(/\d/, "Password must contain at least one number."),
        confirmPassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
    const initialValues = {
        email: "",
        password: "",
        confirmPassword: "",
        token: ptoken,
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: NewPasswordValidationSchema,
        onSubmit: values => {
            resetPassword();
        },
    });
    if (error) return <ErrorPage
        text={"There was an issue resetting your password. Your reset URL may have expired. Please try resetting it at:"}
        to_link={"/request-reset"}/>
    return (
        <div>
            <Center h={600}>
                <Box w={400} sx={{backgroundImage: "linear-gradient(45deg, #f0f0f0, #e0e0e0)"}} p={6}>
                    <Center>
                        <Image src={logo} alt='Plantista Logo' w={200}/>
                    </Center>
                    <Box textAlign={"center"}>
                        <form onSubmit={formik.handleSubmit} id="request-reset-form">
                            <Stack spacing={"6"}>
                                <Text as={"h2"}>Set New Password</Text>
                                <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                                    <FormLabel htmlFor="email">Current Email</FormLabel>
                                    <Input
                                        bgColor="white"
                                        type="email"
                                        id="email"
                                        placeholder="Email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        onBlur={formik.handleBlur}
                                    />
                                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!formik.errors.password && formik.touched.password}>
                                    <FormLabel htmlFor="password">New Password</FormLabel>
                                    <Input
                                        bgColor="white"
                                        type="password"
                                        id="password"
                                        placeholder="New password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                    />
                                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}>
                                    <FormLabel htmlFor="confirmPassword">Confirm New Password</FormLabel>
                                    <Input
                                        bgColor="white"
                                        type="password"
                                        id="confirmPassword"
                                        placeholder="Confirm new password"
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        onBlur={formik.handleBlur}
                                    />
                                    <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                                </FormControl>

                                <Button isDisabled={isSent} mt={4} w="100%" type="submit">Reset
                                    Password</Button>
                            </Stack>
                        </form>
                        {isSent &&
                            <Box mt={4}>
                                <Logout/>
                                <Text>Your password has successfully been reset. <Link to={"/"}><Text color={"green"}>Log
                                    in now</Text></Link>
                                </Text>
                            </Box>
                        }

                    </Box>
                </Box>
            </Center>
        </div>
    )
}
export default ResetPassword;