import {
    Box, Button, Center,
    FormControl,
    FormErrorMessage,
    FormLabel, Image,
    Input,
    InputGroup,
    InputLeftAddon, Skeleton, Text,
    VStack
} from "@chakra-ui/react";
import PlantSearch from "../PlantSearch";
import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import axiosInstance from "../../Api";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {getErrorMsg, validatePhoneNumber} from "../../utils/funcs"
import ErrorPage from "../Pages/ErrorPage";
import PhoneFormControl from "./FormControls/PhoneFormControl";
import EmailFormControl from "./FormControls/EmailFormControl";
import MarketingLegalese from "./EnrollFinePrint";
import {Link} from "react-router-dom";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import Confetti from 'react-confetti'
import {useFeatureFlagVariantKey, usePostHog} from 'posthog-js/react';
import EnrollA from "../Experiments/EnrollA";
import EnrollB from "../Experiments/EnrollB";
import EnrollC from "../Experiments/EnrollC";
import EnrollD from "../Experiments/EnrollD";
import EnrollE from "../Experiments/InterestSurvey";


const EnrollValidationSchema = Yup.object().shape({
    plant: Yup.array().required().min(1),
    phone: Yup.string()
        .required('Phone number is required')
        .test('phone', 'Invalid phone number', validatePhoneNumber),
});

const MissingPlantValidationSchema = Yup.object().shape({
    plant: Yup.string().required("A plant name is required."),
    phone: Yup.string()
        .required('Phone number is required')
        .test('phone', 'Invalid phone number', validatePhoneNumber),
});


const EnrollmentForm = ({
                            retailer,
                            showPhoneAndEmail = true,
                            phoneIn = "",
                            token = null,
                            disableOnSubmit = true,
                            hideEmail = true,
                            manageFlow = false,
                            alreadyEnrolled = [],
                        }) => {
    const [success, setSuccess] = useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false)
    const queryClient = useQueryClient();
    const [showPhone, setShowPhone] = useState(false);
    const [plantSearchTouched, setPlantSearchTouched] = useState(false);
    const [useMissingPlantForm, setUseMissingPlantForm] = useState(false);
    const {height, width} = useWindowDimensions();
    const phoneRef = useRef(null);
    const [hideForm, setHideForm] = useState(false);
    const posthog = usePostHog();
    // Add this for testing
    // posthog.featureFlags.override({'enroll-title-test': null})

    const flagValue = useFeatureFlagVariantKey('enroll-test-two')

    const initialValues = {
        plant: [],
        phone: phoneIn,
        email: "",
    }

    const missingPlantInitialValues = {
        plant: [],
        phone: phoneIn,
        email: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: EnrollValidationSchema,
        onSubmit: values => {
            handleSubmit(formik.values);
            formik.resetForm();
        },
    })

    const missingPlantFormik = useFormik({
        initialValues: missingPlantInitialValues,
        validationSchema: MissingPlantValidationSchema,
        onSubmit: values => {
            const data = {
                plant: [{"value": missingPlantFormik.values.plant}],
                phone: missingPlantFormik.values.phone,
                email: "",
            }
            handleSubmit(data);
            formik.resetForm();
        },
    })
    const prevPlantValue = useRef(formik.values.plant);

    const {mutate: addEnrollment, isPending: pendingEnrollment, error: enrollmentError} = useMutation({
        // mutationFn: (requestData) => axiosInstance.delete('/me/coupon_product', {data: {payload: requestData}}),
        mutationFn: (postData) => axiosInstance.post('/enroll/' + retailer.id, postData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(['enrollments', formik.values.phone]).then();
        },
        onError: (e) => {
            console.log(e);
        },
    });

    const handleSubmit = (postData) => {
        addEnrollment(postData)
        setSuccess(true);

        if (manageFlow === true) {
            setHideForm(false)
        } else {
            setHideForm(true)
        }

    }

    const handleMissingClick = (e) => {
        setUseMissingPlantForm(prevState => !prevState);
    }

    if (retailer === undefined || !posthog) {
        return <Skeleton isLoaded={isLoaded}></Skeleton>
    }

    if (enrollmentError) {
        return <ErrorPage text={getErrorMsg(enrollmentError)}/>
    }

    // // TODO remove this
    // useEffect(() => {
    //         console.log(missingPlantFormik.values?.plant?.length > 0);
    // }, [formik.values])
    const focusPhone = () => {
        if (phoneRef.current) {
            phoneRef.current.focus();
        }
    };

    function getSignupContent(manageFlow) {

        if (flagValue === 'test-sms') {
            return (<EnrollA/>);
        } else if (flagValue === 'test-care-reference') {
            return (<EnrollB/>);
        } else if (flagValue === 'control') {
            return (<EnrollC/>);
        } else if (flagValue === 'text-preview') {
            return (<EnrollD success={success}/>);
        } else {
            return (
                <>
                    <Center>
                        <h2>Care Reminders Signup</h2>
                    </Center>

                    <Center>
                        <Text mt={2} textAlign={"center"}>
                            Receive just-in-time plant care reminders, hear about our latest sales, and more!
                        </Text>
                    </Center>

                </>
            );
        }
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (formik.values.plant.length > 0) {
            focusPhone();
        }
        if (flagValue === 'survey') {
            setHideForm(true);
        }
    }, [formik.values.plant]);

    return (
        <>
            {!manageFlow &&
                <VStack mb={4}>
                    {retailer?.logo_url ?
                        <Image src={retailer.logo_url} maxH={100}/>
                        : <p>{retailer?.business_name} </p>
                    }

                </VStack>
            }
            <Confetti
                run={success && !manageFlow}
                width={width}
                height={height}
            />
            {!useMissingPlantForm ?
                <>
                    {!manageFlow && getSignupContent()}

                    {!hideForm &&
                        <form onSubmit={formik.handleSubmit} id="care-submit-form" style={{width: '100%'}}>
                            <VStack>
                                <FormControl mt={2}>
                                    {/*/retailer/{retailer_id}/plants/genus*/}
                                    {/*<Box sx={{*/}
                                    {/*    animation: `${pulse} 2s infinite`,*/}
                                    {/*}}>*/}
                                    <PlantSearch searchUrl={"/retailer/" + retailer.id + "/plants/genus"}
                                                 responseType="public"
                                                 multiSelectMode={false}
                                                 values={formik.values.plant}
                                                 setFieldValue={formik.setFieldValue}
                                                 setPlantSearchTouched={setPlantSearchTouched}
                                                 formErrors={formik.errors.plant}
                                                 alreadyEnrolled={alreadyEnrolled}
                                                 openUpwards={false}
                                    />
                                    {/*</Box>*/}
                                </FormControl>
                                {(!success && !manageFlow && plantSearchTouched && (formik.values.plant.length <= 0 || formik.values.plant[0] === null)) && (
                                    <Box mt={8}>
                                        <Text fontSize={"1em"}><span>Can't find</span> what you're looking for? <Button
                                            onClick={handleMissingClick}
                                            colorScheme="black"
                                            data-attr="missing-tap-here"
                                            variant={"link"}>Tap
                                            Here</Button></Text>
                                    </Box>
                                )}

                                {!manageFlow && formik.values.plant.length > 0 && formik.values.plant[0] !== null &&
                                    <PhoneFormControl formik={formik} phoneRef={phoneRef}/>
                                }
                                {showPhoneAndEmail &&
                                    <>


                                        {!hideEmail &&
                                            <EmailFormControl formik={formik}/>
                                        }
                                    </>
                                }
                            </VStack>
                            {formik.values.phone.length >= 10 && formik.values?.plant[0]?.value.length > 1 &&
                                <>
                                    <Center>
                                        <Button mt={6} colorScheme={"green"} isDisabled={disableOnSubmit && success}
                                                h={50}
                                                w={200}
                                                data-attr="submit-enroll-form"
                                                type="submit">Submit</Button>
                                    </Center>
                                    {!manageFlow && <MarketingLegalese/>}
                                </>
                            }
                        </form>
                    }
                    {success && !manageFlow &&
                        <Center>
                            <Box>
                                <Box mt={3} textAlign="center" mb={2} color="green"><Text fontSize="xl"
                                                                                          as={"b"}>
                                    Thank you for
                                    enrolling.<br/>Expect to receive your first
                                    text
                                    message in a few minutes.<br/></Text>
                                </Box>
                                <Box textAlign={"center"}>
                                    <Text fontSize="xl">Want to enroll in more plants?<br/> <Link reloadDocument
                                                                                                  to={"/enroll/" + retailer.id}><b>Tap
                                        Here</b></Link></Text></Box>
                            </Box>
                        </Center>
                    }

                </>
                // Missing Plant Form
                :
                <Box>
                    <Box mt={3} mb={4} textAlign={"center"}>
                        <h2>Can't Find Your Plant?</h2>
                        <Text>We will help you find it. Tell us what you were looking for and we will get back
                            to with the Care Info.</Text>
                    </Box>
                    {!success ?
                        <form onSubmit={missingPlantFormik.handleSubmit} id="missing-plant-submit-form"
                              style={{width: '100%'}}>
                            <FormControl mb={4}
                                         isInvalid={!!missingPlantFormik.errors.plant && missingPlantFormik.touched.plant}>
                                <Input
                                    id="plant"
                                    name="plant"
                                    type="input"
                                    height={"70px"}
                                    fontSize={"lg"}
                                    bg="rgb(237, 242, 247)"
                                    variant="outline"
                                    placeholder={"Type a plant name"}
                                    onChange={missingPlantFormik.handleChange}
                                    value={missingPlantFormik.values.plant}
                                    sx={{"textAlign": "center"}}
                                />
                                <FormErrorMessage>{missingPlantFormik.errors.plant}</FormErrorMessage>
                            </FormControl>
                            {missingPlantFormik.values?.plant?.length > 1 &&
                                <PhoneFormControl formik={missingPlantFormik}/>
                            }
                            {missingPlantFormik.values.phone.length < 10 &&
                                <Button data-attr="go-back" variant="link" mt={4} onClick={handleMissingClick}>Go
                                    Back</Button>
                            }

                            {missingPlantFormik.values.phone.length >= 10 && missingPlantFormik.values?.plant?.length > 1 &&
                                <>
                                    <Center>
                                        <Button mt={4} colorScheme={"green"} isDisabled={disableOnSubmit && success}
                                                data-attr="missing-plant-submit"
                                                h={50}
                                                w={200} type="submit">Submit</Button>
                                    </Center>
                                    {!success && <MarketingLegalese/>}
                                </>
                            }

                        </form>
                        :
                        <Center>
                            <Box mt={3} textAlign="center" mb={2} color="green"><Text fontSize="xl"
                                                                                      as={"b"}>
                                Thank you for enrolling.<br/>Expect to receive your first text
                                message within a few minutes.</Text></Box>
                        </Center>
                    }
                </Box>
            }

        </>

    )
}

export default EnrollmentForm;
