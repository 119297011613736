import {Center, HStack, Image, VStack} from "@chakra-ui/react";

const RetailerLogo = ({user, w, h, pr, center = true}) => {
    return (
        <>
            {user.retailer.logo_url && center === true &&
                <Center>
                    <Image src={user.retailer.logo_url} fit={"cover"}/>
                </Center>
            }
            {user.retailer.logo_url && center === false &&
                <Image w={w} h={h} src={user.retailer.logo_url} objectFit={"contain"} pr={pr}/>
            }
        </>
    )
}
export default RetailerLogo;