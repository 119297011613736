import {Box, Center, Container, Text, VStack} from "@chakra-ui/react";
import {Link} from "react-router-dom";

const ErrorPage = ({text, to_link, manageExpired = false}) => {
    return (
        <>
            <Center w={"100%"}>
                <Box w={400} sx={{backgroundImage: "linear-gradient(45deg, #f0f0f0, #e0e0e0)"}} p={6}>
                    <VStack>
                        <Text as={"h2"}>Uh oh!</Text>
                        {!manageExpired && <p>An Error Occurred.</p>}
                        {text ?
                            <>
                                <p>{text}</p>
                                <br/>
                                {to_link &&
                                    <Text as="b" color='green.700'><Link to={to_link}>Request New Reset</Link></Text>}
                            </>
                            :
                            <p>No additional details could be provided.</p>}
                        <br/>
                        {!manageExpired && <p>Please contact support@getplantista.com to get immediate assistance.</p>}

                    </VStack>
                </Box>
            </Center>
        </>
    )
}
export default ErrorPage;
