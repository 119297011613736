import * as Yup from "yup";
import {validatePhoneNumber} from "../../utils/funcs";

const PhoneValidationSchema = Yup.object().shape({
    phone: Yup.string()
        .required('Phone number is required')
        .test('phone', 'Invalid phone number', validatePhoneNumber),
    // plant: Yup.array().required("You must select a plant.")
    plant: Yup.array().required().min(1),
});

export default PhoneValidationSchema;