import {
    Box,
    Button, Center,
    Divider,
    Flex, Heading,
    HStack,
    Image,
    SimpleGrid,
    Skeleton,
    Spacer,
    Text
} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import hoya from "../../images/hoya.jpg"
import CareInstructionForm from "../Forms/CareInstructionForm";
import CareInstructionList from "../CareInstructionList";
import axiosInstance from "../../Api";
import {useQuery} from "@tanstack/react-query";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";
import PhoneFormControl from "../Forms/FormControls/PhoneFormControl";
import LoadingPage from "./LoadingPage";
import PhoneEnrollmentForm from "../Forms/PhoneEnrollmentForm";
import {useAuth} from "../Providers";

const Plant = ({useRetailBranding = false, loadActions = true, publicView = false}) => {
    const {id, retailerId, aliasId} = useParams();
    const [showCareForm, setShowCareForm] = useState(false);
    const navigate = useNavigate();
    // const {user} = useAuth();

    const handleClick = (e) => {
        setShowCareForm(true);
    }

    const {isLoading: loadingPlantData, error: errorPlantData, data: onePlantData} = useQuery({
        queryKey: ['plant', id],
        queryFn: () => {
            let url = '/plants/genus/' + id;
            if (retailerId) {
                url += `?retailer_id=${retailerId}`;
            }
            return axiosInstance.get(url).then((response) => response.data);
        }
    });


    const {
        isLoading: loadingRetailer,
        error: errorRetailerData,
        data: retailerData
    } = useQuery({
        queryKey: ['retailer', retailerId], queryFn: () =>
            axiosInstance.get('/retailer/' + retailerId).then((response) => response.data),
        // enabled: useRetailBranding && retailerId !== undefined,
    });

    const [aliasName, setAliasName] = useState(null);

    useEffect(() => {
        if (onePlantData && onePlantData.genus_aliases && aliasId) {
            const alias = onePlantData.genus_aliases.find(alias => alias.id === aliasId);
            if (alias) {
                setAliasName(alias.alias);
            }
        }
    }, [onePlantData, aliasId]);

    if (loadingPlantData) return <LoadingPage text={"Loading plant data."}/>;
    if (errorPlantData) return <ErrorPage text={getErrorMsg(errorPlantData)}/>;
    if (errorRetailerData) return <ErrorPage text={getErrorMsg(errorRetailerData)}/>;


    // For best practices, we should define a single source of truth for genus data in a separate file
    // That could then be used to populate this rather than hardcoded
    const gridContents = [
        {title: 'Care Difficulty', value: 'care_difficulty'},
    ];


    return (
        <>
            <Box>
                {retailerData &&
                    <div>
                        <Box p={4}>
                            <Center>
                                <Box>
                                    <Image
                                        src={retailerData.logo_url}
                                        alt={`${retailerData.business_name} Logo`}
                                        maxH={100}
                                    />
                                </Box>
                            </Center>
                        </Box>
                        <Divider borderWidth='1px'/>
                        <Box>
                            <Heading textAlign={"center"} size={"2xl"}>{aliasName || onePlantData.name}</Heading>

                        </Box>

                    </div>
                }
                {/*Hides aliases if we are rendering by an alias.*/}
                {onePlantData.aliases && onePlantData.aliases.length > 0 && (
                    <Box mt={2} textAlign={"center"}>
                        {onePlantData.aliases
                            .filter(alias => alias !== aliasName) // Filter out aliasName
                            .length > 0 && (
                            <p><em>Aliases: {
                                onePlantData.aliases
                                    .filter(alias => alias !== aliasName) // Filter out aliasName
                                    .join(', ')
                            }</em></p>
                        )}
                    </Box>
                )}
                <Box textAlign={"center"}>
                    Genus: {onePlantData.name}
                </Box>


                <Center>
                    <Box p={4}>
                        <Box>
                            <Text as={"h3"} textAlign={"Center"}>Plant Care Instructions</Text>
                            {/*<p>Learn how to take care of this plant</p>*/}
                        </Box>
                        <br/>
                        {onePlantData.care_instructions.length > 0 &&
                            <CareInstructionList plant={onePlantData} careCardVersion={true}/>}
                    </Box>
                </Center>
                <Center>
                    <PhoneEnrollmentForm plantData={onePlantData} retailer={retailerData}/>
                </Center>

            </Box>
        </>
    )
}
export default Plant;