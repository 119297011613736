import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../Api";
import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Stack,
    Text
} from "@chakra-ui/react";
import logo from "../../images/logo.png";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ErrorPage from "../Pages/ErrorPage";
import {getErrorMsg} from "../../utils/funcs";

const RequestResetPassword = () => {
    const [isSent, setIsSent] = useState();
    const [error, setError] = useState(false);

    const requestReset = async () => {
        // const token = prompt('Enter the token from your email:');
        // const newPassword = prompt('Enter your new password:');
        try {
            await axiosInstance.post('/user/request_password_reset', formik.values);
            setIsSent(true);
            formik.resetForm();
        } catch (error) {
            console.log(error)
            setError(true);
        }
    };


    //
    // const {
    //     mutate: requestReset,
    //     isPending: pendingRequestReset,
    //     error: errorRequestReset
    // } = useMutation({
    //         mutationFn: (postData) => axiosInstance.post('/user/request_password_reset', postData),
    //         // onMutate: () => {
    //         //     setLoading(true)
    //         // },
    //         onSuccess: (response) => {
    //             formik.resetForm()
    // I tried this with it and without it, neither worked.
    //         queryClient.setQueryData(['request_reset', formik.values.email], (prev) => {
    //             return {...prev, ...response.data};
    //         });
    //         //
    //
    //     },
    //     onError: (e) => {
    //         console.log(e);
    //     },
    //     // onSettled: () => {
    //     //     setLoading(false);
    //     // }
    // });
    const EmailValidationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Email is required")
    });
    const initialValues = {
        email: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: EmailValidationSchema,
        onSubmit: values => {
            requestReset();
        },
    });

    if (error) return <ErrorPage text={getErrorMsg(error)}/>

    return (
        <Center h={600}>
            <Box w={400} sx={{backgroundImage: "linear-gradient(45deg, #f0f0f0, #e0e0e0)"}} p={6}>
                <Center>
                    <Image src={logo} alt='Plantista Logo' w={200}/>
                </Center>
                <Box textAlign={"center"}>
                    <Stack spacing={"6"}>
                        <Text as={"h2"}>Forgot Email/Password</Text>
                        <form onSubmit={formik.handleSubmit} id="request-reset-form">
                            <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                                <FormLabel mb={4} htmlFor="email">We will send you an email with instructions on how to reset
                                    your
                                    password.</FormLabel>
                                <Input
                                    bgColor="white"
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                            </FormControl>
                            <Button isDisabled={isSent} mt={4} w="100%" type="submit">Request Reset</Button>
                        </form>
                        {isSent &&
                            <Box mt={4}>
                                <Text>An email with instructions on how to reset your password has been sent to
                                    {formik.values.email}. Check your spam or junk folder if you don’t see the email in
                                    your inbox. If you no longer have access to this email account, please contact
                                    us.
                                </Text>
                            </Box>
                        }
                    </Stack>
                </Box>
            </Box>
        </Center>
    )
}
export default RequestResetPassword;