import React from "react";
import {
    Box,
    Center, Divider,
    GridItem, HStack,
    VStack,
    Text, Link as ChakraLink
} from "@chakra-ui/react";
import LoadingPage from "./LoadingPage"
import {Link, Outlet, useParams} from "react-router-dom";
import EnrollmentForm from "../Forms/EnrollmentForm";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import axiosInstance from "../../Api";
import {getErrorMsg} from "../../utils/funcs";
import ErrorPage from "./ErrorPage";
import {useFeatureFlagEnabled} from 'posthog-js/react'
import InterestSurvey from "../Experiments/InterestSurvey";

const Enroll = () => {
    const params = useParams('');
    const retailerId = params['retailer_id'];
    const flagEnabled = useFeatureFlagEnabled('show-survey')
    // const flagEnabled = true;
    // console.log(flagEnabled)

    const {isPending: isRetailerLoading, error: isRetailerError, data: retailer} = useQuery({
        queryKey: ['retailer_' + retailerId], queryFn: () =>
            axiosInstance.get('/retailer/' + retailerId).then((response) => response.data)
    });

    const {isLoading: isLoadingPlants, error: plantsError, data: retailerPlantData} = useQuery({
        queryKey: ['allPlantData'],
        queryFn: () => axiosInstance.get(`/retailer/${retailer.id}/plants/genus`).then((response) => response.data),
        staleTime: Infinity, // use this to prevent refetching, otherwise page will duplicate this request needlessly when it renders PlantSearch
        enabled: retailer !== undefined,
    });

    if (isRetailerLoading || isLoadingPlants) return <LoadingPage withHeading={true}/>;
    if (isRetailerError || plantsError) return <ErrorPage text={getErrorMsg(isRetailerError || plantsError)}/>;
    if (retailerPlantData.length === 0) {
        return <VStack>
            <Text>We're not quite ready to let you register!</Text>
            <Text>
                If you think this is incorrect, please reach out to
                <ChakraLink isExternal href="mailto:support@getplantista.com" color="blue"
                            ml={1}>support@getplantista.com</ChakraLink>,
                or report the issue to {retailer.business_name}'s customer service.
            </Text>
        </VStack>
    }

    return (
        <>
            <GridItem colSpan={{base: 6, md: 5}}>
                <Center>
                    <Box w="100%" maxW={600} p={4}>
                        {/*bg={"#b7e4c7"}>*/}
                        {flagEnabled ?
                            <InterestSurvey retailer={retailer}/>
                            :
                            <EnrollmentForm retailer={retailer}/>
                        }
                        <Outlet/>
                        <Divider mt={4}/>
                        <Center>
                            <HStack mt={2}>
                                <Box>
                                    <Text fontSize={"sm"}>
                                        <Link to={"/enroll/terms-of-service"}>
                                            Terms of Service | </Link>
                                    </Text>
                                </Box>
                                {/*<Spacer/>*/}
                                <Box>
                                    <Text fontSize={"sm"}>
                                        <Link to={"/enroll/privacy-policy"}>Privacy Policy</Link>
                                    </Text>
                                </Box>
                            </HStack>
                        </Center>
                    </Box>
                </Center>
            </GridItem>
        </>
    )
}

export default Enroll;

