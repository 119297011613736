import PlantSearch from "../PlantSearch";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import {
    Box, Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Select,
    VStack
} from "@chakra-ui/react";
import axiosInstance from "../../Api";
import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ErrorPage from "../Pages/ErrorPage";
import {CARE_INSTRUCTION_TYPES} from "../../utils/constants";
import {getErrorMsg} from "../../utils/funcs";

const CreateProductRecommendationForm = ({setShowForm, instructionFilter = ''}) => {
    const {id} = useParams();
    const [isSent, setIsSent] = React.useState(false);
    const queryClient = useQueryClient();
    const [plantSearchTouched, setPlantSearchTouched] = useState(false);
    console.log(instructionFilter);
    const filteredInstructionTypes = CARE_INSTRUCTION_TYPES.filter(
        type => type.value === instructionFilter
    );
    const PlantValidationSchema = Yup.object().shape({
        plant: Yup.array().required().min(1),
        instruction: Yup.string().required()
    });

    const {mutate: updateProductRecommendation, isLoading: updateProductRecommendationLoading, error} = useMutation({
        mutationFn: (requestData) => axiosInstance.post('/me/product_recommendation', formik.values),
        onSuccess: (response) => {
            formik.resetForm();
            setIsSent(true);
            queryClient.invalidateQueries(['product', id]);
            setShowForm(false);
        },
        onError: (e) => {
            console.log(e);
        },
    });
    const handleSubmit = async () => {
        updateProductRecommendation();
    }

    const initialValues = {
        instruction: "",
        plant: [],
        product_id: id
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: PlantValidationSchema,
        onSubmit: values => {
            handleSubmit();
            // window.location.reload(); // TODO: this should be a state setting/refresh page instead of a window reload...
        },
    });

    if (error) return <ErrorPage text={getErrorMsg(error)}/>;

    return (
        <form onSubmit={formik.handleSubmit}>
            <PlantSearch searchUrl="/me/plants/genus"
                         multiSelectMode={false}
                         setPlantSearchTouched={setPlantSearchTouched}
                         responseType="into"
                         values={formik.values.plant}
                         setFieldValue={formik.setFieldValue}
                         formErrors={formik.errors.plant}/>
            <VStack spacing={4} align="flex-start">
                <FormControl isInvalid={!!formik.errors.instruction}>
                    <FormLabel mt={2} htmlFor="instruction">Instruction to Use</FormLabel>
                    <Select
                        placeholder="Select option"
                        id="instruction"
                        name="instruction"
                        variant="filled"
                        onChange={formik.handleChange}
                        value={formik.values.instruction}
                        // onChange={(e) => setFieldValue("instruction", e.target.value)}
                    >
                        {filteredInstructionTypes.map((item, index) => {
                            return (
                                <option key={index} value={item.value}>{item.label}</option>
                            )
                        })}
                    </Select>
                    <FormErrorMessage>{formik.errors.instruction}</FormErrorMessage>
                </FormControl>
                {isSent &&
                    <Box color={"green"}>
                        <p>Product Recommendation Added!</p>
                    </Box>
                }
            </VStack>
            <Button mt={4} type='submit'>Add Recommendation</Button>
        </form>
    )
}

export default CreateProductRecommendationForm;