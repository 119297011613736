import {Button, Card, CardBody, CardFooter, CardHeader, Center, Heading, Stack, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import moment from 'moment';
import {Box} from "@mui/material/";


const PlantMiniCard = ({plantData, removeEnrollment, removeRecommendation, startDate = null, cardWidth = '200px'}) => {
    let localDateStr = '';
    if (startDate) {
        localDateStr = moment(startDate).local().format('MMMM D YYYY');
    }

    const aliases = (plantData?.aliases || []).filter((alias) => alias.length > 2);

    return (
        <>
            <Card align="center">
                <CardBody>
                    <Stack spacing={4} overflow="hidden" align="center" h={260}>
                        <Box align='center' overflow="hidden">
                            <Heading color="green" size='md' overflow="hidden" textOverflow="ellipsis"
                                     maxWidth={200}>{plantData.name}</Heading>
                            <Heading color="green" size="sm">{plantData.category}</Heading>
                        </Box>
                        {localDateStr &&
                            <Box align="center">
                                <p><b>Enrolled on:</b></p>
                                <p>{localDateStr}</p>
                            </Box>
                        }
                        <Box align={"center"}>
                            <Text><b>Also Known As:</b></Text>
                            {aliases.length > 0 ? (
                                <Text overflow='hidden' textOverflow="ellipsis"
                                      align='center'>{aliases.slice(0, 3).join(', ')}</Text>
                            ) : (
                                <Text>No known aliases</Text>
                            )}
                        </Box>
                        <Box mt="auto">
                            {
                                removeRecommendation &&
                                <Button
                                    data-attr="remove-recommendation"
                                    colorScheme="red" genusid={plantData.id}
                                    onClick={(e) => removeRecommendation(e)}>Remove</Button>
                            }
                            {
                                removeEnrollment &&
                                <Button
                                    data-attr="remove-enrollment"
                                    colorScheme="red" genusid={plantData.id}
                                    onClick={(e) => removeEnrollment(e)}>Remove</Button>
                            }
                        </Box>
                    </Stack>
                </CardBody>
            </Card>
        </>
    )
}
export default PlantMiniCard;