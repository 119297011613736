import axios from 'axios';
import {redirect} from "react-router-dom";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

const refreshTheToken = async (data) => {
     await axiosInstance.post("/refresh_token", data)
        .then((response) => {
            const access_token = JSON.stringify(response.data.access_token);
            const refresh_token = JSON.stringify(response.data.refresh_token);
            localStorage.setItem("token", access_token);
            localStorage.setItem("refresh_token", refresh_token);
            return access_token;
        })
        .catch((err) => {
            // If there is an error refreshing the token, log out the user
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            window.location.href = "/";
            return Promise.reject(err);
        });
}
// Add a request interceptor to add the JWT token to the authorization header
axiosInstance.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to refresh the JWT token if it's expired
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const originalRequest = error.config;

        // If the error is a 401 and we have a refresh token, refresh the JWT token
        // TODO: this can cause lots of problems if you ever return 401 in the API on any route that is not
        // related to auth and the refresh tokens.

        if (error.response.status === 401 && localStorage.getItem("refresh_token")) {
            const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));

            let data = JSON.stringify({
                refresh_token: refreshToken,
            });

            const access_token = await refreshTheToken(data)
            // Re-run the original request that was intercepted
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            return axiosInstance(originalRequest);

        } else if (error.response.status === 401 && !localStorage.getItem("refresh_token")) {
            window.location.href = "/";
        }

        // Return the original error if we can't handle it
        return Promise.reject(error);
    }
);

// export async function getMe() {
//     const { data: response } = await axiosInstance.get('/me/');
//     return response;
// }
export default axiosInstance;