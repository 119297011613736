import {
    Center,
    Flex,
    FormControl, FormErrorMessage,
    FormLabel,
    Stack,
    Text
} from "@chakra-ui/react";
import {Autocomplete, Chip, TextField, createTheme, Box, Popper} from '@mui/material/';
import {ThemeProvider} from '@mui/system';

import React, {useEffect, useState, useMemo} from "react";
import axiosInstance from "../Api";
import {useQuery} from '@tanstack/react-query';
import LoadingPage from "./Pages/LoadingPage";
import ErrorPage from "./Pages/ErrorPage";
import {getErrorMsg, capitalizeFirstLetter} from "../utils/funcs";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {Typography} from "@mui/material";
import {keyframes} from '@emotion/react';
// Define the pulse keyframes
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const PlantSearch = ({
                         searchUrl,
                         multiSelectMode,
                         responseType,
                         values,
                         setFieldValue,
                         setPlantSearchTouched,
                         formErrors,
                         maxWidth,
                         centerLabel = false,
                         alreadyEnrolled = [],
                         openUpwards = false,
                     }) => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [formattedPlantData, setFormattedPlantData] = useState([]);
    const [plantData, setPlantData] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isPulsing, setIsPulsing] = useState(true);

    const handleFocus = () => setIsPulsing(false);
    const handleBlur = () => setIsFocused(false);


    // Memoized values and options to prevent re-rendering
    const displayValues = useMemo(() => {
        return values
    }, [values]);

    const displayOptions = useMemo(() => {
        return formattedPlantData
    }, [formattedPlantData]);

    const autoCompleteProps = multiSelectMode ? {
        multiple: true, // Allows multiple selections
        freeSolo: true, // Allows custom input
        disableClearable: true, // Disables input clearing
        clearOnBlur: true, // Clears input on blur (on item selection)
        selectOnFocus: true, // Helps user clear selected value
        disableCloseOnSelect: false, // Prevents dropdown from closing on selection
        // openOnFocus: false,
        // autoHightlight: true,
        // limitTags: 1,
        // openText: "Hello",
        id: "plant-multi-select",
        value: displayValues, // Multi-select value (array)
        open: menuOpen,
        onOpen: () => setMenuOpen(true),
        onClose: () => setMenuOpen(false),
    } : {
        multiple: false, // Allows multiple selection
        autoComplete: true,
        forcePopupIcon: false,
        noOptionsText: "Plant Not Found.",
        openOnFocus: false,
        id: "plant-single-select",
    };

    // Get all possible Plant (genus) options on load.
    const {isLoading, error, data} = useQuery({
        queryKey: ['allPlantData', searchUrl],
        queryFn: () => axiosInstance.get(searchUrl).then((response) => {
            return response.data;
        }),
    });

    useEffect(() => {
        if (data) {
            setPlantData(data.filter((plant) => !alreadyEnrolled.includes(plant.id)));
        }
    }, [data, alreadyEnrolled.length])

    useEffect(() => {
        if (plantData) {
            setFormattedPlantData(plantData
                // Include aliases in data object for use in search
                .map((plant) => ({
                    value: plant.id,
                    label: plant.name,
                    category: plant.category,
                    aliases: plant?.aliases || []
                }))
                .sort((a, b) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                }))
        }
    }, [plantData])

    const handleSelectedItemsChange = (selectedItems) => {
        if (selectedItems) {
            setSelectedItems(selectedItems);
            setFieldValue("plant", selectedItems)
            setMenuOpen(false);
            setIsPulsing(false);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
            setMenuOpen(true);
            setPlantSearchTouched(true);
        } else {
            setMenuOpen(false);
        }
    };
    const handleCreateItem = (item) => {
        setPlantData([...plantData, {id: item.value, name: item.value, category: "Unknown", aliases: []}]);
        handleSelectedItemsChange([...selectedItems, {value: item.value, label: item.label, aliases: []}]);
    };

    if (isLoading) return <LoadingPage withHeading={false} text="Loading..."/>;
    if (error) return <ErrorPage text={getErrorMsg(error)}/>;

    return (
        <Stack pt={2}>
            <Center><Text as={"h3"}>Search for a Plant</Text></Center>
            <Flex pt="" align="center" w="full">
                <FormControl w={maxWidth} isInvalid={!!formErrors}>
                    {/*{centerLabel ?*/}
                    {/*    <Center><FormLabel fontSize="lg">Start by Searching for a Plant</FormLabel></Center>*/}
                    {/*    :*/}
                    {/*    <FormLabel fontSize="lg">Search for a Plant</FormLabel>*/}
                    {/*}*/}
                    {/* Wrapped in MUI ThemeProvider to use default MUI styling, otherwise this looks goofy and needs to be completely restyled */}
                    <ThemeProvider theme={createTheme({})}>
                        {/* Custom chip display */}
                        {multiSelectMode && <Flex mb={2} gap={2} flexWrap="wrap">
                            {displayValues.map((value, idx) => {
                                return (
                                    <Chip
                                        key={idx}
                                        label={`${value.label}${value.category ? ` - ${value.category}` : ''}`}
                                        onDelete={() => {
                                            handleSelectedItemsChange(values.filter((v, i) => i !== idx))
                                        }}
                                        sx={{
                                            height: '36px',
                                            fontSize: '1rem',
                                            fontWeight: 500,
                                            backgroundColor: 'rgb(56, 161, 105)'
                                        }}
                                    />
                                )
                            })}
                        </Flex>}

                        {/* MUI Multi-select auto complete with item creation */}
                        {displayOptions.length > 0 &&
                            <Box
                                sx={{
                                    animation: isPulsing ? `${pulse} 2s infinite` : 'none',
                                }}
                            >
                                <Autocomplete
                                    open={menuOpen}
                                    inputValue={inputValue} // Set the inputValue to the state value
                                    onInputChange={handleInputChange} //
                                    {...autoCompleteProps} // Props dynamically defined based on multi vs single select
                                    handleHomeEndKeys // Accessibility support for keyboard navigation
                                    options={displayOptions} // memoized options for the dropdown
                                    getOptionLabel={(option) => { // Custom label display for options, displays option - category for existing and Add "option" for new options
                                        if (option?.newOption && multiSelectMode) return `Create new plant called: "${option.label}"`
                                        return `${option.label}${option.category ? ` - ${capitalizeFirstLetter({value: option.category})}` : ''}`
                                    }}
                                    isOptionEqualToValue={(option, value) => { // Checks if option is equal to value, soft eqaulity check by value (genus id)
                                        return option.value === value.value;
                                    }}
                                    renderOption={(props, option, state, ownerState) => { // Renders each option, displays up to 3 aliases if they match the search query

                                        const displayedAliases = state.inputValue.length > 0 ? (option?.aliases || []).filter((alias) => {
                                            return alias.toLowerCase().includes(state.inputValue.toLowerCase());
                                        }) : [];

                                        const matches = match(option?.label, state.inputValue, {insideWords: true});
                                        const parts = parse(option?.label, matches);

                                        return (
                                            <Box key={option.value}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '8px',
                                                        margin: '5px',
                                                        height: '40px'

                                                    }}
                                                    component="li"
                                                    {...props}
                                                >
                                                    <Text flexShrink={0}>
                                                        {option.newOption && multiSelectMode
                                                            ? `Create new plant called: "${option.label}"`
                                                            : parts.map((part, index) => (
                                                                <Typography
                                                                    key={index}
                                                                    style={{
                                                                        fontWeight: part.highlight ? 700 : 400,
                                                                        display: 'inline',
                                                                    }}
                                                                >
                                                                    {part.text}
                                                                </Typography>
                                                            ))}
                                                        {' - '}
                                                        {option.category}
                                                    </Text>
                                                    {displayedAliases.length > 0 && (
                                                        <Box display="flex" ml={2} gap={0} alignItems="center"
                                                             overflow="hidden" justifyContent="space-between">
                                                            <Text color="#45bba4">(</Text>
                                                            <Text color="#45bba4" overflow='hidden'
                                                                  textOverflow="ellipsis"
                                                                  whiteSpace='nowrap'>
                                                                {displayedAliases.join(', ')}
                                                            </Text>
                                                            <Text color="#45bba4">)</Text>
                                                        </Box>
                                                    )}
                                                </Box>
                                                {/* Display bottom border as a separator for all but last item */}
                                                {state.index !== ownerState.options.length - 1 &&
                                                    <Box sx={{borderBottom: "2px solid #E2E8F0"}}/>}
                                            </Box>
                                        )
                                            ;
                                    }}
                                    filterOptions={(options, state) => {
                                        // Filters options by label & aliases based on search query
                                        if (state.inputValue.length < 1) {
                                            return [];
                                        }

                                        let count = 0;
                                        const filtered = options.filter((option) => {
                                            const matchesLabel = option.label.toLowerCase().includes(state.inputValue.toLowerCase());
                                            const matchesAlias = option.aliases.some((alias) => alias.toLowerCase().includes(state.inputValue.toLowerCase()));

                                            // Controls how many options are rendered.
                                            if ((matchesLabel || matchesAlias) && count < 6) {
                                                count++;
                                                return true;
                                            }
                                            return false;
                                        });

                                        // Checks if search query exists in options, if not, adds a new option (multi-select only)
                                        const isExisting = options.some((option) => (
                                            state.inputValue === option.label || option.aliases.some((alias) => alias === state.inputValue)));
                                        if (state.inputValue !== '' && !isExisting && multiSelectMode) {
                                            filtered.unshift({
                                                value: state.inputValue,
                                                label: `${state.inputValue}`,
                                                newOption: true,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    renderInput={(params) => ( // Renders custom input using MUI
                                        <TextField
                                            {...params}
                                            // color={"warning"}
                                            variant="outlined"
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            // label="Tap here and type a plant name"
                                            placeholder={`try... ${displayOptions?.[0]?.name || 'Begonia'}`}
                                            // InputProps={{
                                            //     sx: {
                                            //         "& input": {
                                            //             textAlign: "center"
                                            //         }
                                            //     }
                                            // }}

                                        />
                                    )}
                                    sx={{ // Override default MUI styles
                                        width: maxWidth,
                                        mb: '1rem',
                                        background: 'rgb(237, 242, 247)'
                                    }}
                                    onChange={(event, value, reason) => {
                                        if (multiSelectMode && value.at(-1)?.newOption) {
                                            // Calls createItem function if new option is selected (multi-select only)
                                            handleCreateItem(value.at(-1));
                                            setMenuOpen(false);
                                        } else {
                                            // Else handle selected items, coercing state to array
                                            handleSelectedItemsChange(Array.isArray(value) ? value : [value])
                                        }
                                    }}
                                    renderTags={() => {
                                    }} // disables tag display
                                    PopperComponent={openUpwards ? (props) =>
                                        <Popper {...props}
                                                placement="bottom"/> : undefined
                                        // <Popper height={"300px"}
                                        // placement="bottom"/> : undefined
                                    } // Custom popper placement, forces it to open upwards on mobile to ensure submit button is visible
                                />
                            </Box>
                        }
                    </ThemeProvider>
                    <FormErrorMessage mb={2}>{formErrors}</FormErrorMessage>
                </FormControl>
            </Flex>
        </Stack>
    )
}
export default PlantSearch;