import {Box, Center, Image, SimpleGrid, SlideFade, Text, useDisclosure, VStack} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {usePostHog} from "posthog-js/react";
import {useFormik, Formik, Form, Field} from 'formik';
import EnrollmentForm from "../Forms/EnrollmentForm";

const InterestSurvey = ({retailer}) => {
    const posthog = usePostHog();
    const [survey, setSurvey] = useState({})
    const [delayHit, setDelayHit] = useState(false);
    const [sent, setSent] = useState();
    const {isOpen, onToggle} = useDisclosure()
    const selectionColor = '#056735';
    const surveyId = '019036f0-5237-0000-c557-8b97cca57a99';

    posthog.capture("survey shown", {
        $survey_id: surveyId // required
    })

    const handleDismiss = () => {
        posthog.capture("survey dismissed", {
            $survey_id: surveyId,
        })
        localStorage.setItem(`hasInteractedWithSurvey_${surveyId}`, 'true');
        setTimeout(() => {
            // setM essage(`Feedback for ${value} submitted!`);
            setDelayHit(true);
            onToggle();
        }, 4000);
        setSent(true);
    }

    const handleFeedbackSubmit = (value) => {
        // e.preventDefault();
        // const feedback = e.target.elements.feedback.value;

        posthog.capture("survey sent", {
            $survey_id: surveyId,
            $survey_response: value
        })
        localStorage.setItem(`hasInteractedWithSurvey_${surveyId}`, 'true');
        setTimeout(() => {
            // setM essage(`Feedback for ${value} submitted!`);
            setDelayHit(true);
            onToggle();
        }, 4000);
        setSent(true);

    }

    return (
        <>
            {!sent &&
                <VStack mb={4}>
                    {retailer?.logo_url ?
                        <Image src={retailer.logo_url} maxH={100}/>
                        : <p>{retailer?.business_name} </p>
                    }
                </VStack>
            }
            {!sent ?
                <form>

                    <Box mt={4} mb={4} textAlign={"center"}>
                        <Text as="h2">Hello! What are you most interested in today?</Text>
                    </Box>

                    <Box textAlign={"center"}>
                        <Text as={"h3"}>Sign Up</Text>
                    </Box>
                    <SimpleGrid mt={4} columns={2} spacing={8}>
                        <Box
                            bg={selectionColor}
                            color={'white'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={() => handleFeedbackSubmit('Care Reminders')}
                        >
                            Care Reminders
                        </Box>
                        <Box
                            bg={selectionColor}
                            color={'white'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={() => handleFeedbackSubmit('Coupons')}
                        >
                            Coupons
                        </Box>
                    </SimpleGrid>

                    <Box mt={4} textAlign={"center"}>
                        <Text as={"h3"}>Discovery & Info</Text>
                    </Box>
                    <SimpleGrid mt={4} columns={2} spacing={8}>
                        <Box
                            bg={selectionColor}
                            color={'white'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={() => handleFeedbackSubmit('Plant Info')}
                        >
                            Plant Info
                        </Box>
                        <Box
                            bg={selectionColor}
                            color={'white'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={() => handleFeedbackSubmit('Care Instructions')}
                        >
                            Care Instructions
                        </Box>
                    </SimpleGrid>

                    <SimpleGrid mt={4} columns={2} spacing={8}>
                        <Box
                            bg={selectionColor}
                            color={'white'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={() => handleFeedbackSubmit('Plant Finder')}
                        >
                            Plant Finder
                        </Box>
                        <Box
                            bg={selectionColor}
                            color={'white'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={() => handleFeedbackSubmit('Plant ID')}
                        >
                            Plant ID
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mt={6} columns={1} spacing={8}>
                        <Box
                            bg={'#CCCCCC'}
                            height='80px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='md'
                            boxShadow='md'
                            onClick={handleDismiss}
                        >
                            Skip This
                        </Box>
                    </SimpleGrid>
                </form>

                :
                <>
                    {!delayHit &&
                        <Box>
                            <Center>{retailer?.logo_url ?
                                <Image src={retailer.logo_url} maxH={100}/>
                                : <p>{retailer?.business_name} </p>
                            }</Center>

                            <Center mt={4}>
                                <Box
                                    bg="green.100"
                                    borderRadius="md"
                                    p={4}
                                    textAlign="center"
                                    boxShadow="md"
                                >

                                    <Text fontSize="lg" fontWeight="bold" color="green.700">
                                        Thank you for the feedback. Now let's get you set up for plant care reminders...
                                    </Text>
                                </Box>
                            </Center>
                        </Box>
                    }
                    <SlideFade in={isOpen} offsetY='20px'>
                        <EnrollmentForm retailer={retailer}/>
                    </SlideFade>
                </>
            }
        </>
    )
        ;
};
export default InterestSurvey;