import {useState} from 'react';

// // https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// TODO: potentiall anyone can login by setting a token value, since we don't check the tokens on the backend?
export default function useToken() {
    const getToken = () => {
        try {
            const tokenString = localStorage.getItem('token');
            const userToken = JSON.parse(tokenString); // TODO: not sure why i had to change this.
            return userToken
        } catch (e) {
            // if (e instanceof SyntaxError) {
            //     sessionStorage.removeItem("token");
            //     sessionStorage.removeItem("refresh_token");
            // }
            console.log(e);
        }

    };

    const [token, setToken] = useState(getToken());
    const [refreshToken, setRefreshToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken); // was previously userToken.token
    };

    const saveRefreshToken = userRefreshToken => {
        localStorage.setItem('refresh_token', JSON.stringify(userRefreshToken));
        setRefreshToken(userRefreshToken);
    }

    return {
        setToken: saveToken,
        setRefreshToken: saveRefreshToken,
        token
    }
}