import {
    Box,
    Button,
    Flex,
    GridItem,
    SimpleGrid,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import SendCareForm from "../Forms/SendCareForm";
import ReactPlayer from "react-player";

const Training = () => {
    return (
        <>
            <Flex>
            <Box mb={4}>
                <h2>Training</h2>
                <p><em>Everything related to training your staff.</em></p>
            </Box>
                <Spacer/>
            <Box>
                <Button m={4}>Customize</Button>
            </Box>
                </Flex>
            <Tabs>
                <TabList>
                    <Tab>General Care</Tab>
                    <Tab>Outdoor Plants</Tab>
                    <Tab>Indoor Plants</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                          <SimpleGrid templateColumns='repeat(1, 1fr)' gap={4}>
                            <GridItem w="50%">
                                <h2>Annuals vs Perennials</h2>
                                <p><em>What's the difference between annuals and perennials</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://youtu.be/xz37dy7Ih68?si=sqnoav8esTlGQUBw'/>
                                </Box>
                            </GridItem>
                              <GridItem w="50%">
                                <h2>Native Plants to Colorado</h2>
                                <p><em>Learn about, and how to identify some of the best plants in California</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=rI71UCVcYP4&ab_channel=PPLDTV'/>
                                </Box>
                            </GridItem> <GridItem w="50%">
                                <h2>Repotting</h2>
                                <p><em>How to properly repot a plant</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=Fy8kP2EYpcY'/>
                                </Box>
                            </GridItem>
                            <GridItem w="50%">
                                <h2>Picking the Right Plants</h2>
                                <p><em>Explore how to help customers pick the right plants.</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=dslGiINx6IY'/>
                                </Box>
                            </GridItem>

                        </SimpleGrid>
                    </TabPanel>


                    <TabPanel>
                        <SimpleGrid templateColumns='repeat(1, 1fr)' gap={4}>
                            <GridItem w="50%">
                                <h2>Tree Wrapping</h2>
                                <p><em>How to properly wrap outdoor plants</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://youtu.be/B_KOaHXETb4?si=BZnZ7bQwbcLFmpwP'/>
                                </Box>
                            </GridItem>
                            <GridItem w="50%">
                                <h2>Fertilizing 101</h2>
                                <p><em>Explore different types of fertilizer and techniques for fertilizing.</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=I4esJjU85AM'/>
                                </Box>
                            </GridItem>
                            <GridItem w="50%">
                                <h2>Pruning</h2>
                                <p><em>How to prune a plant for a natural look.</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=-AW-zlCto_0'/>
                                </Box>
                            </GridItem>

                        </SimpleGrid>
                    </TabPanel>
                    <TabPanel>
                        <SimpleGrid templateColumns='repeat(1, 1fr)' gap={4}>
                            <GridItem w="50%">
                                <h2>Misting</h2>
                                <p><em>Learn how to properly mist plants.</em></p>
                                <Box mt={2}>
                                    <ReactPlayer url='https://youtu.be/b_aLcklEqu0?si=H_hLrY1UQwso5VQa'/>
                                </Box>
                            </GridItem>
                            <GridItem w="50%">
                                <h2>Lighting</h2>
                                <p><em>Discover different light types, like indirect, medium and bright-indirect
                                    light.</em></p>
                                {/*<Spacer/>*/}
                                <Box mt={2}>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=Qlb45OM0g7g'/>
                                </Box>
                            </GridItem>
                        </SimpleGrid>
                        {/*<Box mt={4}>*/}
                        {/*    <h3>Add Video</h3>*/}
                        {/*    <p><em>Add your own material, or link to an exiting one on Youtube.</em></p>*/}
                        {/*    <Button>Add Video</Button>*/}
                        {/*</Box>*/}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}
export default Training;