import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement
} from "@chakra-ui/react";
import {PhoneIcon} from "@chakra-ui/icons";
import React from "react";

const PhoneFormControl = ({formik, phoneRef, fontSize = 'lg', center = true, title = null}) => {
    const useTitle = title !== null ? title : 'Enter Your Phone #';

    return (
        <FormControl isInvalid={!!formik?.errors.phone && formik?.touched.phone}>
            {center ?
                <FormLabel textAlign="center" htmlFor="phone" fontSize={fontSize}>{useTitle}</FormLabel>
                :
                <FormLabel textAlign="left" htmlFor="phone" fontSize={fontSize}>{useTitle}</FormLabel>
            }
            <InputGroup mt={2}>
                <InputLeftAddon children='+1' height={"50px"}/>
                <Input
                    id="phone"
                    name="phone"
                    type="phone"
                    height={"50px"}
                    fontSize={"lg"}
                    bg="rgb(237, 242, 247)"
                    variant="outline"
                    onChange={formik?.handleChange}
                    value={formik?.values.phone}
                    ref={phoneRef}
                />
            </InputGroup>
            <FormErrorMessage>{formik?.errors.phone}</FormErrorMessage>
        </FormControl>
    )
}

export default PhoneFormControl;