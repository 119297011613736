// AuthContext.js
import React, {createContext, useContext, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axiosInstance from "../../../Api";
import {Skeleton} from "@chakra-ui/react";
import ErrorPage from '../../Pages/ErrorPage';
import { getErrorMsg } from '../../../utils/funcs';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    const {
        isLoading: isLoadingMeData,
        error: errorMeData,
        data: meData
    } = useQuery({
        queryKey: ['user', user?.email], queryFn: () =>
            axiosInstance.get('/me/').then(response => {
                setUser(response.data)
                return response.data;
            }), enabled: user === null,
    });

    if (isLoadingMeData) return <div><Skeleton/></div>;
    if (errorMeData) return <ErrorPage text={getErrorMsg(errorMeData)}/>;

    return (
        <AuthContext.Provider value={{user, setUser}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
