import {
    Box,
    Button,
    Image,
    Text,
    SimpleGrid,
    Skeleton,
    Heading,
    ModalOverlay,
    Modal,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent, ModalHeader, Divider
} from "@chakra-ui/react";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import axiosInstance from "../../Api";
import CreateProductRecommendationForm from "../Forms/CreateProductRecommendationForm";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../Providers/Auth/AuthContext";
import CouponCard from "../CouponCard";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";
import ProductPlantMiniCard from "../ProductPlantMiniCard";


const Product = () => {
    const {id} = useParams();
    const [deleted, setDeleted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const queryClient = useQueryClient();
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();
    const onClose = () => setIsOpen(false);
    const {user} = useAuth();
    const retailerId = user.retailer_id;

    const {isLoading, error, data: product} = useQuery({
        queryKey: ['product', id], queryFn: () =>
            axiosInstance.get('/me/product/' + id).then(response => {
                return response.data;
            })
    });

    const {isLoading: isLoadingCoupons, error: errorCoupons, data: CouponsData} = useQuery({
        queryKey: ['coupons', retailerId], queryFn: () =>
            axiosInstance.get('/me/coupons').then(response => {
                return response.data;
            })
    });

    const {
        mutate: deleteProduct,
        isPending: pendingDeleteProduct,
        isFetching: fetchingDeleteProduct,
        error: errorDeleteProduct
    } = useMutation({
        // onMutate: () => {
        //     setLoading(true)
        // },
        mutationFn: (CouponId) => axiosInstance.delete('/me/product/' + id),
        onSuccess: (response) => {
            // queryClient.setQueryData(['products', user?.email], (prev) => {
            //     return {...prev, ...response.data};
            // });
            //
            queryClient.invalidateQueries(['products', user?.email]).then();
            // // TODO: not sure about this.
            queryClient.invalidateQueries(['product', id]).then();
            navigate("/products");
        },
        onError: (e) => {
            console.log(e);
        },
        // onSettled: () => {
        //     setLoading(false);
        // }
    });
    const handleAddMore = (e) => {

    }
    const removeRecommendation = (e) => {
        const genusId = e.target.getAttribute(['genusid'])
        const requestData = {product_id: product.id, genus_id: genusId};

        updateProductRecommendation(requestData)
    }

    const {mutate: updateProductRecommendation, isLoading: updateProductRecommendationLoading} = useMutation({
        mutationFn: (requestData) => axiosInstance.delete('/me/product_recommendation', {data: {payload: requestData}}),
        onSuccess: (response) => {
            // Invalidate cache and re-fetch recommendations
            queryClient.invalidateQueries(['product', id]);
        },
        onError: (e) => {
            const details = e.response?.data?.detail;
            const msg = (
                <>
                    <div>Failed to delete</div>
                    <div>{details || 'Please try again.'}</div>
                </>
            );
            console.log(e);
        },
    });

    if (isLoading || isLoadingCoupons || !user) return <div><Skeleton/></div>;
    if (error) return <ErrorPage text={getErrorMsg(error)}/>;
    if (errorCoupons) return <ErrorPage text={getErrorMsg(errorCoupons)}/>;

    return (
        <>
            <Box>
                <Box mb={4}>
                    <Heading as={"h2"}>{product.name}</Heading>
                    <Text as={"p"}><b>For Use When:</b> {product.type}</Text>
                </Box>
            </Box>
            <SimpleGrid columns={{sm: 1, md: 2}}>
                <Box maxW={"100vw"}>
                    <h3>Description</h3><p>{product.description}</p>
                </Box>
                {product.image_url && product.image_url !== '' ?
                    <Box p={4}>
                        <Image maxHeight={300} src={product.image_url} alt={product.name}/>
                    </Box>
                    : null
                }
            </SimpleGrid>
            <Box mt={2} mb={2}>
                <Button size="xs" colorScheme={"red"} onClick={() => setIsOpen(true)}>Delete Product</Button>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Confirm Delete</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            Are you sure you want to delete this item?
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" onClick={onClose}>Cancel</Button>
                            <Button colorScheme={"red"} onClick={deleteProduct}>Delete Product</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
            <Divider/>
            <Box mt={4}>
                <Heading as='h3' size='md'>Recommended on Plants</Heading>
                <Text mb={2} as={"i"}>A list of plants this product is recommended on.</Text>

                {/*<p><em>These are plants (by genus) that you have linked this product to. Customers will receive these as*/}
                {/*    recommendations in various texts.</em></p>*/}
                {product.genus_recommendation_info && product.genus_recommendation_info.length > 0 ?
                    // <PlantRecommendationTable product={product} removeRecommendation={removeRecommendation} deleted={deleted}/>
                    <SimpleGrid mt={4} spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
                        {
                            product.genus_recommendation_info.map((item, index) => (
                                <ProductPlantMiniCard genus={item.genus_id} key={item.id} plantData={item}
                                                      removeRecommendation={removeRecommendation}/>
                            ))
                        }
                    </SimpleGrid>
                    :
                    null
                }
                <Box mt={2}>
                    <Button
                        onClick={() => setShowForm(!showForm)}> {showForm ? 'Hide' : product.genus_recommendation_info.length > 0 ? 'Recommend on More' : 'Add Recommendation'} </Button>
                </Box>
                {showForm && (
                    <>
                        <Box w={400} mt={4}>
                            <Text as="i">Search for a plant that you would like to recommend this product on.</Text>
                            <CreateProductRecommendationForm setShowForm={setShowForm}
                                                             instructionFilter={product.type}/>
                        </Box>
                    </>
                )}
            </Box>
            <Box mt={2}>
                <Box mb={2}><h3>Attached Coupon</h3></Box>
                <Box mb={4}><Text as={"i"}>The coupon that is currently attached to this product.</Text></Box>
                {product.coupons && product.coupons.length > 0 ?
                    // TODO: why is this selecting the first coupon only? seems bad, idk.
                    <CouponCard couponData={product.coupons[0]} useToggle={false} showRemove={true}></CouponCard>
                    :
                    <Box>
                        <Text mb={4} maxW={800}>There are no coupons attached to this product. Your customers will not
                            receive a coupon
                            code in a text message when receiving this product.</Text>
                        {CouponsData && CouponsData.length > 0 ?
                            <div>
                                <Text>You can attach one of the following coupons to this product</Text>
                                <SimpleGrid mt={4} spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
                                    {CouponsData.map((coupon, index) => (
                                        <CouponCard key={coupon.id} couponData={coupon} showRemove={false}
                                                    showAdd={true}></CouponCard>
                                    ))}
                                </SimpleGrid>
                            </div>

                            :
                            <Box>
                                <Text>You need to create a coupon first, then you can attach it to this product.</Text>
                                <Link to="/coupons"><Button>Create Coupon</Button></Link>
                            </Box>
                        }
                    </Box>
                }

            </Box>

        </>
    );
};

export default Product;