import {Box, Center, Image, Text} from "@chakra-ui/react";
import React from "react";
import phone_shot from "../../images/GREAT.png";

const EnrollD = ({success}) => {
    return (
        <>
            <Center>
                <h2>Care Reminders Signup</h2>
            </Center>
            <Center>
                <Text mt={2} mb={4} textAlign={"center"}>
                    Receive just-in-time plant care reminders, product recommendations, coupons, and more!
                </Text>
            </Center>
            {!success &&
                <>
                    <Center>
                        <Image
                            // borderWidth="2px"  // Set the width of the border
                            // borderRadius="2px"  // Set the border-radius for rounded corners
                            // borderColor="gray.200"  // Set the color of the border
                            // borderStyle="solid"  // Set the style of the border (solid, dashed, etc.)
                            w="100%"  // Set the width of the image
                            src={phone_shot}  // Set the source of the image
                        />
                    </Center>
                    <Text as={"h4"} mt={2} mb={4} textAlign={"center"}>
                        Let's get started!
                    </Text>
                </>
            }
        </>
    );
}

export default EnrollD;