import {
    Accordion,
    AccordionButton, AccordionIcon,
    AccordionItem, AccordionPanel,
    Box, Button, Card, CardBody, FormControl, FormErrorMessage, Select,
    SimpleGrid,
    Stack,
    Text
} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import CareInstructionList from "../CareInstructionList";
import axiosInstance from "../../Api";
import {useMutation, useQuery} from "@tanstack/react-query";
import ErrorPage from "./ErrorPage";
import {getErrorMsg} from "../../utils/funcs";

import {Link} from "react-router-dom";
import {useAuth} from "../Providers";
import LoadingPage from "./LoadingPage";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";


const Plant = () => {
    const {id} = useParams();
    const {user} = useAuth();
    const navigate = useNavigate();

    const {isLoading: loadingPlantData, error: errorPlantData, data: onePlantData} = useQuery({
        queryKey: ['plant', id], queryFn: () =>
            axiosInstance.get('/me/plants/genus/' + id).then((response) => response.data)
    });

    const {mutate: createQRCode, isLoading: loadingCreateQRCode, error: createQRError, data: QRCodeData} = useMutation({
        mutationFn: (addData) => axiosInstance.post('/me/download-file', addData),
        // TODO: use alias ID. but concerned that need to also filter it by retailer id, since aliases are shared.
        // mutationKey: ['plant_page_qr', formik?.values?.alias],
        onSuccess: (response) => {
            console.log(response);
        },
        onError: (e) => {
            console.log(e);
        },
    });

    const downloadFile = async (postData) => {
        try {
            const response = await axiosInstance.post('/me/qr_code/plant_page/download', postData, {
                responseType: 'arraybuffer' // Ensure the response is treated as binary data
            });
            // Create a Blob from the response data
            const blob = new Blob([response.data], {type: 'application/octet-stream'});

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create an anchor element and trigger the download
            const a = document.createElement('a');
            a.href = url;
            const alias = onePlantData.genus_aliases.find(alias => alias.id === postData.alias_id);
            const aliasName = alias ? alias.alias : ''; // The alias.alias value, or an empty string if not found
            a.download = aliasName + '.png'; // Set the desired file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the URL to free up memory
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    const initialValues = {
        alias: "",
    }
    const handleSubmit = () => {
        const data = {
            alias_id: formik.values.alias,
            genus_id: id,
            type: "plant_page"
        }
        const response = downloadFile(data);
    }

    const copyLink = () => {
        if (formik.values.alias === null && formik.values.alias === undefined || formik.values.alias.length === 0) {
            toast.error('Select an alias before copying a link.');
            return;
        }
        // process.env.REACT_APP_URL
        const url = `${process.env.REACT_APP_URL}/plants/genus/${id}/${user.retailer.id}/${formik.values.alias}`;

        navigator.clipboard.writeText(url).then(() => {
            toast.success('URL copied to clipboard:', url);
        }).catch((error) => {
            toast.error('Failed to copy URL to clipboard:', error);
        });
        return url;
    }
    const previewPlantPage = () => {
        const url = `/plants/genus/${id}/${user.retailer.id}/${formik.values.alias}`;
        navigate(url);
    }

    const AliasValidationSchema = Yup.object().shape({
        alias: Yup.string().required("An alias selection is required."),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: AliasValidationSchema,
        onSubmit:
            values => {
                handleSubmit();
            },
    })

    if (loadingPlantData || !user) return <LoadingPage text={"Loading..."}/>
    if (errorPlantData) return <ErrorPage text={getErrorMsg(errorPlantData)}/>;
    // if (errorRetailerData) return <ErrorPage text={getErrorMsg(errorRetailerData)}/>;

    // For best practices, we should define a single source of truth for genus data in a separate file
    // That could then be used to populate this rather than hardcoded
    const gridContents = [
        {title: 'Care Difficulty', value: 'care_difficulty'},
        // {title: 'Category', value: 'category'},
        {title: 'Drought Tolerant', value: 'drought_tolerant'},
        {title: 'Light Requirements', value: 'light_requirements'},
        {title: 'Light Amount', value: 'light_amount'},
        {title: 'Mature Height', value: 'mature_height'},
        {title: 'Mature Spread', value: 'mature_spread'},
        {title: 'Native To', value: 'native_to'},
        {title: 'Plant In The', value: 'plant_in_the'},
        {title: 'Bloom Month', value: 'bloom_month'},
        {title: 'USDA Plant Zones', value: 'usda_plant_zones'},
        {title: 'Pairs Well With', value: 'pairs_well_with'},
        {title: 'Attracts', value: 'attracts'},
        {title: 'Common Use', value: 'common_use'},
        {title: 'Known For', value: 'known_for'},
        {title: 'Toxic to', value: 'toxic_to'},
    ];


    return (
        <>
            <Box>
                <Box p={4}>
                    <Box mb={4}>
                        {onePlantData &&
                            <h2> Name: {onePlantData.name} - {onePlantData.category.charAt(0).toUpperCase() + onePlantData.category.slice(1)}</h2>}
                        {onePlantData.aliases && onePlantData.aliases.length > 0 &&
                            <p><em> Aliases: {onePlantData.aliases.join(', ')}</em></p>}
                    </Box>
                    <Box mt={4} maxW={600}>
                        <form onSubmit={formik.handleSubmit}
                              id="create-qr-code-form">
                            {onePlantData.genus_aliases?.length > 0 && (
                                <>
                                    <FormControl isInvalid={!!formik.errors.alias && formik.touched.alias}>
                                        <Select
                                            placeholder="Select an alias to create a QR code"
                                            name="alias"
                                            onChange={formik.handleChange}
                                        >
                                            {onePlantData.genus_aliases.map((alias) => (
                                                <option key={alias.id} value={alias.id}>{alias.alias}</option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formik.errors.alias}</FormErrorMessage>
                                    </FormControl>
                                </>
                            )}
                            <Stack mt={3} direction={"row"}>
                                <Button type={"submit"} isDisabled={!formik.values.alias}>Download QR Code</Button>
                                <Button onClick={previewPlantPage} isDisabled={!formik.values.alias}>Preview Plant
                                    Page</Button>
                                <Button onClick={copyLink} isDisabled={!formik.values.alias}>Copy Plant Page
                                    Link</Button>
                            </Stack>
                        </form>
                    </Box>

                    {/*<Stack direction={"row"}>*/}
                    {/*    <Box>*/}
                    {/*        <Button>Download QR Code</Button>*/}

                    {/*    </Box>*/}
                    {/*    <Box>*/}
                    {/*        <Link to={""}><Button>View Plant Page</Button></Link>*/}
                    {/*    </Box>*/}
                    {/*</Stack>*/}
                    {/*{loadActions &&*/}
                    {/*    <HStack>*/}
                    {/*        <Button*/}
                    {/*            onClick={() => navigate("/care", {*/}
                    {/*                replace: false,*/}
                    {/*                state: {defaultIndex: 1, plantName: onePlantData.name},*/}
                    {/*            })}*/}
                    {/*        > Create Care Card</Button>*/}
                    {/*        <Button onClick={() => navigate("/care")}>Send to Customer</Button>*/}
                    {/*    </HStack>*/}
                    {/*}*/}
                </Box>
                <SimpleGrid columns={{sm: 1, md: 2}}>
                    <Box p={4}>
                        <h3>Description</h3><p>{onePlantData.description}</p>
                    </Box>
                </SimpleGrid>
                <Box p={4}>
                    <h3>Light Requirements</h3>
                    <p>{onePlantData && onePlantData.light_requirements ? onePlantData.light_requirements : "Not set yet."}</p>
                </Box>
                {/* define grid, map over grid item definitions and render then programmatically */}
                <SimpleGrid columns={{sm: 3, md: 3}}>
                    {gridContents.filter((item) => onePlantData?.[item.value]).map((item, index) => {
                        let value = onePlantData[item.value];
                        if (typeof value === 'string') {
                            value = value.charAt(0).toUpperCase() + value.slice(1);
                        }

                        return (
                            <Box p={4} key={index}>
                                <h3>{item.title}</h3>
                                <p>{item.value === 'drought_tolerant' && value !== undefined
                                    ? (value ? 'Yes' : 'No')
                                    : value ? value : "Not set yet."}</p>
                            </Box>
                        )
                    })}
                </SimpleGrid>

                <Box p={4}>
                    <Box>
                        <h2>Plant Care Instructions</h2>
                        {/*<p>Learn how to take care of this plant</p>*/}
                    </Box>
                    <br/>
                    {onePlantData.care_instructions.length > 0 &&
                        <CareInstructionList plant={onePlantData} careCardVersion={false}/>}
                </Box>
                <Box p={4}>
                    <Text as={"h2"} mb={2}>Generic Recommended Products</Text>
                    <Text as={"i"} mb={2}>These are default recommendations we suggest for a genus.</Text>
                    {onePlantData.generic_recommended_products?.length > 0 ? (
                        <SimpleGrid maxW={600} p={4} columns={2} spacing={10}>
                            {onePlantData.generic_recommended_products.map((recommendation, index) => (
                                <Card key={index} w={200} textAlign={"center"} mb={2} flex="1 0 200px">
                                    <CardBody>
                                        <Text as="b" mb={4}>
                                            {recommendation.name}
                                        </Text>
                                        <Text>Use When: {recommendation.use_when_instruction_type}</Text>
                                    </CardBody>
                                </Card>
                            ))}
                        </SimpleGrid>
                    ) : (
                        <Text mt={4}>No generic product recommendations are currently set for this genus.</Text>
                    )}
                </Box>
                <Box p={4}>
                    <Text as={"h2"} mb={2}>Your Recommended Products</Text>
                    <Text as={"i"} mb={2}>These are recommendations you made on a genus.</Text>
                    {onePlantData.special_recommendations?.length > 0 ? (
                        <SimpleGrid maxW={600} p={4} columns={2} spacing={10}>
                            {onePlantData.special_recommendations
                                .filter(recommendation => recommendation.retailer_id === user.retailer.id) // Assuming retailerId is set from auth context
                                .map((recommendation, index) => {
                                    return (
                                        // {console.log(recommendation)}
                                        <Link key={index} to={`/product/${recommendation.product_id}`}
                                              style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Card w={200} textAlign={"center"} mb={2} flex="1 0 200px">
                                                <CardBody>
                                                    <Text as="b" mb={4}>
                                                        {recommendation.name}
                                                    </Text>
                                                    <Text>Sent For: {recommendation.trigger_instruction_type}</Text>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    );
                                })}
                            {onePlantData.special_recommendations
                                .filter(recommendation => recommendation.retailer_id === user.retailer.id)
                                .length === 0 && <Text>No product recommendations are set.</Text>}
                        </SimpleGrid>
                    ) : (
                        <Text mt={4}>No product recommendations are set.</Text>
                    )}
                </Box>


                {/*<Box p={4}>*/}
                {/*    <Text as={"h2"} mb={2}>Active Coupon</Text>*/}
                {/*    {onePlantData.recommended_products?.length > 0 &&*/}
                {/*        <CouponCard couponData={onePlantData.recommended_products[0].coupons[0]}/>*/}
                {/*    }*/}
                {/*    {!onePlantData.recommended_products?.length || !onePlantData.recommended_products[0].coupons.length ? (*/}
                {/*        <Text>None set</Text>*/}
                {/*    ) : null}*/}
                {/*</Box>*/}
            </Box>
        </>
    )
}
export default Plant;