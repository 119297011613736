import {Button, Card, Heading, Image, Skeleton, Stack, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axiosInstance from "../Api";
import {useParams} from "react-router-dom";
import {useAuth} from "./Providers/Auth/AuthContext";
import ErrorPage from "./Pages/ErrorPage";


const CouponCard = ({couponData, setActiveIndex, showRemove = false, showAdd = false, showDelete = false}) => {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    // const []
    const productId = useParams();
    const {user} = useAuth();
    const retailerId = user.retailer_id;

    const {
        mutate: deleteCoupon,
        isPending: pendingDeleteCoupon,
        isFetching: fetchingDeleteCoupon,
        error: deleteCouponError
    } = useMutation({
        // mutationFn: (requestData) => axiosInstance.delete('/me/coupon_product', {data: {payload: requestData}}),
        // mutationFn: (requestData) => axiosInstance.delete('/me/coupons?coupon_id=' + requestData, {data: requestData}),
        onMutate: () => {
            setLoading(true)
        },
        mutationFn: (CouponId) => axiosInstance.delete('/me/coupons?coupon_id=' + CouponId),
        onSuccess: (response) => {

            queryClient.setQueryData(['coupons', retailerId], (prev) => {
                return {...prev, ...response.data};
            });

            queryClient.invalidateQueries(['coupons', retailerId]).then();
        },
        onError: (e) => {
            console.log(e);
        },
        onSettled: () => {
            setLoading(false);
        }
    });

    const {
        mutate: deleteCouponProductMapping,
        isPending: pendingDeleteCouponProductMapping,
        error: deleteCouponProductError
    } = useMutation({
        // mutationFn: (requestData) => axiosInstance.delete('/me/coupon_product', {data: {payload: requestData}}),
        mutationFn: (requestData) => axiosInstance.delete('/me/coupon_product', {data: requestData}),
        onMutate: () => {
            setLoading(true)
        },
        onSuccess: (response) => {
            // window.location.reload()
            queryClient.setQueryData(['product', productId], (prev) => {
                return {...prev, ...response.data};
            });

            queryClient.invalidateQueries(['product', productId]).then();
        },
        onError: (e) => {
            console.log(e);
        },
        onSettled: () => {
            setLoading(false);
        }
    });

    const {
        mutate: addCouponProductMapping,
        isPending: pendingAddCouponProductMapping,
        error: addCouponProductError
    } = useMutation({
        // mutationFn: (requestData) => axiosInstance.delete('/me/coupon_product', {data: {payload: requestData}}),
        mutationFn: (addData) => axiosInstance.post('/me/coupon_product', addData),
        onMutate: () => {
            setLoading(true)
        },
        onSuccess: (response) => {
            queryClient.setQueryData(['product', productId], (prev) => {
                return {...prev, ...response.data};
            });

            queryClient.invalidateQueries(['product', productId]).then();
        },
        onError: (e) => {
            console.log(e);
        },
        onSettled: () => {
            setLoading(false);
        }
    });

    const removeCouponFromProduct = (e) => {
        let cid = e.currentTarget.getAttribute('cid');
        let d = JSON.stringify({coupon_id: cid, product_id: productId['id']})
        deleteCouponProductMapping(d)
    }
    const addCouponToProduct = (e) => {
        let cid = e.currentTarget.getAttribute('cid');
        let d = JSON.stringify({coupon_id: cid, product_id: productId['id']})
        addCouponProductMapping(d)
    }

    const deleteCouponEntirely = (e) => {
        let cid = e.currentTarget.getAttribute('cid');
        let d = JSON.stringify({coupon_id: cid})
        deleteCoupon(cid)
    }

    if (!user) return <Skeleton></Skeleton>;
    if (deleteCouponError || deleteCouponProductError || addCouponProductError) return <ErrorPage
        text={getErrorMsg(deleteCouponError || deleteCouponProductError || addCouponProductError)}/>;

    return (
        <Card key={couponData.id}
            // className={index === activeIndex ? 'active' : ''}
              w={200}
              p={4}
              cid={couponData.id}
            // onClick={(e) => handleClick(e, index)}
        >
            <Stack mt='6' spacing='3'>
                <Heading noOfLines={1} color='blue.600' fontSize='2xl'>{couponData.name}</Heading>
                <Text>
                    Type: {couponData.type}
                </Text>
                <Text fontSize='md'>
                    Value: {couponData.value}{couponData.type === "percentage" ? '%' : '$'}
                </Text>
                <Text fontSize='md'>
                    Code: {couponData.code}
                </Text>
                <Text fontSize='md'>
                    Minimum Purchase: {couponData.min_purchase_requirement ? `$${couponData.min_purchase_requirement}` : 'None Set'}
                </Text>
                <Text fontSize="sm" as={"em"}>
                    Attached to: {couponData.on_product_count} products
                </Text>

                {showRemove && (<Button isLoading={pendingDeleteCouponProductMapping} cid={couponData.id}
                                        onClick={removeCouponFromProduct}>Remove</Button>)}
                {showAdd && (<Button isLoading={pendingAddCouponProductMapping} cid={couponData.id}
                                     onClick={addCouponToProduct}>Add</Button>)}
                {showDelete && (<Button isLoading={pendingDeleteCoupon} colorScheme="red" cid={couponData.id}
                                        onClick={deleteCouponEntirely}>Delete</Button>)}
            </Stack>
            {couponData?.image_url &&
                <Image src={couponData.image_url}/>
            }
        </Card>
    )
}

export default CouponCard;