// AuthContext.js
import React, {createContext, useContext, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axiosInstance from "../../../Api";
import {Skeleton} from "@chakra-ui/react";
import { useAuth } from '../Auth/AuthContext';
import ErrorPage from '../../Pages/ErrorPage';

const RetailersContext = createContext();

export const RetailersProvider = ({children}) => {
    const [retailers, setRetailers] = useState([]);
    const { user } = useAuth();

    const {
        isLoading: isLoadingRetailersData,
        error: errorRetailersData,
        data: retailersData
    } = useQuery({
        queryKey: ['retailers', retailers, user], queryFn: () =>
            axiosInstance.get('/admin/retailers/').then(response => {
                setRetailers(response.data)
                return response.data;
            }), enabled: retailers.length === 0 && user?.is_admin,
    });

    if (isLoadingRetailersData) return <div><Skeleton/></div>;
    if (errorRetailersData) return <ErrorPage text={getErrorMsg(errorRetailersData)}/>;

    return (
        <RetailersContext.Provider value={{retailers, setRetailers}}>
            {children}
        </RetailersContext.Provider>
    );
};

export const useRetailers = () => useContext(RetailersContext);
